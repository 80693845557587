import React from "react";
import { Box, Typography, Pagination } from "@mui/material";

const CommonPagination = ({ totalItems, itemsPerPage = 10, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end", // Align content to the right
                alignItems: "center",
                mt: 2,
                width: "100%", // Ensure full width
                gap: 2, // Add space between items
            }}
        >
            {/* Page Info */}
            <Typography
                variant="body2"
                sx={{
                    whiteSpace: "nowrap", // Prevents collapsing or breaking
                }}
            >
                {(totalItems == 0 || totalItems == undefined) ? (
                    <></>
                ) : (
                    `${startItem} TO ${endItem} OF ${totalItems} ITEMS`
                )}
            </Typography>

            {/* Pagination Control */}
            {(totalItems == 0 || totalItems == undefined) ? <></> : (

                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(_, page) => onPageChange(page)}
                    color="primary"
                    siblingCount={1}
                    boundaryCount={1}
                    size="small"
                    showFirstButton
                    showLastButton
                />
            )}
        </Box>

    );
};

export default CommonPagination;
