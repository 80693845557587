import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Box, Container, CardMedia, Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetDashboardIDUrl, GetLoadDashboardUrl } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../components/toast";
import { axiosGet } from "../../utils/Axios";


const LoadDashboard = () => {
  const { ToastComponent, showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardID, setDashboardID] = useState(location.state?.dashboardID || null);
    const [reportConfig, setReportConfig] = useState(null);
    const [isDefault, setIsDefault] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const urlParams = location.pathname;
    console.log('urlParams', urlParams);

    const codeMatch = urlParams.match(/\/code=([A-Za-z0-9-]+)/);
    if (codeMatch) {
      const randomCode = codeMatch[1];
      console.log('randomCode', randomCode);

      getLoadDashboardwithCode(randomCode);
    } else if (dashboardID) {
      const userInfo = localStorage.getItem("userInfo");
      if (!userInfo) {
        window.location.href = "/Login/KeyStone_Login";
        return;
      } else {
        getDashboardLoad(dashboardID);
      }
    }
  }, [dashboardID, navigate]);


  const getDashboardLoad = async (dashboardID) => {
    const endpoint = `?dashboardID=${dashboardID}`;
    setIsLoading(true);

    try {
      const response = await axiosGet(`${GetLoadDashboardUrl}${endpoint}`);
        debugger;
      if (
        response?.Type === "Report" &&
        response?.EmbedReport &&
        Array.isArray(response.EmbedReport) &&
        response.EmbedReport.length > 0 &&
        response?.EmbedToken?.Token
      ) {
          setIsDefault(false);
        const report = response.EmbedReport[0];
        setReportConfig({
          type: "report",
          embedUrl: report.EmbedUrl,
          accessToken: response.EmbedToken.Token,
          id: report.ReportId,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: { expanded: false, visible: true },
            },
            background: models.BackgroundType.Transparent,
          },
        });
      } else {
          setIsDefault(true);
        showToast("error", t("InvalidReportData", "Invalid report data received."));
        console.error("Invalid response data:", response);
      }
    } catch (error) {
      const errorMessage = error?.message || "Unknown error occurred";
      console.error("Error fetching dashboard data:", error);
      showToast("error", t("FetchError", `Error fetching dashboard details: ${errorMessage}`));
    } finally {
      setIsLoading(false);
    }
  };
  const getLoadDashboardwithCode = async (randomCode) => {
    const endpoint = `?randomCode=${randomCode}`;
    setIsLoading(true);

    try {
      const response = await axiosGet(`${GetDashboardIDUrl}${endpoint}`);

      if (response) {
        setDashboardID(response.dashboardID)
        getDashboardLoad(response.dashboardID)
      } else {
        showToast("error", t("InvalidReportData", "Invalid report data received."));
        console.error("Invalid response data:", response);
      }
    } catch (error) {
      const errorMessage = error?.message || "Unknown error occurred";
      console.error("Error fetching dashboard data:", error);
      showToast("error", t("FetchError", `Error fetching dashboard details: ${errorMessage}`));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Header />
      <Box
        id="main"
        style={{
          marginTop: 80,
          height: "calc(100vh - 80px)", // Full viewport height minus header
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            p: 0,
            width: "100%",
            height: "100%", // Full height
            display: "flex",
            flexDirection: "column",
          }}
        >
                  {reportConfig && isDefault == false ? (
                      <PowerBIEmbed
                          embedConfig={reportConfig}
                          cssClassName="power-bi-report-class"
                          style={{
                              flex: 1,
                              width: "100%",
                              height: "100%", // Take full height
                          }}
                      />
                  ) : (
                      isDefault == true && (
                          <Box
                              sx={{
                                  textAlign: "center",
                                  mt: 4,
                                  flex: 1,
                                  height: "100%",
                              }}
                          >
                              <img src='/imgs/PowerBI.png' />
                              <Typography
                                  variant="h6"
                                  component="p"
                                  sx={{
                                      fontWeight: "bold",
                                      fontSize: "1rem",
                                      mb: 1,
                                      textAlign: "center",
                                      flex: 1,
                                      wordWrap: "break-word",
                                      cursor: "pointer"
                                  }}
                              >
                                  No report available to display.
                              </Typography>
                          </Box>
                      )

          )}
        </Container>
        <Footer />
      </Box>
      {ToastComponent}
    </>
  );
};

export default LoadDashboard;