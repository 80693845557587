//export const BASE_URL = "https://localhost:44349/api";
//export const BASE_URL = "https://beta-apps.benchmarksolution.com/api";
export const BASE_URL = window.location.origin + "/api";

//export const fetchAuthReport = `${REPORT_BASE_URL}/report/SetParameterData`;
//export const downloadReport = `${REPORT_BASE_URL}/WebForm/ItmeReportViewer.aspx`;

//common
export const getFillListUrl = `${BASE_URL}/Common/GetFilllist`;
export const GetUserPrivUrl = `${BASE_URL}/Common/GetUserPriv`;
export const GetEmailTemplateUrl = `${BASE_URL}/Common/GetEmailTemplate`;
export const GetAuditTrailSearchUrl = `${BASE_URL}/Common/SearchAuditTrail`;
export const GetAuditTrailExcel = `${BASE_URL}/Common/AuditTrailExcel`;
export const logoutUrl = `${BASE_URL}/Common/Logout`;

//user
export const GetUserDetailsUrl = `${BASE_URL}/userInfo/GetUserDetails`;
export const InsertUserDetailsUrl = `${BASE_URL}/userInfo/InsertUserDetails`;
export const UpdateUserDetailsUrl = `${BASE_URL}/userInfo/UpdateUserDetails`;
export const GetUserListSearchUrl = `${BASE_URL}/userInfo/GetUserListSearch`;
export const DeleteUserUrl = `${BASE_URL}/userInfo/DeleteUser`;
export const GetModuleDetailsUrl = `${BASE_URL}/userInfo/GetModuleDetails`;
export const DeleteActionUrl = `${BASE_URL}/userInfo/DeleteAction`;
export const UpdateUserLangUrl = `${BASE_URL}/userInfo/UpdateUserLang`;

//client
export const GetClientListSearchUrl = `${BASE_URL}/client/GetClientListSearch`;
export const GetClientDetailsUrl = `${BASE_URL}/client/GetClientDetails`;

//dashboardGroup
export const GetDashboardGroupListSearchUrl = `${BASE_URL}/DashboardGroup/GetDashboardGroupListSearch`;
export const GetDashboardGroupDetailsUrl = `${BASE_URL}/DashboardGroup/GetDashboardGroupDetails`;
export const InsertDashboardGroupUrl = `${BASE_URL}/DashboardGroup/InsertDashboardGroup`;
export const UpdateDashboardGroupUrl = `${BASE_URL}/DashboardGroup/UpdateDashboardGroup`;
export const DashboardGroupLangUrl = `${BASE_URL}/DashboardGroup/DashboardGroupLang`;

//dashboardLog
export const GetDashboardLogListSearchUrl = `${BASE_URL}/DashboardLog/DashboardLogList`;
export const GetDashboardLogListExcelUrl = `${BASE_URL}/DashboardLog/DashboardLogExcel`;

//dashboard
export const GetDashboardUrl = `${BASE_URL}/Dashboard/GetDashboard`;
export const GetDashboardListSearchUrl = `${BASE_URL}/Dashboard/GetDashboardListSearch`;
export const GetLoadDashboardUrl = `${BASE_URL}/Dashboard/LoadDashboard`;
export const InsertDashboardUrl = `${BASE_URL}/Dashboard/InsertDashboard`;
export const UpdateDashboardUrl = `${BASE_URL}/Dashboard/UpdateDashboard`;
export const GetShareDashboardUrl = `${BASE_URL}/Dashboard/GetShareDashboard`;
export const InsertShareDashboardurl = `${BASE_URL}/Dashboard/InsertShareDashboard`;
export const InsertUpdateDashboardFavUrl = `${BASE_URL}/Dashboard/InsertUpdateDashboardFav`;
export const GetDashboardUsersUrl = `${BASE_URL}/Dashboard/GetUsers`;
export const SearchUserAndADGroupUrl = `${BASE_URL}/Dashboard/SearchUserAndADGroup`;
export const CallGraphApiUrl = `${BASE_URL}/Dashboard/CallGraphApi`;
export const DeleteDashboardUserUrl = `${BASE_URL}/Dashboard/DeleteDashboardUser`;
export const GetThirdPartyAPI = `${BASE_URL}/Dashboard/GetUsersViaThirdPartyAPI`;
export const GetDashboardIDUrl = `${BASE_URL}/Dashboard/GetDashboardID`;
export const SendMailNotificationUrl = `${BASE_URL}/Dashboard/SendMailNotification`;
export const GetUserShareAccessUrl = `${BASE_URL}/Dashboard/GetUserShareAccess`;

//  platform  name  const platform = window.navigator.platform
export const platform = window.navigator.platform;
export const headers = {
  Accept: "application/json, text/plain, */*", // It can be used to overcome cors errors
  "Content-Type": "application/json",
};

//action type start.....................

// login
export const GET_USER_DETAILS_ACTION_TYPE = "GET_USER_DETAILS";
export const INSERT_USER_DETAILS_ACTION_TYPE = "INSERT_USER_DETAILS";
export const UPDATE_USER_DETAILS_ACTION_TYPE = "UPDATE_USER_DETAILS";
export const GET_USER_LIST_SEARCH_ACTION_TYPE = "GET_USER_LIST_SEARCH";
export const USER_INFO_GET_CLIENT_LIST_SEARCH_ACTION_TYPE = "USER_INFO_GET_CLIENT_LIST_SEARCH";
export const DELETE_USER_ACTION_TYPE = "DELETE_USER";

export const GET_CLIENT_LIST_SEARCH_ACTION_TYPE = "GET_CLIENT_LIST_SEARCH";
export const GET_CLIENT_DETAILS_ACTION_TYPE = "GET_CLIENT_DETAILS";

export const GET_DASHBOARD_GROUP_LIST_SEARCH_ACTION_TYPE = "GET_DASHBOARD_GROUP_LIST_SEARCH";
export const GET_DASHBOARD_GROUP_DETAILS_ACTION_TYPE = "GET_DASHBOARD_GROUP_DETAILS";
export const GET_DASHBOARD_LIST_SEARCH_ACTION_TYPE = "GET_DASHBOARD_LIST_SEARCH";
export const GET_LOAD_DASHBOARD_ACTION_TYPE = "GET_LOAD_DASHBOARD";


//action type end..........................

// action ID name
export const dashboardAccessRightActionID = "1";
export const countryActionID = "2"
export const dashboardGroupActionID = "3"
export const dashboardRoleActionID = "4"


export const appVersion = "1.0.0";
export const buildNumber = "20220511";
export const NA = "NA";




