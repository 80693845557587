import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withTranslation } from 'react-i18next';
import {
    Container, Box, Typography, Button, TextField, Select, MenuItem,
    InputLabel, FormControl, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, IconButton, Pagination, InputAdornment,
    Dialog, DialogTitle, DialogContent, DialogActions, Grid, Icon, Tooltip
} from "@mui/material";
import { getFillListUrl, GetUserListSearchUrl, DeleteActionUrl } from "../../utils/constants";
import { useNavigate } from 'react-router-dom';
import { axiosDelete, axiosGet, axiosPost } from "../../utils/Axios";
import { useToast } from '../../components/toast';
import { useTranslation } from 'react-i18next';
import CommonPagination from "../../components/CommonPagination";
import { Search, List, GridView, StarOutline, Star, Share, Delete, Edit, Close, Save, Cancel } from "@mui/icons-material";

const ColliersUserList = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const { ToastComponent, showToast } = useToast();

    const [userName, setUserName] = useState("");
    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };
    const [email, setEmail] = useState("");
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const [roleID, setrRoleID] = useState("0");
    const handleRoleChange = (event) => {
        setrRoleID(event.target.value);
    };
    const [status, setStatus] = useState("Active");
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    const [colliersUserList, setColliersUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState(colliersUserList);
    const [currentPage, setCurrentPage] = useState(1);
    const [reset, setReset] = useState(false);
    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo == null) {
            navigate("/login/KeyStone_Login");
            return;
        } else {
            fetchData();
            getColliersUserList();
        }

    }, [reset]);

    const [roleList, setRoleList] = useState([]);
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const data = await axiosGet(getFillListUrl, {
                actionID: 5,
                valueID: null,
                keyValue: null
            });
            if (data != null && data.length > 0) {
                setRoleList(data);
            }
            else {
                console.error("Failed to fetch role list: No data found.");
            }
        }
        catch (error) {
            console.error("Error fetching role list:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const searchBtnCall = async () => {
        if (reset == true) {
            setReset(false)
        }
        getColliersUserList();
    }
    const resetSearch = async () => {
        setUserName("");
        setEmail("");
        setrRoleID("");
        setStatus('Active');
        setCurrentPage(1)
        setReset(true)
    };
   
    const listItemsPerPage = 10;
    const startIndex = (currentPage - 1) * listItemsPerPage;
    const paginatedUserList = filteredUserList?.slice(startIndex, startIndex + listItemsPerPage);
    const totalItems = filteredUserList?.length;

    const { t } = useTranslation();



    const getColliersUserList = async () => {
        setIsLoading(true);
        try {
            const colliersUserSearchParam = {
                name: (userName == null || userName == "") ? "" : userName,
                emailAddress: (email == "" || email == null || email == undefined) ? "" : email,
                roleID: (roleID == null || roleID == "" || roleID == "0") ? "0" : roleID,
                status: (status == "Active") ? true : ((status == "" || status == "0") ? null : false)
            };
            const response = await axiosPost(GetUserListSearchUrl, colliersUserSearchParam);
            if (response) {
                setColliersUserList(response);
                setFilteredUserList(response);
                setCurrentPage(1)
            } else {
                showToast('error', 'Failed to fetch user list: No data found.');
            }
        } catch (error) {
            console.error("Error fetching user list:", error);
        } finally {
            setIsLoading(false);
        }
    }
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [userID, setUserID] = useState('');
    const toggleDeleteModal = (userID) => {
        if (isDeleteModal == true) {
            setDeleteModal(false);
            setUserID('');
        } else {
            setDeleteModal(true);
            setUserID(userID);
        }
    };
    const handleDelete = async (userID) => {
        setIsLoading(true);
        try {
            const response = await axiosDelete(DeleteActionUrl, { moduleID: 2, valueID: userID.toString() });
            if (response) {
                showToast('success', 'Successfully Deleted!');
                getColliersUserList();
            } else {
                showToast('error', 'Failed to fetch user list: No data found.');
            }
        } catch (error) {
            showToast('error', `Error fetching user list:, ${error}`)
        } finally {
            setIsLoading(false);
            setDeleteModal(false); // Close the modal after the action
        }
    }
    const editUser = async (userID) => {
        debugger;
        navigate("/colliers-user-management-manage", { state: { userID } });
    }
    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />

            <Box
                style={{
                    position: 'relative',
                    justifyContent: "space-between",
                    alignItems: "center", background: "#EDF2FF", width: '100%', marginTop: 50,
                    padding: '2rem'
                }}>
                <Box></Box>
                <Typography variant="h5" className="padding-top-m text-center page-name">
                    {t('user_mgmt.colliersUserMgmt')}
                </Typography>
                <Button
                    className="add-dashboard-btn"
                    variant="outlined"
                    startIcon={<i className="fa fa-plus" style={{ fontSize: 12 }} />}
                    sx={{
                        fontSize: 13,
                        letterSpacing: "0.5px",
                        textTransform: "uppercase",
                        mx: 3,
                        py: 0.5,
                        px: 3,
                        borderRadius: 1,
                    }}
                    onClick={() => navigate("/colliers-user-management-manage")}
                >
                    {t('common.Add')}
                </Button>
            </Box>



            <main
                id="main"
                style={{
                    justifyContent: "center",
                    minHeight: "100vh",
                    width: "100%",
                    padding: '25px',
                    paddingTop: '0px',
                    marginBottom: '30px'
                }}
            >
                <Box className="pagetitle main-area-top"
                    textAlign="center"

                >



                    <section className="section page-body">
                        <Box  >
                            <Box className="margin-top-base"
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 2,
                                    mb: 3,
                                    alignItems: "flex-start",
                                }}
                            >
                                {/* Name */}
                                <TextField className="padding-top-s"
                                    sx={{ width: "295px" }}
                                    label={t('user_mgmt.name')}
                                    placeholder={t('user_mgmt.enteraName')}
                                    variant="outlined"
                                    value={userName}
                                    onChange={handleUserNameChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // InputProps={{
                                    //     startAdornment: (
                                    //         <InputAdornment position="start"></InputAdornment>
                                    //     ),
                                    // }}
                                />

                                {/* Email */}
                                <TextField  className="padding-top-s"
                                    sx={{ width: "295px" }}
                                    label={t('user_mgmt.email')}
                                    placeholder={t('user_mgmt.addEmail')}
                                    variant="outlined"
                                    value={email}
                                    onChange={handleEmailChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // InputProps={{
                                    //     startAdornment: (
                                    //         <InputAdornment position="start"></InputAdornment>
                                    //     ),
                                    // }}
                                />

                                {/* Role */}
                                <FormControl sx={{ width: "295px" }}>
                                    <TextField  className="padding-top-s"
                                        label={t('user_mgmt.role')}
                                        onChange={handleRoleChange}
                                        value={roleID}
                                        select
                                        variant="outlined"
                                    >
                                        <MenuItem key="0" value="0">
                                            {t('user_mgmt.selectRole')}
                                        </MenuItem>
                                        {roleList.map((item) => (
                                            <MenuItem key={item.code} value={item.code}>
                                                {item.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>

                                {/* Status */}
                                <FormControl sx={{ width: "295px" }}>
                                    <TextField  className="padding-top-s"
                                        label={t('user_mgmt.status')}
                                        onChange={handleStatusChange}
                                        value={status}
                                        select
                                        variant="outlined"
                                    >
                                        <MenuItem value="0">{t('user_mgmt.selectStatus')}</MenuItem>
                                        <MenuItem value="Active">{t("common.active")}</MenuItem>
                                        <MenuItem value="Inactive">{t("common.inactive")}</MenuItem>
                                    </TextField>
                                </FormControl>

                                {/* Spacer for Buttons to Push Them Right */}
                                <Box sx={{ flexGrow: 1 }} />

                                {/* Buttons */}
                                <Button
                                    className=""
                                    variant="contained"
                                    sx={{ alignSelf: "flex-start" }}
                                    onClick={() => searchBtnCall()}
                                >
                                    {t("common.search")}
                                </Button>
                                <Button
                                    className="margin-left-xs"
                                    variant="contained"
                                    sx={{ alignSelf: "flex-start" }}
                                    onClick={() => resetSearch()}
                                >
                                    {t("common.reset")}
                                </Button>
                            </Box>


                            <Box className="row mt-4 m-1">

                                <TableContainer component={Paper} sx={{ mt: 2 }} className="list-height">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t("user_mgmt.name")}</TableCell>
                                                <TableCell>{t("user_mgmt.emailAddress")}</TableCell>
                                                <TableCell>{t("user_mgmt.role")}</TableCell>
                                                <TableCell align="center">{t("user_mgmt.activeDashboard")}</TableCell>
                                                <TableCell>{t("user_mgmt.status")}</TableCell>
                                                <TableCell align="center">{t("common.Action")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {paginatedUserList?.length > 0 ? (
                                                paginatedUserList?.map((user, index) => (
                                                    <TableRow key={user.name}>
                                                        <TableCell>{user.name}</TableCell>
                                                        <TableCell>{user.emailAddress}</TableCell>
                                                        <TableCell>{user.role}</TableCell>
                                                        <TableCell align="center">{user.activeDashboard}</TableCell>
                                                        <TableCell>{user.status}</TableCell>
                                                        <TableCell align="center">
                                                            <Box display="flex" justifyContent="center">
                                                                <Button className="padding-right-none">
                                                                    <Tooltip title="Edit">
                                                                        <Icon className='fa-light fa-pen-to-square font-size-base text-primary' onClick={() => editUser(user.userID)} />
                                                                        </Tooltip>
                                                                </Button>
                                                                <Button>
                                                                    <Tooltip title="Remove">
                                                                        <Icon className='fa-light fa-trash-can font-size-base text-primary' onClick={() => toggleDeleteModal(user.userID)} />
                                                                     </Tooltip>
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))) : (
                                                <TableCell colSpan={6}>{t('common.noRecordsFound')}</TableCell>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <CommonPagination
                                    totalItems={totalItems}
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                />
                                <Dialog
                                    open={isDeleteModal}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "12px",
                                            padding: "16px",
                                            width: "400px",
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            style={{ color: "#000959", marginBottom: "8px" }}
                                        >
                                            {t('common.deleteMessage')}
                                        </Typography>
                                    </DialogTitle>

                                    <DialogContent>
                                        <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                                            {t('user_mgmt.deleteUser')}
                                        </Typography>
                                    </DialogContent>

                                    <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Cancel />}
                                            onClick={() => toggleDeleteModal('')}
                                            style={{
                                                color: "#000959",
                                                borderColor: "#000959",
                                                textTransform: "none",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            {t('common.Cancel')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleDelete(userID)}
                                            startIcon={<Delete />}
                                            style={{
                                                backgroundColor: "#000959",
                                                color: "#FFFFFF",
                                                textTransform: "none",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            {t('common.Delete')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Box>

                        <div style={{ height: 150 }} />
                    </section>
                </Box>
            </main>
            <Footer />
            {ToastComponent}
        </>
        //        </>
        //    )
        //    }
        //</>
    );
};

export default withTranslation()(ColliersUserList);
