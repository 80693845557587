import React, { useState, useEffect } from "react";
import {
    Container,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Autocomplete, Chip, Checkbox, ListItemText, Icon
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withTranslation } from "react-i18next";
import { DashboardGroupLangUrl, InsertDashboardGroupUrl, GetDashboardGroupDetailsUrl, getFillListUrl, UpdateDashboardGroupUrl } from "../../utils/constants";
import { axiosGet, axiosPost } from "../../utils/Axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../components/toast';
import CloseIcon from '@mui/icons-material/Close'; // Default import for icons
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown'; // Replace with actual Material-UI dropdown icon
import ClearIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const DashboardGroupAdd = () => {

    const { ToastComponent, showToast } = useToast();

    const { t } = useTranslation();

    const location = useLocation();
    const { groupID } = location.state || {};

    const [isLoading, setIsLoading] = useState(false);
    const [validate, setValidate] = useState(false);

    const navigate = useNavigate();

    const handleCancel = () => {
        // Logic for canceling the operation
        navigate('/dashboard-group');
        console.log("Canceled!");
    };

    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo == null) {
            navigate('/login/KeyStone_Login');
            return;
        } else {
            fetchData(); // Ensure fetchData is declared or imported
        }
    }, []);

    const [dropdownList, setDropdownList] = useState([]);
    const [groupName, setGroupName] = useState("");

    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCountryIds, setSelectedCountryIds] = useState("");
    const [originalCountryIds, setOriginalCountryIds] = useState("");

    const [languagelist, setlanguagelist] = useState([]);
    const [originalLanguagelist, setOriginalLanguagelist] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const data = await axiosGet(getFillListUrl, {
                actionID: 2,
                valueID: null,
                keyValue: null
            });
            if (data != null && data.length > 0) {
                setDropdownList(data);
                setCountries(data);
                if (groupID) {
                    getGroupInfo(data);
                }
            }
            else {
                console.error("Failed to fetch country list: No data found.");
            }
        }
        catch (error) {
            console.error("Error fetching country list:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getGroupInfo = async (countryList) => {
        debugger;
        setIsLoading(true);
        try {
            const data = await axiosGet(GetDashboardGroupDetailsUrl, { editDashboardGroupID: groupID });
            if (data != null) {
                setGroupName(data.dashboardGroup);
                setlanguagelist(data.dashboardGroupLangLists);
                setSelectedCountryIds(data.countryIDs);
                setOriginalCountryIds(data.countryIDs);
                setOriginalLanguagelist(data.dashboardGroupLangLists);

                const countryIdArray = data.countryIDs.split(',').map(id => parseInt(id, 10));

                const selectedCountries = countryList.filter(country => countryIdArray.includes(Number(country.code)));

                setSelectedCountries(selectedCountries);
            }
            else {
                console.error("Failed to fetch group data: No data found.");
            }
        }
        catch (error) {
            console.error("Error fetching group data:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };
    const handleLanguageChange = (index, newValue) => {
        setlanguagelist(prevList =>
            prevList.map((item, i) =>
                i === index
                    ? { ...item, name: newValue } // Update the 'name' property for the matched item
                    : item // Keep other items unchanged
            )
        );
    };


    const getLanguagesCountrywise = async (value) => {
        debugger;
        setIsLoading(true);
        try {
            let countryIds = "";
            if (value != null && value.length > 0) {
                value.forEach(x => {
                    if (countryIds == "") {
                        countryIds = x.code;
                    }
                    else {
                        countryIds = countryIds + "," + x.code;
                    }
                })
            }
            setSelectedCountryIds(countryIds);
            const data = await axiosGet(DashboardGroupLangUrl, {
                countryIds: countryIds
            });
            //if (data?.data != null && data?.data.length > 0) {
            //    setlanguagelist(data.data);
            //}
            if (data != null && data.length > 0) {
                const updatedLanguageList = data.map(item => ({
                    ...item, // Keep existing properties
                    name: "" // Add a new property 'name' based on 'language'
                }));
                if (languagelist != null && languagelist.length > 0) {
                    languagelist.forEach(x => {
                        if (x.name != "" && x.name != null) {
                            updatedLanguageList.filter(y => y.lang == x.lang)[0].name = x.name;
                        }
                    })
                }
                setlanguagelist(updatedLanguageList);
            }
            else {
                setlanguagelist([]);
                console.error("Failed to fetch language list: No data found.");
            }
        }
        catch (error) {
            console.error("Error fetching language list:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleChange = (event, value) => {
        debugger;
        setSelectedCountries(value);
        console.log(selectedCountries);
        getLanguagesCountrywise(value);
    };
    const deepCompare = (obj1, obj2) => {
        // Convert objects to JSON strings and compare them
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    };
    const compareArrays = (array1, array2) => {
        debugger;
        if (array1.length !== array2.length) return true;

        // Sort the arrays (optional, only needed if order doesn't matter)
        const sortedArray1 = [...array1].sort((a, b) => a.dashboardGroupID - b.dashboardGroupID);
        const sortedArray2 = [...array2].sort((a, b) => a.dashboardGroupID - b.dashboardGroupID);

        // Compare each object in the sorted arrays
        for (let i = 0; i < sortedArray1.length; i++) {
            if (!deepCompare(sortedArray1[i], sortedArray2[i])) {
                return true; // Arrays are different
            }
        }

        return false; // Arrays are identical
    };

    const compareCountryIds = (string1, string2) => {
        debugger;
        // Split the strings into arrays
        const array1 = string1.split(",").map(Number); // Convert to numbers if needed
        const array2 = string2.split(",").map(Number);

        // Check if lengths are different
        if (array1.length !== array2.length) return true;

        // Sort both arrays
        const sortedArray1 = array1.sort((a, b) => a - b);
        const sortedArray2 = array2.sort((a, b) => a - b);

        // Compare sorted arrays
        for (let i = 0; i < sortedArray1.length; i++) {
            if (sortedArray1[i] !== sortedArray2[i]) {
                return true; // Return false if any element is different
            }
        }
        return false; // Arrays contain the same items
    };

    const handleSave = async () => {
        debugger;
        setValidate(true);
        if (groupName != "" && groupName != null && selectedCountryIds != "" && selectedCountryIds != null) {
            let dashboardGroupLangListstemp = [];

            if (languagelist != null && languagelist != undefined && languagelist.length > 0) {
                languagelist.forEach(x => {
                    dashboardGroupLangListstemp.push({ dashboardGroupID: groupID > 0 ? groupID : 0, lang: x.lang, name: x.name })
                })
            }
            if (groupID > 0) {
                //implement loader here
                setIsLoading(true);
                const updateGroup = {
                    dashboardGroupID: groupID,
                    dashboardGroup: (groupName == "" || groupName == null || groupName == undefined) ? "" : groupName,
                    countryIDs: (selectedCountryIds == null || selectedCountryIds == undefined) ? "" : selectedCountryIds,
                    countryName: "",
                    status: "Active",
                    action: "",
                    dashboardGroupLangLists: dashboardGroupLangListstemp
                };
                try {
                    //language list changed?
                    const langChanged = compareArrays(
                        dashboardGroupLangListstemp,
                        originalLanguagelist
                    );
                    //country list changed?
                    const countryChanged = compareCountryIds(
                        selectedCountryIds,
                        originalCountryIds
                    );

                    const response = await axiosPost(`${UpdateDashboardGroupUrl}?isLangChanged=${langChanged}&isCountryChanged=${countryChanged}`,
                        updateGroup);
                    debugger;
                    if (response.code == 200) {
                        setValidate(false);
                        showToast('success', 'Successfully Updated!');
                        setTimeout(() => {
                            navigate('/dashboard-group');
                        }, 1000);
                    } else {
                        showToast('error', 'Failed to save Group!');
                        console.error("Failed to update dashboard groups: No data found.");
                        setIsLoading(false);
                    }
                } catch (error) {
                    showToast('error', 'Failed to update Group!');
                    console.error("Error saving dashboard groups:", error);
                    setIsLoading(false);
                } finally {

                }
            }
            else {
                //implement loader here
                setIsLoading(true);
                const saveGroup = {
                    dashboardGroupID: 0,
                    dashboardGroup: (groupName == "" || groupName == null || groupName == undefined) ? "" : groupName,
                    countryIDs: (selectedCountryIds == null || selectedCountryIds == undefined) ? "" : selectedCountryIds,
                    countryName: "",
                    status: "Active",
                    action: "",
                    dashboardGroupLangLists: dashboardGroupLangListstemp
                };
                try {
                    const response = await axiosPost(InsertDashboardGroupUrl, saveGroup);
                    debugger;
                    if (response.code == 200) {
                        setValidate(false);
                        showToast('success', 'Successfully Saved!');
                        setTimeout(() => {
                            navigate('/dashboard-group');
                        }, 1000);
                    } else {
                        showToast('error', 'Failed to save Group!');
                        console.error("Failed to save dashboard groups: No data found.");
                        setIsLoading(false);
                    }
                } catch (error) {
                    showToast('error', 'Failed to save Group!');
                    console.error("Error saving dashboard groups:", error);
                    setIsLoading(false);
                } finally {

                }
            }


        }
        else {
            console.error("Mandatory fields are required");
        }
    };
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleBlur = (event) => {
        debugger;
        //const relatedTarget = event.relatedTarget; // Focus target
        //if (!relatedTarget || !relatedTarget.closest('.MuiAutocomplete-popup')) {
        //    // Close dropdown if the focus is outside the Autocomplete component
        //    setDropdownOpen(false);
        //}
        setDropdownOpen(false);
    };
    const clickAnyWhereBut = (e, className) => {
        debugger;
        if (!e.current.target.classList.contains(className)) {
            setDropdownOpen(false); /* you have a typo for "complete/compelte" */
        }
    }
    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />
            <main id="main" style={{ marginTop: 70 }}>
                <Box
                    sx={{
                        textAlign: "center",
                        py: 4,
                        backgroundColor: "#EDF2FF",
                    }}
                >
                    <Typography variant="h5" sx={{ textTransform: "uppercase" }}>
                        {t('dashboardGroup.dashboardGroup')}
                    </Typography>
                </Box>
                <Container>
                    <Box
                        sx={{
                            maxWidth: 800,
                            margin: "2rem auto",
                            p: 3,
                            border: "1px solid #ddd",
                            borderRadius: 2,
                            backgroundColor: "white",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ textTransform: "uppercase", mb: 2 }}
                        >
                            {t('dashboardGroup.addDashboardGroup')}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                            {/*<Typography variant="h6">*/}
                            {/*                         {t('dashboard_group.groupName')} <span style={{ color: 'red' }}>*</span>*/}
                            {/*</Typography>*/}
                            <TextField
                                className="space-lab"
                                fullWidth
                                label={t('dashboardGroup.groupName')}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 100,  // Set the maxLength to 100 characters
                                }}
                                placeholder={t('dashboardGroup.enterGroupName')}
                                variant="outlined" value={groupName} onChange={handleGroupNameChange}
                                error={validate && (groupName == "" || groupName == null)}
                                helperText={(validate && (groupName == "" || groupName == null)) ? t('dashboardGroup.pleaseEnterGroupName') : ""}
                            />
                            <FormControl fullWidth>
                                {/*<InputLabel>Country<span style={{ color: 'red' }}>*</span></InputLabel>*/}
                                <Autocomplete
                                    multiple
                                    ChipProps={{ deleteIcon: <CloseIcon /> }}
                                    options={countries}
                                    disableCloseOnSelect
                                    popupIcon={< ChevronDownIcon />}
                                    clearIcon={< ClearIcon />}
                                    getOptionLabel={(option) => option.description}
                                    value={selectedCountries}
                                    onChange={handleChange}
                                    autoclose={false}
                                    open={isDropdownOpen}
                                    onOpen={() => setDropdownOpen(true)}
                                    onClose={() => setDropdownOpen(false)}
                                    onBlur={() => setDropdownOpen(false)}
                                    className="App"
                                    onClick={() => clickAnyWhereBut("autoCompleteBox")}
                                    renderOption={(props, option, { selected }) =>
                                    (
                                        <MenuItem{...props}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={<CheckBoxIcon />}
                                                checked={selected}
                                            />
                                            <ListItemText className='margin-left-s'>
                                                {option.description}
                                            </ListItemText>
                                        </MenuItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('dashboardGroup.selectCountries')}
                                            className="space-lab"
                                            placeholder={t('dashboardGroup.countries')}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validate && (selectedCountryIds == "" || selectedCountryIds == null || selectedCountryIds == undefined)}
                                            helperText={(validate && (selectedCountryIds == "" || selectedCountryIds == null || selectedCountryIds == undefined)) ? t('dashboardGroup.pleaseSelectCountry') : ""}
                                        />
                                    )}

                                />
                            </FormControl>
                        </Box>
                        {languagelist?.length > 0 && (
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                {t('dashboardGroup.languageEntries')}
                            </Typography>
                        )}
                        {languagelist?.length > 0 && (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('dashboardGroup.languageEntries')}</TableCell>
                                            <TableCell>Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {languagelist.map((lang, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{lang.lang}</TableCell>
                                                <TableCell className="padding-top-none">
                                                    <TextField
                                                        fullWidth
                                                        placeholder={`Enter name for ${lang.lang}`}
                                                        variant="outlined"
                                                        size="small"
                                                        inputProps={{
                                                            maxLength: 100,  // Set the maxLength to 100 characters
                                                        }} value={lang.name}
                                                        onChange={(e) => handleLanguageChange(index, e.target.value)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 3,
                            }}
                        >
                            <Button className="margin-right-base"
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                            >
                                {t('dashboardGroup.save')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCancel}
                            >
                                {t('dashboardGroup.cancel')}
                            </Button>
                        </Box>
                    </Box>
                </Container>
                <Footer />
                {ToastComponent}
            </main>
        </>
        //    )}
        //</>
    );
};

export default withTranslation()(DashboardGroupAdd);
