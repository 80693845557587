import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Grid,
    Container,
    Box,
    Typography,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withTranslation } from "react-i18next";
import { GetAuditTrailSearchUrl, GetAuditTrailExcel } from "../../utils/constants";
import { axiosGet } from "../../utils/Axios";
import CommonPagination from "../../components/CommonPagination";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../components/toast';
import moment from "moment";
import _ from 'lodash';

const AuditTrial = () => {
    const navigate = useNavigate();

    const { ToastComponent, showToast } = useToast();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [reset, setReset] = useState(false);

    const [dateRange, setDateRange] = useState("");
    const [showDatePickers, setShowDatePickers] = useState(false);
    const openDatePickers = () => {
        setShowDatePickers(true);
    };
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const fromDateRef = useRef("");
    const toDateRef = useRef("");
    const handleFromDateChange = useCallback(
        _.debounce((event) => {
            if (event != "" && event != null && event != undefined) {
                //const formattedfromDate = dayjs(event).format('DD-MMM-YYYY');
                const formattedfromDate = moment(event, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
                setFromDate(event);
                fromDateRef.current = formattedfromDate;
                console.log("fromDate:", fromDate);
                handleDateChange();
            }
        }, 300), []);

    const handleToDateChange = useCallback(
        _.debounce((event) => {
            if (event != "" && event != null && event != undefined) {
                //const formattedfromDate = dayjs(event).format('DD-MMM-YYYY');
                const formattedtoDate = moment(event, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
                setToDate(event);
                toDateRef.current = formattedtoDate;
                handleDateChange();
            }
        }, 300), []);
    const handleDateChange = (event) => {
        if ((fromDateRef.current != null && fromDateRef.current != undefined && fromDateRef.current != "") &&
            (toDateRef.current == "" || toDateRef.current == undefined || toDateRef.current == null)) {
            setDateRange("greater than " + fromDateRef.current);
        }
        else if ((toDateRef.current != null && toDateRef.current != undefined && toDateRef.current != "") &&
            (fromDateRef.current == "" || fromDateRef.current == undefined || fromDateRef.current == null)) {
            setDateRange("less than " + toDateRef.current);
        }
        else if (fromDateRef.current != null && fromDateRef.current != undefined && fromDateRef.current != "" &&
            toDateRef.current != null && toDateRef.current != undefined && toDateRef.current != "") {
            //setDateRange(fromDateRef.current + "|" + toDateRef.current);
            setDateRange("Between " + fromDateRef.current + " and " + toDateRef.current);
        }
        else if (fromDateRef.current == null || fromDateRef.current == undefined || fromDateRef.current == "" ||
            toDateRef.current == null || toDateRef.current == undefined || toDateRef.current == "") {
            setDateRange("");
        }
    }
    const handleFromKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            setFromDate('');
            fromDateRef.current = "";
            handleDateChange();
        }
    };
    const handleToKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            setToDate('');
            toDateRef.current = "";
            handleDateChange();
        }
    }
    const [emailText, setEmailText] = useState("");

    const [auditTrailList, setAuditTrailList] = useState([]);
    const [filteredAuditTrail, setFilteredAuditTrail] = useState(auditTrailList);

    const [currentPage, setCurrentPage] = useState(1);
    const listItemsPerPage = 10;
    const startIndex = (currentPage - 1) * listItemsPerPage;
    const paginatedAuditTrail = filteredAuditTrail?.slice(startIndex, startIndex + listItemsPerPage);
    const totalItems = filteredAuditTrail?.length;

    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo == null) {
            navigate("/login/KeyStone_Login");
            return;
        } else {
            getAuditTrailList();
        }
    }, [reset]);

    const searchBtnCall = async () => {
        setReset(false)
        getAuditTrailList();
    }
    const resetSearch = async () => {
        setFromDate("");
        setToDate("");
        setEmailText("");
        setDateRange("");
        setShowDatePickers(false);
        setReset(true)
    };
    const getAuditTrailList = async () => {
        setIsLoading(true);

        try {
            const response = await axiosGet(GetAuditTrailSearchUrl, {
                dateRange: (dateRange == "" || dateRange == null || dateRange == undefined) ? "" : dateRange.toString().indexOf("Between") > -1 ? (fromDateRef.current + "|" + toDateRef.current) : dateRange,
                email: (emailText == "" || emailText == null || emailText == undefined) ? "" : emailText
            });
            if (response) {
                setAuditTrailList(response);
                setFilteredAuditTrail(response);
            } else {
                showToast('error', 'Failed to fetch Audit Trail: No data found.');
            }
        } catch (error) {
            showToast('error', 'Failed to fetch Audit Trail: No data found.');
        } finally {
            setIsLoading(false);
        }
    }
    const exportBtnCall = async () => {
        setIsLoading(true);
        try {
            const response = await axiosGet(GetAuditTrailExcel, {
                dateRange: (dateRange == "" || dateRange == null || dateRange == undefined) ? "" : dateRange.toString().indexOf("Between") > -1 ? (fromDateRef.current + "|" + toDateRef.current) : dateRange,
                email: (emailText == "" || emailText == null || emailText == undefined) ? "" : emailText
            });
            if (response) {
                window.open(response.responseMsg);
            } else {
                showToast('error', 'Failed to generate excel');
            }
        } catch (error) {
            showToast('error', 'Failed to generate excel');
        } finally {
            setIsLoading(false);
        }
    }
    const handleEmailChange = (event) => {
        setEmailText(event.target.value);
    };

    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />
            <Box style={{
                position: 'relative',
                justifyContent: "space-between",
                alignItems: "center", background: "#EDF2FF", width: '100%', marginTop: 50,
                padding: '2rem'
            }}>
                <Typography variant="h5" className="padding-top-m text-center page-name">
                    {t('auditTrail.auditTrail')}
                </Typography>
            </Box>
            <main
                id="main"
                style={{
                    justifyContent: "center",
                    minHeight: "100vh",
                    width: "100%",
                    padding: '24px'
                }}
            >

                <>

                    <Box>
                        {/* Search Filters */}
                        <Box className="margin-bottom-m">
                            <Grid container spacing={2}>
                                {/* Date Range Input */}
                                <Grid item xs={12} md={3}>
                                    <Typography className="cus-label-date" variant="subtitle2" gutterBottom>
                                        {t('dashboard_log.dateRange')}
                                    </Typography>
                                    {/* label={t('dashboard_log.dateRange')} */}
                                 
                                        <TextField
                                        style={{ marginTop: '-15px' }}
                                        fullWidth
                                        value={dateRange}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        onClick={openDatePickers}
                                        placeholder={t('auditTrail.selectDate')}
                                    />
                                    {showDatePickers === true && (
                                        <div style={{ display: 'flex', gap: '10px', marginTop: 'px' }}>
                                            <TextField
                                                fullWidth
                                                type="date"
                                                value={fromDate}
                                                onChange={(e) => handleFromDateChange(e.target.value)}
                                                required
                                                variant="outlined"
                                                onKeyDown={handleFromKeyDown}
                                            />
                                            <TextField
                                                fullWidth
                                                type="date"
                                                value={toDate}
                                                onChange={(e) => handleToDateChange(e.target.value)}
                                                required
                                                variant="outlined"
                                                onKeyDown={handleToKeyDown}
                                            />
                                        </div>
                                    )}
                                </Grid>

                                {/* Email Input */}
                                <Grid item xs={12} md={3}>
                                    {/* <Typography variant="subtitle2" sx={{marginBottom:'0px'}} gutterBottom>
                                                {t('auditTrail.email')}
                                            </Typography> */}

                                    {/* <Typography className="cus-label-date" variant="subtitle2" gutterBottom>
                                                Email
                                            </Typography> */}
                                    <TextField

                                        className="space-lab"
                                        sx={{ maxWidth: "350px", width: '350px', paddingTop: "10px" }}
                                        label={t('dashboard_log.name')}
                                        placeholder={t('dashboard_log.enterAName')}
                                        fullWidth
                                        variant="outlined"
                                        value={emailText}
                                        onChange={handleEmailChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                {/* View Button */}
                                <Grid item xs={12} md={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        onClick={() => searchBtnCall()}
                                    >
                                        {t('common.view')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={1}>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        onClick={() => resetSearch()}
                                    >
                                        {t('common.reset')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>

                        {/* Record Info */}
                        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <Typography className="table-rec" variant="body2">{t('common.total')} {totalItems} {t('common.recordsFound')}</Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ borderColor: "#25408f" }}
                                onClick={() => exportBtnCall()}
                            >
                                {t('common.exportToExcel')}
                            </Button>
                        </Box>

                        {/* Data Table */}
                        <TableContainer component={Paper} sx={{ mb: 3 }} className="list-height-aud">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('auditTrail.actionedDateTime')}</TableCell>
                                        <TableCell>{t('auditTrail.actionedBy')}</TableCell>
                                        <TableCell>{t('auditTrail.userType')}</TableCell>
                                        <TableCell>{t('auditTrail.dashboard')}</TableCell>
                                        <TableCell>{t('auditTrail.group')}</TableCell>
                                        <TableCell>{t('auditTrail.lang')}</TableCell>
                                        <TableCell>{t('auditTrail.action')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedAuditTrail?.length > 0 ? (
                                        paginatedAuditTrail?.map((auditTrail, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{moment(auditTrail.actionDate, "DD-MM-YYYY HH:mm:ss").isValid()
                                                    ? moment(auditTrail.actionDate, "DD-MM-YYYY HH:mm:ss").format("DD MMM YYYY HH:mm:ss")
                                                    : t('common.invalidDate')}</TableCell>
                                                <TableCell>{auditTrail.actionBy}</TableCell>
                                                <TableCell>{auditTrail.userType}</TableCell>
                                                <TableCell>{auditTrail.dashboard}</TableCell>
                                                <TableCell>{auditTrail.dashboardGroup}</TableCell>
                                                <TableCell>{auditTrail.lang}</TableCell>
                                                <TableCell>{auditTrail.action}</TableCell>
                                            </TableRow>
                                        ))) : (
                                        <TableCell colSpan={7}>{t('common.noRecordsFound')}</TableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Pagination */}
                        <CommonPagination
                            totalItems={totalItems}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                        />
                    </Box>
                    <Box sx={{ height: '50px' }}></Box>
                </>

            </main>
            <Footer />
            {ToastComponent}
        </>
        //    )}
        //</>
    );
};

export default withTranslation()(AuditTrial);
