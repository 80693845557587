import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import moment from "moment";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import {
    Box, Button, Checkbox, Container, FormControl, FormControlLabel,
    FormHelperText, Grid, InputLabel, MenuItem, Select, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
    Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions,
    IconButton, Icon, Tooltip
} from "@mui/material";
import { Search, List, GridView, StarOutline, Star, Share, Delete, Edit, Close, Save, Cancel } from "@mui/icons-material";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../components/toast';
import { axiosDelete, axiosGet, axiosPost } from "../../utils/Axios";
import { getFillListUrl, InsertUserDetailsUrl, GetUserDetailsUrl, DeleteDashboardUserUrl, DeleteUserUrl, UpdateUserDetailsUrl } from "../../utils/constants";
import CommonPagination from "../../components/CommonPagination";

const ColliersUserManage = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { ToastComponent, showToast } = useToast();

    const { t } = useTranslation();

    const location = useLocation();
    const { userID } = location.state || {};
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();

    const [validate, setValidate] = useState(false);

    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo == null) {
            navigate('/login/KeyStone_Login');
            return;
        } else {
            fetchData();
            if (userID) {
                setIsEdit(true);
            }
        }
    }, [userID]);

    const [countryList, setCountryList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [countryID, setCountryID] = useState("0");
    const handleCountryChange = (event) => {
        const selectedCountryID = event.target.value;
        setCountryID(selectedCountryID);
        if (selectedCountryID > 0) {
            const updatedCountries = accessCountries.map((country) =>
                country.code === selectedCountryID
                    ? { ...country, defaultChecked: true } // Update the matched country
                    : country
            );
            //const tempCountries = accessCountries;
            //tempCountries.forEach(x => {
            //    if (x.code == event.target.value) {
            //        x.defaultChecked = true;
            //    }
            //})
            setAccessCountries(updatedCountries);
        }
    };
    const handleAccessCountryChange = (code, checked) => {
        if (code == countryID && checked == false) {
            setCountryID("0");
        }
        if (checked == false) {
            const updatedCountries = accessCountries.map((country) =>
                country.code === code
                    ? { ...country, defaultChecked: false } // Update the matched country
                    : country
            );
            setAccessCountries(updatedCountries);
        }
        else if (checked == true) {
            const updatedCountries = accessCountries.map((country) =>
                country.code === code
                    ? { ...country, defaultChecked: true } // Update the matched country
                    : country
            );
            setAccessCountries(updatedCountries);
        }
        //setAccessCountries((prevCountries) =>
        //    prevCountries.map((country) =>
        //        country.code === code
        //            ? { ...country, defaultChecked: checked }
        //            : country
        //    )
        //);
    }
    const [roleID, setRoleID] = useState("0");
    const handleRoleChange = (event) => {
        setRoleID(event.target.value);
    };
    const [accessRightList, setAccessRightList] = useState([]);
    const [accessCountries, setAccessCountries] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        let updatedAccessCountries = [];
        let updatedAccessRights = [];
        try {
            const data = await axiosGet(getFillListUrl, {
                actionID: 2,
                valueID: null,
                keyValue: null
            });
            if (data != null && data.length > 0) {
                setCountryList(data);
                updatedAccessCountries = data.map(item => ({
                    ...item,
                    defaultChecked: false
                }));
                setAccessCountries(updatedAccessCountries);
            }
            else {
                console.error("Failed to fetch country list: No data found.");
            }
            const roledata = await axiosGet(getFillListUrl, {
                actionID: 5,
                valueID: null,
                keyValue: null
            });
            if (roledata != null && roledata.length > 0) {
                setRoleList(roledata);
            }
            else {
                console.error("Failed to fetch role list: No data found.");
            }
            const accessRightdata = await axiosGet(getFillListUrl, {
                actionID: 1,
                valueID: null,
                keyValue: null
            });
            if (accessRightdata != null && accessRightdata.length > 0) {
                updatedAccessRights = accessRightdata.map(item => ({
                    ...item,
                    defaultChecked: false
                }));
                setAccessRightList(updatedAccessRights);
                if (userID) {
                    getUserInfo(updatedAccessCountries, updatedAccessRights);
                }
            }
            else {
                console.error("Failed to fetch access right list: No data found.");
            }
        }
        catch (error) {
            console.error("Error fetching country list or role list or access right list:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const [email, setEmail] = useState("");
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const [name, setName] = useState("");
    const handleNameChange = (event) => {
        setName(event.target.value);
    }
    const handleAccessRightChange = (code, checked) => {

        if (checked == false) {
            const updatedRights = accessRightList.map((right) =>
                right.code === code
                    ? { ...right, defaultChecked: false }
                    : right
            );
            setAccessRightList(updatedRights);
        }
        else if (checked == true) {
            const updatedRights = accessRightList.map((right) =>
                right.code === code
                    ? { ...right, defaultChecked: true }
                    : right
            );
            setAccessRightList(updatedRights);
        }
        //setAccessCountries((prevCountries) =>
        //    prevCountries.map((country) =>
        //        country.code === code
        //            ? { ...country, defaultChecked: checked }
        //            : country
        //    )
        //);
    }

    const compareStringIds = (string1, string2) => {
        // Split the strings into arrays
        const array1 = string1.split(",").map(Number); // Convert to numbers if needed
        const array2 = string2.split(",").map(Number);

        // Check if lengths are different
        if (array1.length !== array2.length) return true;

        // Sort both arrays
        const sortedArray1 = array1.sort((a, b) => a - b);
        const sortedArray2 = array2.sort((a, b) => a - b);

        // Compare sorted arrays
        for (let i = 0; i < sortedArray1.length; i++) {
            if (sortedArray1[i] !== sortedArray2[i]) {
                return true; // Return false if any element is different
            }
        }
        return false; // Arrays contain the same items
    };
    const validateAccess = () => {
        if (accessRightList != null && accessRightList != undefined && accessRightList.length > 0) {
            if (accessRightList.filter(x => x.defaultChecked == true).length > 0) {
                return "";
            }
            else {
                return "access rights are mandatory";
            }
        }
        else {
            return "access rights are mandatory";
        }
        return "";
    }
    const anyCountrySelected = () => {
        return accessCountries.some(country => country.defaultChecked);
    };

    // const validateCountry = () => {
    //     if (accessCountries != null && accessCountries != undefined && accessCountries.length > 0) {
    //         if (accessCountries.filter(x => x.defaultChecked == true).length > 0) {
    //             return "";
    //         }
    //         else {
    //             return "country selection is mandatory";
    //         }
    //     }
    //     else {
    //         return "country selection is mandatory";
    //     }
    //     return "";
    // }
    const InsertUser = async () => {
        setValidate(true);
        let accessRightTemp = "";
        if (accessRightList != null && accessRightList != undefined && accessRightList.length > 0) {
            accessRightList.forEach(x => {
                if (x.defaultChecked == true) {
                    accessRightTemp = (accessRightTemp == "") ? x.code : accessRightTemp + "," + x.code;
                }
            })
        }
        let countryAccessTemp = "";
        if (accessCountries != null && accessCountries != undefined && accessCountries.length > 0) {
            accessCountries.forEach(x => {
                if (x.defaultChecked == true) {
                    countryAccessTemp = (countryAccessTemp == "") ? x.code : countryAccessTemp + "," + x.code;
                }
            })
        }
        if (name != null && name != "" && validateEmail() == "" && roleID > 0 && countryID > 0 && accessRightTemp != "" && countryAccessTemp != "") {
            if (userID > 0) {
                //access right list changed?
                const accessChanged = compareStringIds(
                    accessRightTemp,
                    originalrightIds
                );
                //country list changed?
                const countryChanged = compareStringIds(
                    countryAccessTemp,
                    originalcountryIds
                );
                try {
                    setIsLoading(true);
                    const updateUser = {
                        name: (name == "" || name == null || name == undefined) ? "" : name,
                        emailAddress: (email == null || email == undefined || email == "") ? "" : email,
                        roleID: (roleID != null && roleID != undefined && roleID != "0") ? Number(roleID) : 0,
                        basecountryID: (countryID != null && countryID != undefined && countryID != "0") ? Number(countryID) : 0,
                        updateuserID: userID,
                        accessRight: accessRightTemp,
                        countryAccess: countryAccessTemp,
                        isAccessChanged: accessChanged,
                        isCountryChanged: countryChanged
                    };
                    const response = await axiosPost(UpdateUserDetailsUrl, updateUser);
                    if (response.code == 200) {
                        setValidate(false);
                        showToast('success', 'Successfully Updated!');
                        setTimeout(() => {
                            navigate('/colliers-user-management');
                        }, 1000);

                    } else {
                        showToast('error', response.responseMsg);
                        setIsLoading(false);
                    }
                }
                catch (error) {
                    console.error("Error updating user:", error);
                    setIsLoading(false);
                } finally {
                    /*setIsLoading(false);*/
                }
            }
            else {
                try {
                    setIsLoading(true);
                    const saveUser = {
                        name: (name == "" || name == null || name == undefined) ? "" : name,
                        emailAddress: (email == null || email == undefined || email == "") ? "" : email,
                        roleID: (roleID != null && roleID != undefined && roleID != "0") ? Number(roleID) : 0,
                        basecountryID: (countryID != null && countryID != undefined && countryID != "0") ? Number(countryID) : 0,
                        updateuserID: 0,
                        accessRight: accessRightTemp,
                        countryAccess: countryAccessTemp
                    };
                    const response = await axiosPost(InsertUserDetailsUrl, saveUser);
                    if (response.code == 200) {
                        setValidate(false);
                        showToast('success', 'Successfully Saved!');
                        setTimeout(() => {
                            navigate('/colliers-user-management');
                        }, 1000);
                    } else {
                        showToast('error', response.responseMsg);
                        setIsLoading(false);
                    }
                }
                catch (error) {
                    console.error("Error saving user:", error);
                    setIsLoading(false);
                } finally {
                    /*setIsLoading(false);*/
                }
            }
        }
        else {
            console.error("Mandatory fields are required");
        }
    }
    const [activeDashboard, setActiveDashboard] = useState("");
    const [viewedDashboard, setViewedDashboard] = useState("");
    const [sharedDashboard, setSharedDashboard] = useState("");
    const [dashboards, setDashboards] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const listItemsPerPage = 10;
    const [filteredDashboardLog, setFilteredDashboardLog] = useState(dashboards);
    const startIndex = (currentPage - 1) * listItemsPerPage;
    const paginatedDashboardLog = filteredDashboardLog?.slice(startIndex, startIndex + listItemsPerPage);
    const totalItems = filteredDashboardLog?.length;
    const [originalcountryIds, setOriginalcountryIds] = useState("");
    const [originalrightIds, setOriginalrightIds] = useState("");
    const getUserInfo = async (updatedAccessCountries, updatedAccessRights) => {
        setIsLoading(true);
        try {
            const data = await axiosGet(GetUserDetailsUrl, { edituserID: Number(userID) });
            if (data != null) {
                setName(data.userName);
                setEmail(data.emailAddress);
                setCountryID(data.basecountryID);
                setRoleID(data.roleID);
                setActiveDashboard(data.activeDashboard);
                setViewedDashboard(data.viewedDashboard);
                setSharedDashboard(data.sharedDashboard);
                if (data.userCountries != null && data.userCountries != undefined && data.userCountries.length > 0) {
                    const countryIdArray = data.userCountries.map(country => parseInt(country.countryID, 10));
                    const newAccessCountries = updatedAccessCountries.map(item => ({
                        ...item,
                        defaultChecked: false
                    }));
                    newAccessCountries.forEach(x => {
                        if (countryIdArray.includes(Number(x.code))) {
                            x.defaultChecked = true
                        }
                    })
                    setAccessCountries(newAccessCountries);

                    const countryIdString = countryIdArray.join(',');
                    setOriginalcountryIds(countryIdString);
                }
                if (data.accessRights != null && data.accessRights != undefined && data.accessRights.length > 0) {
                    const rightIdArray = data.accessRights.map(right => parseInt(right.accessRightID, 10));
                    const newAccessRights = updatedAccessRights.map(item => ({
                        ...item,
                        defaultChecked: false
                    }));
                    newAccessRights.forEach(x => {
                        if (rightIdArray.includes(Number(x.code))) {
                            x.defaultChecked = true
                        }
                    })
                    setAccessRightList(newAccessRights);

                    const rightIdString = rightIdArray.join(',');
                    setOriginalrightIds(rightIdString);
                }
                setDashboards(data.dashboards);
                setFilteredDashboardLog(data.dashboards);
            }
        }
        catch (error) {
            console.error("Error saving user:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const [dashboardGroupID, setDashboardGroupID] = useState("");
    const [dashboardID, setdashboardID] = useState("");
    const [isDeleteModal, setDeleteModal] = useState(false);

    const toggleDeleteModal = (dashboardGroupID, dashboardID) => {
        if (isDeleteModal == true) {
            setDeleteModal(false);
            setDashboardGroupID('');
            setdashboardID('');
        } else {
            setDeleteModal(true);
            setDashboardGroupID(dashboardGroupID);
            setdashboardID(dashboardID);
        }
    };
    const handleDelete = async (dashboardGroupID, dashboardID) => {
        try {
            setIsLoading(true);
            const response = await axiosDelete(DeleteDashboardUserUrl, { deleteUserID: userID, deleteADGroupID: dashboardGroupID, dashboardID: dashboardID });
            if (response) {
                showToast('success', 'Successfully Deleted!');
                const updatedDashboards = dashboards.filter(x => Number(x.dashboardID) != Number(dashboardID));
                setDashboards(updatedDashboards);
                setFilteredDashboardLog(updatedDashboards);
                setActiveDashboard(response);
            } else {
                showToast('error', 'Failed to Delete');
            }
        } catch (error) {
            showToast('error', `Error in Delete Dashboard:, ${error}`)
        } finally {
            setIsLoading(false);
            setDeleteModal(false); // Close the modal after the action
        }
    }
    const [isDisableModal, setDisableModal] = useState(false);
    const toggleDisableModel = async () => {
        if (isDisableModal == true) {
            setDisableModal(false);
        } else {
            setDisableModal(true);
        }
    }
    const handleDisable = async () => {
        try {
            setIsLoading(true);
            const response = await axiosDelete(DeleteUserUrl, { deleteuserID: userID });
            if (response) {
                showToast('success', 'Successfully Deleted!');
                navigate('/colliers-user-management');
            } else {
                showToast('error', 'Failed to Delete');
            }
        } catch (error) {
            showToast('error', `Error in Delete Dashboard:, ${error}`)
        } finally {
            setIsLoading(false);
            setDisableModal(false); // Close the modal after the action
        }
    }
    const onCancelClick = async () => {
        navigate("/colliers-user-management");
    }
    const validateEmail = () => {
        let check = true;
        let errmsg = "";

        if (email == "" || email == null || !is_email(email)) {
            check = false;
            errmsg = "Invalid Email";
        }
        if (!check) {
            return errmsg;
        }

        return errmsg;
    }
    const is_email = (str) => {
        //if (str.toString().indexOf("@Colliers") > -1) {

        var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (str.match(validRegex)) {
            return true;
        }
        else {
            return false;
        }
        //}
        //else {
        //    return false;
        //}
    }
    const deriveName = async () => {
        if (email != "" && email != null && is_email(email)) {
            const tempName = email.toString().split("@")[0];
            setName(tempName);
        }
    }
    const formatValidUntilDate = (date, t) => {
        if (!date || date === "") return ""; // Handle empty values

        const parsedDate = moment(date, "DD-MM-YYYY HH:mm:ss", true); // 'true' ensures strict parsing
        return parsedDate.isValid() ? parsedDate.format("DD MMM YYYY") : t("common.invalidDate");
    };
    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />

            <Box
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#EDF2FF",
                    width: "100%",
                    marginTop: 40,
                    padding: "2rem",
                }}
            >
                <Typography variant="h5" className="padding-top-m text-center page-name">
                    {t('user_mgmt.colliersUserMgmt')}
                </Typography>
            </Box>

            <main
                id="main"
                style={{
                    minHeight: "100vh",
                    width: "100%",
                    padding: '25px',
                    paddingTop: '15px'
                }}
            >



                <Box >


                    <Box style={{ border: '0px' }} mb={3}>
                        <Card className="padding-top-s padding-left-base">
                            <CardContent>
                                {isEdit ? (<Typography className="box-name-tit text-align-left padding-top-s" variant="h6" textTransform="uppercase" mb={2}>
                                    {t('user_mgmt.editUser')}
                                </Typography>) : (<Typography className="box-name-tit text-align-left padding-top-s" variant="h6" textTransform="uppercase" mb={2}>
                                    {t('user_mgmt.addNewUser')}
                                </Typography>)}
                                <Box style={{ height: '15px' }}></Box>
                                {isEdit && (
                                    <Grid container spacing={2} mb={3}>
                                        <Grid item xs={6} sm={3}>
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    border: "1px solid #ccc",
                                                    borderRadius: 1,
                                                    padding: 2,
                                                }}
                                            >
                                                <Typography variant="h5" fontWeight="bold">
                                                    {activeDashboard}
                                                </Typography>
                                                <Typography>{t('user_mgmt.activeDashboards')}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    border: "1px solid #ccc",
                                                    borderRadius: 1,
                                                    padding: 2,
                                                }}
                                            >
                                                <Typography variant="h5" fontWeight="bold">
                                                    {viewedDashboard}
                                                </Typography>
                                                <Typography>{t('user_mgmt.viewedDashboards')}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    border: "1px solid #ccc",
                                                    borderRadius: 1,
                                                    padding: 2,
                                                }}
                                            >
                                                <Typography variant="h5" fontWeight="bold">
                                                    {sharedDashboard}
                                                </Typography>
                                                <Typography>{t('user_mgmt.sharedDashboards')}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <TextField className="space-lab"
                                            label={t('user_mgmt.email')}
                                            variant="outlined"
                                            fullWidth
                                            placeholder={t('user_mgmt.addEmail')}
                                            value={email}
                                            onChange={handleEmailChange}
                                            onBlur={deriveName}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validate && validateEmail() != ""}
                                            helperText={(validate && validateEmail() != "") ? t('user_mgmt.pleaseEnterEmail') : ""}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField className="space-lab"
                                            label={t('user_mgmt.name')}
                                            variant="outlined"
                                            fullWidth
                                            placeholder={t('user_mgmt.enteraName')}
                                            value={name}
                                            onChange={handleNameChange}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validate && (name == "" || name == null)}
                                            helperText={(validate && (name == "" || name == null)) ? t('user_mgmt.pleaseEnterName') : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth >
                                            <TextField className="space-lab"
                                                label={t('user_mgmt.country')}
                                                onChange={handleCountryChange}
                                                value={countryID}
                                                select
                                                fullWidth
                                                required
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                error={validate && (countryID == "" || countryID == null || countryID == "0")}
                                                helperText={(validate && (countryID == "" || countryID == null || countryID == "0")) ? t('user_mgmt.pleaseSelectCountry') : ""}
                                            >
                                                <MenuItem key="0" value="0">
                                                    {t('user_mgmt.selectCountry')}
                                                </MenuItem>
                                                {countryList.map((item) => (
                                                    <MenuItem key={item.code} value={item.code}>
                                                        {item.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <TextField
                                                className="space-lab"
                                                label={t('user_mgmt.role')}
                                                onChange={handleRoleChange}
                                                value={roleID}
                                                select
                                                fullWidth
                                                required
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                error={validate && (roleID == "" || roleID == null || roleID == "0")}
                                                helperText={(validate && (roleID == "" || roleID == null || roleID == "0")) ? t('user_mgmt.pleaseSelectRole') : ""}
                                            >
                                                <MenuItem key="0" value="0">
                                                    {t('user_mgmt.selectRole')}
                                                </MenuItem>
                                                {roleList.map((item) => (
                                                    <MenuItem key={item.code} value={item.code}>
                                                        {item.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} mt={1}>
                                    <Grid item xs={12} md={4}>
                                        <Typography className="  box-name-tit margin-bottom-m " variant="h6" textTransform="uppercase">
                                            {t('user_mgmt.accessRights')} <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </Typography><div style={{ height: '5px' }}></div>
                                        {accessRightList.map((right, index) => (
                                            <FormControl className="margin-left-xs single-line margin-left-none" key={index} error={validate && validateAccess() !== ""}>
                                                <FormControlLabel className="margin-left-none"
                                                    control={<Checkbox sx={{ marginRight: '-8px' }} checked={right.defaultChecked}
                                                        onChange={(e) => handleAccessRightChange(right.code, e.target.checked)} className="margin-left-none" />}
                                                    label={right.description}
                                                />
                                                {index === accessRightList.length - 1 && validate && validateAccess() !== "" && (
                                                    <FormHelperText className="margin-left-none">
                                                        {t('user_mgmt.pleaseSelectOneAccess')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" className="margin-bottom-s box-name-tit" textTransform="uppercase">
                                            {t('user_mgmt.accessibleCountry')} <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </Typography>
                                        <div style={{ height: '5px' }}></div>

                                        {/* Map through the countries and display the checkboxes */}
                                        {accessCountries.map((country, index) => (
                                            <FormControl className="margin-left-xs" key={index} error={validate && !anyCountrySelected()}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{ marginRight: '-8px' }}
                                                            checked={country.defaultChecked}
                                                            onChange={(e) => handleAccessCountryChange(country.code, e.target.checked)}
                                                        />
                                                    }
                                                    label={country.description}
                                                />
                                            </FormControl>
                                        ))}

                                        {/* Show error message only if no country is selected */}
                                        {validate && !anyCountrySelected() && (
                                            <Typography variant="body2" sx={{ fontSize: '11px', color: 'red' }} color="error" className="margin-left-none">
                                                {t('user_mgmt.pleaseSelectOneCountry')}
                                            </Typography>
                                        )}
                                    </Grid>


                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                    {isEdit && (
                        <Box className="site-border" style={{ padding: '23px' }}>
                            {isEdit && (<Typography variant="h6" className="box-name-tit" textTransform="uppercase" mb={2}>
                                {t('user_mgmt.listOfAccessibleDashboard')}
                            </Typography>)}
                            {isEdit && (<TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('user_mgmt.dashboard')}</TableCell>
                                            <TableCell>{t('user_mgmt.group')}</TableCell>
                                            <TableCell>{t('user_mgmt.accessRights')}</TableCell>
                                            <TableCell>{t('user_mgmt.validTil')}</TableCell>
                                            <TableCell align="center">{t('common.Action')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedDashboardLog?.length > 0 ? (
                                            paginatedDashboardLog?.map((dashboard, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>{dashboard.dashboardName}</TableCell>
                                                    <TableCell>{dashboard.dashboardGroup}</TableCell>
                                                    <TableCell>{dashboard.accessRight}</TableCell>
                                                    <TableCell>{formatValidUntilDate(dashboard.validUntil, t)}</TableCell>
                                                    <TableCell align="center">
                                                        {/*<IconButton onClick={() => toggleDeleteModal(dashboard.dashboardGroupID,dashboard.dashboardID)}>*/}
                                                        {/*    <Delete color="primary" />*/}
                                                        {/*</IconButton>*/}
                                                        <Button className="padding-right-none">
                                                            <Tooltip title="Remove">
                                                                <Icon className='fa-light fa-trash-can text-primary font-size-base  text-primary' onClick={() => toggleDeleteModal(dashboard.dashboardGroupID, dashboard.dashboardID)} />
                                                            </Tooltip>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))) : (
                                            <TableCell colSpan={5}>{t('common.noRecordsFound')}</TableCell>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>)}
                            {isEdit && (< CommonPagination
                                totalItems={totalItems}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            />)}
                            <Dialog
                                open={isDeleteModal}
                                PaperProps={{
                                    style: {
                                        borderRadius: "12px",
                                        padding: "16px",
                                        width: "400px",
                                        textAlign: "center",
                                    },
                                }}
                            >
                                <DialogTitle>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        style={{ color: "#000959", marginBottom: "8px" }}
                                    >
                                        {t('user_mgmt.deleteDashboard')}
                                    </Typography>
                                </DialogTitle>

                                <DialogContent>
                                    <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                                        {t('common.deleteMessage')}
                                    </Typography>
                                </DialogContent>

                                <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Cancel />}
                                        onClick={() => toggleDeleteModal('', '')}
                                        style={{
                                            color: "#000959",
                                            borderColor: "#000959",
                                            textTransform: "none",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        {t('common.Cancel')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleDelete(dashboardGroupID, dashboardID)}
                                        startIcon={<Delete />}
                                        style={{
                                            backgroundColor: "#000959",
                                            color: "#FFFFFF",
                                            textTransform: "none",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        {t('common.Delete')}
                                    </Button>
                                </DialogActions>
                            </Dialog>


                            <Dialog
                                open={isDisableModal}
                                PaperProps={{
                                    style: {
                                        borderRadius: "12px",
                                        padding: "16px",
                                        width: "400px",
                                        textAlign: "center",
                                    },
                                }}
                            >
                                <DialogTitle>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        style={{ color: "#000959", marginBottom: "8px" }}
                                    >
                                        {t('user_mgmt.disableUser')}
                                    </Typography>
                                </DialogTitle>

                                <DialogContent>
                                    <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                                        {t('common.deleteMessage')}
                                    </Typography>
                                </DialogContent>

                                <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Cancel />}
                                        onClick={() => toggleDisableModel()}
                                        style={{
                                            color: "#000959",
                                            borderColor: "#000959",
                                            textTransform: "none",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        {t('common.Cancel')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleDisable()}
                                        startIcon={<Delete />}
                                        style={{
                                            backgroundColor: "#000959",
                                            color: "#FFFFFF",
                                            textTransform: "none",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        {t('common.Delete')}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </Box>
                    )}
                </Box>

                <Box mt={3} mb={4} display="flex" gap={2} className="btn-center">

                    <Button variant="outlined" color="primary" onClick={InsertUser}>
                        {t('common.Save')}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {t('common.Cancel')}
                    </Button>
                    {isEdit && (<Button variant="outlined" color="error" onClick={toggleDisableModel}>
                        {t('common.disable')}
                    </Button>)}
                </Box>




            </main>
            <Footer />{ToastComponent}
        </>
        //  )}
        //</>
    );
};

export default withTranslation()(ColliersUserManage);
