// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './i18n'; // Import the i18n configuration
import { Suspense } from 'react';
import Loader from './components/Loader';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Suspense fallback={<Loader/>}>
      <App />
    </Suspense>
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();