import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../components/Loader";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import {
    Box, Typography, TextField, TableContainer, Paper,
    MenuItem, Button, Table, TableHead, TableRow,
    TableCell, TableBody, InputAdornment, Checkbox,
    FormControlLabel, Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Icon,
    CircularProgress,
    Link,
    Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Cancel } from "@mui/icons-material";
import { useFillList } from "../../components/fillList";
import { dashboardGroupActionID, dashboardRoleActionID, GetDashboardUrl, GetUserShareAccessUrl, InsertDashboardUrl, SearchUserAndADGroupUrl, SendMailNotificationUrl, UpdateDashboardUrl } from "../../utils/constants";
import { axiosGet, axiosPost } from "../../utils/Axios";
import CommonPagination from "../../components/CommonPagination";
import { useToast } from "../../components/toast";
import moment from 'moment';
import _ from 'lodash';

const DashboardAdd = () => {
    const getNextYearDate = () => {
        const today = new Date();
        const nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
        return nextYear.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };

    //toast
    const { ToastComponent, showToast } = useToast();
    //navigate
    const navigate = useNavigate();
    const location = useLocation();
    const [dashboardID, setDashboardID] = useState(location.state?.dashboardID || null)
    //pop
    const [isRemoveUserModal, setRemoveUserModal] = useState(false);
    const [removeUserIndex, setRemoveUserIndex] = useState('');
    //conditional
    const [isLoading, setIsLoading] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [isInvite, setIsInvite] = useState(false);
    const [canShareClient, setCanShareClient] = useState(false);
    const [canShareColliers, setCanShareColliers] = useState(false);
    //state
    const [titleLocal, setTitleLocal] = useState("");
    const [titleEnglish, setTitleEnglish] = useState("");
    const [clientNameLocal, setClientNameLocal] = useState("");
    const [clientNameEnglish, setClientNameEnglish] = useState("");
    const [dashboardGroupID, setDashboardGroupID] = useState("");
    const [workspaceName, setWorkspaceName] = useState("");
    const [workspaceID, setWorkspaceID] = useState("");
    const [reportName, setReportName] = useState("");
    const [reportID, setReportID] = useState("");
    const [field1, setField1] = useState("");
    const [field2, setField2] = useState("");
    const [imageFileName, setImageFileName] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [isRLS, setIsRLS] = useState(false);
    const [isAlteredImage, setIsAlteredImage] = useState(false);
    const [isUDTUserDataChanged, setIsUDTUserDataChanged] = useState(false);
    //validator
    const [TitleLocalErr, setTitleLocalErr] = useState("");
    const [TitleEnglishErr, setTitleEnglishErr] = useState("");
    const [ClientNameLocalErr, setClientNameLocalErr] = useState("");
    const [ClientNameEnglishErr, setClientNameEnglishErr] = useState("");
    const [DashboardGroupIDErr, setDashboardGroupIDErr] = useState("");
    const [WorkspaceIDErr, setWorkspaceIDErr] = useState("");
    const [ReportIDErr, setReportIDErr] = useState("");
    const [GroupnUserListErr, setGroupnUserListErr] = useState("");

    //invite
    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteValidUntil, setInviteValidUntil] = useState(getNextYearDate());
    const [inviteRls, setInviteRls] = useState('');
    //fillList
    const [DashboardGroupFillList, setDashboardGroupFillList] = useState([]);
    const [DashboardRoleFillList, setDashboardRoleFillList] = useState([]);
    const groupFillList = useFillList(dashboardGroupActionID, "", ""); // Action ID 3
    const roleFillList = useFillList(dashboardRoleActionID, "", ""); // Action ID 4
    //group and user
    const [inputValue, setInputValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [DashboardUserData, setDashboardUserData] = useState([]);
    const [GroupnUserList, setGroupnUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    //error
    const [errors, setErrors] = useState(null)
    //translations
    const { t } = useTranslation();
    //common
    const TAction = t("common.Action");
    const TnoRecordFound = t("common.noRecordFound");
    const TnoUserFound = t("common.noUserFound");
    const Tcancel = t("common.Cancel");
    const Tnew = t("common.New");
    const Tadd = t("common.Add");
    const Tupdate = t("common.Update");
    const Tsave = t("common.Save");
    const Tdelete = t("common.Delete");
    const TDashboard = t("common.Dashboard");
    const TInvite = t("common.Invite");
    const TdropDownDefaultSelect = t("common.dropDownDefaultSelect");
    //Primary Fields
    const TdashboardDetails = t("dashboardAddUpdate.dashboardDetails");
    const Ttitle = t("dashboardAddUpdate.dashboardFields.title");
    const Tlocal = t("dashboardAddUpdate.dashboardFields.titleLocal");
    const TtitleEnglish = t("dashboardAddUpdate.dashboardFields.titleEnglish");
    const TclientName = t("dashboardAddUpdate.dashboardFields.clientName");
    const TlocalClientName = t("dashboardAddUpdate.dashboardFields.localClientName");
    const TclientNameEnglish = t("dashboardAddUpdate.dashboardFields.clientNameEnglish");
    const TdashboardGroupID = t("dashboardAddUpdate.dashboardFields.dashboardGroupID");
    const TworkspaceName = t("dashboardAddUpdate.dashboardFields.workspaceName");
    const TworkspaceID = t("dashboardAddUpdate.dashboardFields.workspaceID");
    const TreportName = t("dashboardAddUpdate.dashboardFields.reportName");
    const TreportID = t("dashboardAddUpdate.dashboardFields.reportID");
    const TRLS = t("dashboardAddUpdate.dashboardFields.RLS");
    const TattachImage = t("dashboardAddUpdate.dashboardFields.attachImage");
    //placeholder
    const TtitlePH = t("dashboardAddUpdate.dashboardPlaceHolder.titlePH");
    const TtitleLocalPH = t("dashboardAddUpdate.dashboardPlaceHolder.titleLocalPH");
    const TtitleEnglishPH = t("dashboardAddUpdate.dashboardPlaceHolder.titleEnglishPH");
    const TclientNamePH = t("dashboardAddUpdate.dashboardPlaceHolder.clientNamePH");
    const TlocalClientNamePH = t("dashboardAddUpdate.dashboardPlaceHolder.localClientNamePH");
    const TclientNameEnglishPH = t("dashboardAddUpdate.dashboardPlaceHolder.clientNameEnglishPH");
    const TworkspaceNamePH = t("dashboardAddUpdate.dashboardPlaceHolder.workspaceNamePH");
    const TworkspaceIDPH = t("dashboardAddUpdate.dashboardPlaceHolder.workspaceIDPH");
    const TreportNamePH = t("dashboardAddUpdate.dashboardPlaceHolder.reportNamePH");
    const TreportIDPH = t("dashboardAddUpdate.dashboardPlaceHolder.reportIDPH");
    //dashboard Validator
    const TtitleErr = t("dashboardAddUpdate.dashboardValidator.titleErr");
    const TtitleLocalErr = t("dashboardAddUpdate.dashboardValidator.titleLocalErr");
    const TtitleEnglishErr = t("dashboardAddUpdate.dashboardValidator.titleEnglishErr");
    const TclientNameErr = t("dashboardAddUpdate.dashboardValidator.clientNameErr");
    const TclientNameLocalErr = t("dashboardAddUpdate.dashboardValidator.clientNameLocalErr");
    const TclientNameEnglishErr = t("dashboardAddUpdate.dashboardValidator.clientNameEnglishErr");
    const TdashboardGroupErr = t("dashboardAddUpdate.dashboardValidator.dashboardGroupErr");
    const TworkspaceIDErr = t("dashboardAddUpdate.dashboardValidator.workspaceIDErr");
    const TreportIDErr = t("dashboardAddUpdate.dashboardValidator.reportIDErr");
    //dashboardUdt 
    const TgroupNusers = t("dashboardAddUpdate.dashboardUdt.groupNusers");
    const TinviteUsers = t("dashboardAddUpdate.dashboardUdt.inviteUsers");
    const TemailUdt = t("dashboardAddUpdate.dashboardUdt.emailUdt");
    const TroleUdt = t("dashboardAddUpdate.dashboardUdt.roleUdt");
    const TguestEmailUdt = t("dashboardAddUpdate.dashboardUdt.guestEmailUdt");
    const TguestEmailNoteUdt = t("dashboardAddUpdate.dashboardUdt.guestEmailNoteUdt");
    const TAddAsUdt = t("dashboardAddUpdate.dashboardUdt.AddAsUdt");
    const TvalidUntilUdt = t("dashboardList.dashboardTable.ValidUntil");


    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (debouncedValue) {
            SearchUserAndADGroup(debouncedValue);
        } else {
            if (!userInfo) {
                window.location.href = "/Login/KeyStone_Login";
                return;
            } else {
                if (location.state?.dashboardID != null) {
                    getDashboardDetails(location.state?.dashboardID)
                }
                GetUserShareAccess();
                if (groupFillList.data) setDashboardGroupFillList(groupFillList.data);
                if (roleFillList.data) setDashboardRoleFillList(roleFillList.data);

                // Retrieve user info once and parse it
                if (userInfo) {
                    const parsedUserInfo = JSON.parse(userInfo);
                    const ownerUser = {
                        displayName: parsedUserInfo.userName,
                        emailAddress: parsedUserInfo.emailAddress,
                        userID: parsedUserInfo.UserID || '', // Ensure UserID is defined
                        validUntil: getNextYearDate(),
                        rlsName: '',
                        azureGroupID: '',
                        azureGroupName: '',
                        dashboardRoleID: 3,
                        adGroupID: "",
                        isDeleted: false
                    };

                    // Check if the owner user already exists in the list
                    if (!GroupnUserList.some(user => user.userID === ownerUser.userID)) {
                        setGroupnUserList(prevList => [...prevList, ownerUser]);
                    }
                }

                // else {
                //     GetDashboardUsers();
                // }
            }
        }
    }, [groupFillList.data, roleFillList.data, debouncedValue]);
    const getDashboardDetails = async (dashboardID) => {
        setIsLoading(true);
        const endpoint = `?dashboardID=${dashboardID}`;
        try {
            const data = await axiosGet(`${GetDashboardUrl}${endpoint}`);
            if (data != null) {
                console.log('response', data);
                setTitleEnglish(data.title);
                setTitleLocal(data.localTitle);
                setClientNameEnglish(data.clientName);
                setClientNameLocal(data.localClientName);
                setDashboardGroupID(data.dashboardGroupID);
                setWorkspaceName(data.workspaceName);
                setWorkspaceID(data.workspaceID);
                setReportName(data.reportName);
                setReportID(data.reportID);
                setField1(data.field1);
                setField2(data.field2);
                setImageFileName(data.imageFileName);
                setImagePath(data.imagePath);
                setIsRLS(data.isRLS);
                console.log(data.isRLS, "Rls")

                const existingUsers = (data.dashboardUserGroup || []).map(user => ({
                    ...user,
                    isDeleted: user.isDeleted !== undefined ? user.isDeleted : false,
                    rlsName: user.dashboardRLS != '' ? user.dashboardRLS : '',
                    isMailView: true,
                }));

                //valid until coding added by Pradnya
                if (existingUsers != null && existingUsers.length > 0) {
                    existingUsers.forEach(x => {
                        if (x.validUntil != null && x.validUntil != undefined) {
                            x.validUntil = moment(x.validUntil, 'DD-MM-YYYY').format('YYYY-MM-DD');
                        }
                    })
                }
                //end of valid until coding added by Pradnya

                setGroupnUserList(existingUsers);

                if (!dashboardID) {
                    const userInfo = localStorage.getItem("userInfo");
                    if (userInfo) {
                        const parsedUserInfo = JSON.parse(userInfo);
                        const ownerUser = {
                            displayName: parsedUserInfo.userName,
                            emailAddress: parsedUserInfo.emailAddress,
                            userID: parsedUserInfo.UserID || '', // Ensure UserID is defined
                            validUntil: getNextYearDate(),
                            rlsName: '',
                            azureGroupID: '',
                            azureGroupName: '',
                            dashboardRoleID: 3,
                            adGroupID: "",
                            isDeleted: false
                        };

                        // Check if the owner user already exists in the list by emailAddress
                        const userExists = existingUsers.some(user => user.emailAddress === ownerUser.emailAddress);

                        // If not exists, add to the list
                        if (!userExists) {
                            setGroupnUserList(prevList => [...prevList, ownerUser]);
                        }
                    }
                }

                setIsLoading(false);
            } else {
                console.error("Failed to fetch group data: No data found.");
                setIsLoading(false);

            }
        } catch (error) {
            console.error("Error fetching group data:", error);
            setIsLoading(false);

        } finally {
            setIsLoading(false);
        }
    };
    const handleChangeSearch = (e) => {
        setInputValue(e.target.value);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(
            setTimeout(() => {
                if (e.target.value.length >= 3) {
                    setDebouncedValue(e.target.value)
                }
                else if (e.target.value <= 3) {
                    // GetDashboardUsers()
                    setDashboardUserData([])
                }
            }, 100)
        );
    };
    const GetUserShareAccess = async () => {
        try {
            const response = await axiosGet(GetUserShareAccessUrl);
            console.log(response);
            if (response) {
                setCanShareClient(response.canShareClient);
                setCanShareColliers(response.canShareColliers);
            } else {
                console.error("Failed to fetch GetDashboardUsers: No data found.");
            }
        } catch (error) {
            console.error("Error fetching GetDashboardUsers:", error);
        }
    };
    const handleValidUntil = async (e, i, data) => {
        const updatedList = [...DashboardUserData];
        updatedList[i] = {
            ...updatedList[i],
            validUntil: e.target.value
        };
        const today = moment().format('YYYY-MM-DD');
        const selectedDate = moment(e.target.value, 'YYYY-MM-DD');
        if (selectedDate.isBefore(today, 'day')) {
            //setErrors(prev => ({
            //    ...prev,
            //    [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
            //}));
            showToast('error', "You can not select past date");
        } else {
            //setErrors(prev => ({
            //    ...prev,
            //    [`${data.userID}-validUntil`]: false
            //}));
            setDashboardUserData(updatedList);
        }
    }

    const handleValidDateChangeEvent = async (e, index, data) => {
        setIsUDTUserDataChanged(true)
        const updatedList = [...GroupnUserList];
        updatedList[index] = {
            ...updatedList[index],
            validUntil: e.target.value
        };
        const today = moment().format('YYYY-MM-DD');
        const selectedDate = moment(e.target.value, 'YYYY-MM-DD');

        if (selectedDate.isBefore(today, 'day')) {
            setErrors(prev => ({
                ...prev,
                [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
            }));
            showToast('error', "You can not select past date");
        } else {
            setErrors(prev => ({
                ...prev,
                [`${data.userID}-validUntil`]: false
            }));
            setGroupnUserList(updatedList);
        }

    }

    const SearchUserAndADGroup = async (searchUserKeyword) => {
        setIsUserLoading(true)
        try {
            setTimeout(() => {
                setDashboardUserData([]);
            }, 200);
            const response = await axiosGet(`${SearchUserAndADGroupUrl}?keyword=${searchUserKeyword}`);
            console.log(response);
            if (response) {
                const updatedData = response.map(row => ({
                    ...row,
                    isAdded: 0
                }));
                updatedData.forEach(x => x.validUntil = getNextYearDate());
                setDashboardUserData(updatedData);
                setIsUserLoading(false)
            } else {
                console.error("Failed to search Users: No data found.");
                setIsUserLoading(false)
            }
        } catch (error) {
            setIsUserLoading(false)
            console.error("Error Search Users:", error);
        }
    };

    //add to group user
    const AddtoGroupUsers = (user) => {
        console.log('userExist', user)
        if (user.validUntil == null || user.validUntil == '') {
            showToast('error', 'Please select valid until date');
        } else {
            const dashboardRoleID = user.email && user.email.includes('@colliers') ? 4 : 2;
            const newUser = {
                displayName: user.displayName ? user.displayName : user.firstName,
                emailAddress: user.userPrincipalName ? user.userPrincipalName : user.email,
                userID: user.userID || '', // Ensure UserID is defined
                validUntil: user.validUntil,
                rlsName: '',
                azureGroupID: '',
                azureGroupName: '',
                dashboardRoleID: dashboardRoleID, // Set dashboardRoleID based on userPrincipalName
                adGroupID: "",
                isDeleted: false
            };
            const existingUser = GroupnUserList.find(existingUser => existingUser.emailAddress.toLowerCase() === newUser.emailAddress.toLowerCase());
            if (existingUser) {
                if (existingUser.isDeleted) {
                    const updatedUserList = GroupnUserList.map(existingUserItem =>
                        existingUserItem.emailAddress.toLowerCase() === newUser.emailAddress.toLowerCase()
                            ? { ...existingUserItem, isDeleted: false }
                            : existingUserItem
                    );
                    setGroupnUserList(updatedUserList);
                    if (dashboardID) {
                        setIsUDTUserDataChanged(true);
                    }
                    showToast("success", "User Added Successfully");
                } else {
                    showToast("success", "User Already Exists");
                }
            } else {
                setGroupnUserList(prevList => [...prevList, newUser]);
                showToast("success", "User Added Successfully");
                if (dashboardID) {
                    setIsUDTUserDataChanged(true);
                }
            }
            setGroupnUserListErr('');
        }

    };

    const inviteSubmit = () => {
        console.log(inviteValidUntil)
        if (inviteEmail == '') {
            showToast("error", "Kindly Enter Email Address");
        } else if (inviteValidUntil == '') {
            showToast("error", "Kindly Enter Valid Until Date");
        } else if (isRLS==1 && inviteRls == '') {
                showToast("error", "Kindly Enter RLS Name");
        } else {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const emails = inviteEmail.split(',').map(email => email.trim()).filter(email => email);
            const duplicateEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
            if (duplicateEmails.length > 0) {
                showToast("error", `Duplicate emails found: ${[...new Set(duplicateEmails)].join(', ')}`);
            } else {
                const invalidEmails = emails.filter(email => !emailRegex.test(email));

                if (invalidEmails.length > 0) {
                    showToast("error", `The following emails are invalid: ${invalidEmails}`);
                } else {
                    const newUsers = emails.map(email => {
                        const dashboardRoleID = email.includes('@colliers') ? 4 : 2;

                        return {
                            userID: "",
                            azureGroupID: "",
                            azureGroupName: "",
                            emailAddress: email,
                            validUntil: inviteValidUntil,
                            dashboardRoleID: dashboardRoleID, // Set dashboardRoleID based on email
                            rlsName: inviteRls,
                            adGroupID: "",
                            isDeleted: false
                        };
                    });
                    const existingEmails = GroupnUserList.map(user => user.emailAddress);
                    const duplicateNewUsers = newUsers.filter(user => existingEmails.includes(user.emailAddress));

                    if (duplicateNewUsers.length > 0) {
                        duplicateNewUsers.forEach(user => {
                            const existingUser = GroupnUserList.find(existing => existing.emailAddress === user.emailAddress);

                            if (existingUser) {
                                if (existingUser.isDeleted) {
                                    existingUser.isDeleted = false;
                                    showToast('success', `User Added Successfully.`);
                                    if (dashboardID) {
                                        setIsUDTUserDataChanged(true);
                                    }
                                } else {
                                    showToast('info', `User ${user.emailAddress} already added.`);
                                }
                            } else {
                                showToast("success", "User Already Exists");
                            }
                        });
                    } else {
                        setGroupnUserList(prevList => [...prevList, ...newUsers]);
                        showToast("success", "User Added Successfully");
                        if (dashboardID) {
                            setIsUDTUserDataChanged(true);
                        }
                    }
                    setGroupnUserListErr('');
                    setInviteEmail('');
                    setInviteRls('');
                    setIsInvite(false);
                }
            }
        }
    };

    const handleRemovefromGroupList = (email) => {
        const updatedList = GroupnUserList.map((item, i) =>
            item.emailAddress === email ? { ...item, isDeleted: true } : item
        );
        setGroupnUserList(updatedList);
        setRemoveUserModal(false);
        setRemoveUserIndex('');

        showToast("success", "User Removed Successfully")
        if (dashboardID) {
            setIsUDTUserDataChanged(true);
        }
    };

    const toggleRemoveUser = (index) => {
        if (isRemoveUserModal == true) {
            setRemoveUserModal(false);
            setRemoveUserIndex('')
        } else {
            setRemoveUserIndex(index)
            setRemoveUserModal(true);
        }
    };
    const sendMail = async (dashboardID, userID) => {
        const endpoint = `?dashboardID=${dashboardID}&toUserID=${userID}`;
        setIsLoading(true)
        try {
            const response = await axiosPost(`${SendMailNotificationUrl}${endpoint}`);
            if (response) {
                showToast('success', 'Mail Sent Successfully!');
                setIsLoading(false)
            } else {
                showToast('error', 'Failed to Send Mail.');
                setIsLoading(false)
                console.error("Failed to Send Mail.");
            }
        } catch (error) {
            showToast('error', 'Error Sending Mail.');
            setIsLoading(false)
            console.error("Error Sending Mail:", error);
        }
    }
    const inputValidate = () => {
        let isValid = true;

        // Reset all previous error states first
        setTitleLocalErr('');
        setTitleEnglishErr('');
        setClientNameLocalErr('');
        setClientNameEnglishErr('');
        setDashboardGroupIDErr('');
        setWorkspaceIDErr('');
        setReportIDErr('');


        if (currentLang.toLowerCase() === "en-us") {
            if (titleEnglish == '') {
                setTitleEnglishErr(TtitleErr)
                isValid = false;
            }
        } else {
            if (titleEnglish == '') {
                setTitleEnglishErr(TtitleEnglishErr)
                isValid = false;
            }
            if (titleLocal == '') {
                setTitleLocalErr(TtitleLocalErr)
                isValid = false;
            }
        }
        if (currentLang.toLowerCase() === "en-us") {
            if (clientNameEnglish == '') {
                setClientNameEnglishErr(TclientNameErr)
                isValid = false;
            }
        } else {
            if (titleEnglish == '') {
                setClientNameEnglishErr(TclientNameEnglishErr)
                isValid = false;
            }
            if (clientNameLocal == '') {
                setClientNameLocalErr(TclientNameLocalErr)
                isValid = false;
            }
        }
        if (dashboardGroupID == '' || dashboardGroupID == null || dashboardGroupID == TdropDownDefaultSelect) {
            setDashboardGroupIDErr(TdashboardGroupErr)
            isValid = false;
        }

        if (workspaceID == '') {
            setWorkspaceIDErr(TworkspaceIDErr)
            isValid = false;
        }

        if (reportID == '') {
            setReportIDErr(TreportIDErr)
            isValid = false;
        }

        // Enhanced RLS Validation
        if (isRLS) {
            // Check if GroupnUserList is empty or undefined
            if (!GroupnUserList || GroupnUserList.length === 0) {
                setGroupnUserListErr('No users selected for Row-Level Security');
                showToast('error', 'No users selected for Row-Level Security')
                isValid = false;
            } else {
                const invalidRLSRows = GroupnUserList.filter(row =>
                    !row.rlsName ||
                    row.rlsName.trim() === '' ||
                    row.rlsName === 'select'
                );
                const invalidValidUntilRows = GroupnUserList.filter(row =>
                    !row.validUntil ||
                    row.validUntil.trim() === '' ||
                    row.validUntil === null
                );

                if (invalidRLSRows.length > 0) {
                    setGroupnUserListErr('RLS entries not detected');
                    showToast('eeror', 'RLS entries not detected')
                    console.log('Invalid RLS Rows:', invalidRLSRows);
                    isValid = false;
                }
                if (invalidValidUntilRows.length > 0) {
                    setGroupnUserListErr('Valid Until entries not detected');
                    showToast('eeror', 'Valid Until entries not detected')
                    console.log('Invalid Valid Until Rows:', invalidValidUntilRows);
                    isValid = false;
                }
            }
        }
        return isValid;
    }
    const SubmitDashboard = async () => {
        console.log('submit start')
        const isValid = inputValidate();
        if (isValid) {
            console.log('validated')
            setIsLoading(true);
            const formData = new FormData();
            formData.append("titleLocal", titleLocal);
            formData.append("titleEnglish", titleEnglish);
            formData.append("clientNameLocal", clientNameLocal);
            formData.append("clientNameEnglish", clientNameEnglish);
            formData.append("dashboardGroupID", dashboardGroupID);
            formData.append("workspaceName", workspaceName ? workspaceName : '');
            formData.append("workspaceID", workspaceID);
            formData.append("reportName", reportName ? reportName : '');
            formData.append("reportID", reportID);
            formData.append("field1", field1 ? field1 : '');
            formData.append("field2", field2 ? field2 : '');
            formData.append("isRLS", isRLS);
            formData.append("imageFileName", imageFileName ? imageFileName : '');
            formData.append("imagePath", imagePath ? imagePath : '');
            formData.append("isUDTUserDataChanged", false); // Set this to true or false as needed
            GroupnUserList.forEach((data, index) => {
                formData.append(`dashboardGroupAndUsers[${index}].userID`, (data.userID != '' && data.userID != null) ? data.userID : 0);
                const azureGroupID = (data.azureGroupID != null && data.azureGroupID !== '' && data.azureGroupID !== null) ? data.azureGroupID : 0;
                formData.append(`dashboardGroupAndUsers[${index}].azureGroupID`, azureGroupID);
                formData.append(`dashboardGroupAndUsers[${index}].azureGroupName`, (data.azureGroup != null || data.azureGroup != "" ? data.azureGroup : null));
                formData.append(`dashboardGroupAndUsers[${index}].emailAddress`, data.emailAddress);
                formData.append(`dashboardGroupAndUsers[${index}].validUntil`, (data.validUntil != null && data.validUntil != "" && data.validUntil != undefined) ? moment(data.validUntil).format('DD-MM-YYYY') : null);
                formData.append(`dashboardGroupAndUsers[${index}].dashboardRoleID`, data.dashboardRoleID);
                formData.append(`dashboardGroupAndUsers[${index}].rlsName`, data.rlsName ? data.rlsName : '');

                const adGroupID = (data.adGroupID != null && data.adGroupID !== '' && data.adGroupID !== null) ? data.adGroupID : 0;
                formData.append(`dashboardGroupAndUsers[${index}].adGroupID`, adGroupID);
                formData.append(`dashboardGroupAndUsers[${index}].isDeleted`, data.isDeleted);
            });
            try {
                const response = await axiosPost(InsertDashboardUrl, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                if (response) {
                    // setIsLoading(false);
                    showToast("success", 'Dashboard Added Successfully');
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 2000);
                } else {
                    showToast("error", "Failed to fetch dashboards: No data found.");
                    setIsLoading(false);
                }
            } catch (error) {
                showToast("error", `Error fetching dashboards: ${error}`);
                console.error("Error fetching dashboards:", error);
                setIsLoading(false);
            }
        }
    };
    const updateDashboard = async () => {
        const isValid = inputValidate();
        if (isValid) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("titleLocal", titleLocal);
            formData.append("titleEnglish", titleEnglish);
            formData.append("clientNameLocal", clientNameLocal);
            formData.append("clientNameEnglish", clientNameEnglish);
            formData.append("dashboardGroupID", dashboardGroupID);
            formData.append("workspaceName", workspaceName ? workspaceName : '');
            formData.append("workspaceID", workspaceID);
            formData.append("reportName", reportName ? reportName : '');
            formData.append("reportID", reportID);
            formData.append("field1", field1 ? field1 : '');
            formData.append("field2", field2 ? field2 : '');
            formData.append("isRLS", isRLS);
            formData.append("imageFileName", imageFileName ? imageFileName : '');
            formData.append("imagePath", imagePath ? imagePath : '');
            formData.append("isUDTUserDataChanged", isUDTUserDataChanged);
            GroupnUserList.forEach((data, index) => {
                formData.append(`dashboardGroupAndUsers[${index}].userID`, (data.userID != '' && data.userID != null) ? data.userID : 0);
                const azureGroupID = (data.azureGroupID != null && data.azureGroupID !== '' && data.azureGroupID !== null) ? data.azureGroupID : 0;
                formData.append(`dashboardGroupAndUsers[${index}].azureGroupID`, azureGroupID);
                formData.append(`dashboardGroupAndUsers[${index}].azureGroupName`, (data.azureGroup != null || data.azureGroup != "" ? data.azureGroup : null));
                formData.append(`dashboardGroupAndUsers[${index}].emailAddress`, data.emailAddress);
                formData.append(`dashboardGroupAndUsers[${index}].validUntil`, (data.validUntil != null && data.validUntil != "" && data.validUntil != undefined) ? moment(data.validUntil).format('DD-MM-YYYY') : null);
                formData.append(`dashboardGroupAndUsers[${index}].dashboardRoleID`, data.dashboardRoleID);
                formData.append(`dashboardGroupAndUsers[${index}].rlsName`, data.rlsName ? data.rlsName : '');

                const adGroupID = (data.adGroupID != null && data.adGroupID !== '' && data.adGroupID !== null) ? data.adGroupID : 0;
                formData.append(`dashboardGroupAndUsers[${index}].adGroupID`, adGroupID);
                formData.append(`dashboardGroupAndUsers[${index}].isDeleted`, data.isDeleted);
            });
            const endpoint = `?updateDashboardID=${dashboardID}`
            try {
                const response = await axiosPost(`${UpdateDashboardUrl}${endpoint}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                if (response) {
                    //  setIsLoading(false);
                    showToast("success", 'Dashboard Updated Successfully');
                    setTimeout(() => {
                        navigate('/dashboard')
                    }, 2000);
                } else {
                    showToast("error", "Failed to fetch dashboards: No data found.");
                    setIsLoading(false);
                }
            } catch (error) {
                showToast("error", `Error fetching dashboards: ${error}`);
                console.error("Error fetching dashboards:", error);
                setIsLoading(false);
            }
        }
    };
    //fileUpload
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log(file)
        if (file) {
            const isImage = file.type.startsWith("image/");
            const isSizeValid = file.size <= 10 * 1024 * 1024; // 10 MB limit

            if (!isImage) {
                showToast("error", "Please select a valid image file.");
            } else if (!isSizeValid) {
                showToast("error", "Please select an image file smaller than 10MB.");
            } else {
                setImageFileName(file.name); // Save file name for display purposes
                setImagePath(file);         // Save the file itself for upload
            }

            // Clear the file input and reset values if the file is invalid
            if (!isImage || !isSizeValid) {
                e.target.value = "";
                setImageFileName(null);
                setImagePath(null);
            }
        }
    };
    // const userLangInfo = JSON.parse(localStorage.getItem("userInfo"))?.userLangInfo || [];
    const currentLang = JSON.parse(localStorage.getItem("userInfo")).lang;
    const handleDate = useCallback(
        _.debounce((event) => {
            const today = moment().format('YYYY-MM-DD');
            const selectedDate = moment(event, 'YYYY-MM-DD');

            if (selectedDate.isBefore(today, 'day')) {
                //setErrors(prev => ({
                //    ...prev,
                //    [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
                //}));
                showToast('error', "You can not select past date");
            } else {
                //setErrors(prev => ({
                //    ...prev,
                //    [`${data.userID}-validUntil`]: false
                //}));
                setInviteValidUntil(event);
            }
        }, 300), []);



    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#EDF2FF",
                    width: "100%",
                    marginTop: 50,
                    padding: "2rem",
                }}
            >
                <Box></Box>
                <Typography variant="h5" className="padding-top-m text-center page-name">
                    {dashboardID ? Tupdate : Tnew} {TDashboard}
                </Typography>
                <Box></Box>
            </Box>

            <Box id="main" style={{ marginTop: 10 }}>
                <Box sx={{ m: '2rem', mt: '15px', mb: '0px' }}>
                    <Box className="section">
                        <Box className="margin-bottom-m"
                            sx={{
                                border: "1px solid #dbe5ff",
                                borderRadius: 1,
                                padding: 2,
                                flex: 1,
                            }}
                        >
                            <Typography variant="h6" className="tit-a">
                                {TdashboardDetails}
                            </Typography>

                            <Box sx={{ display: "flex", gap: 3, mb: 3 }}>
                                {currentLang.toLowerCase() === "en-us" ? (
                                    <TextField
                                        className="space-lab"
                                        onChange={(e) => {
                                            setTitleEnglish(e.target.value)
                                            setTitleEnglishErr('')
                                        }}
                                        error={TitleEnglishErr != ''}
                                        helperText={TitleEnglishErr}
                                        value={titleEnglish}
                                        fullWidth
                                        label={Ttitle}
                                        placeholder={TtitlePH}
                                        required
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 200,  // Set the maxLength to 100 characters
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                ) : <>
                                    <TextField
                                        className="space-lab"
                                        onChange={(e) => {
                                            setTitleEnglish(e.target.value)
                                            setTitleEnglishErr('')
                                        }}
                                        error={TitleEnglishErr != ''}
                                        helperText={TitleEnglishErr}
                                        value={titleEnglish}
                                        fullWidth
                                        label={TtitleEnglish}
                                        placeholder={TtitleEnglishPH}
                                        required
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 200,  // Set the maxLength to 100 characters
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        className="space-lab"
                                        label={Tlocal}
                                        onChange={(e) => {
                                            setTitleLocal(e.target.value);
                                            setTitleLocalErr('');
                                        }}
                                        error={TitleLocalErr !== ''}
                                        value={titleLocal}
                                        fullWidth
                                        helperText={TitleLocalErr}
                                        placeholder={TtitleLocalPH}
                                        required
                                        variant="outlined"
                                        inputProps={{ maxLength: 200 }}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </>}
                                <TextField
                                    className="space-lab"
                                    label={TdashboardGroupID}
                                    onChange={(e) => {
                                        setDashboardGroupID(e.target.value)
                                        setDashboardGroupIDErr('')
                                    }}
                                    value={dashboardGroupID}
                                    helperText={DashboardGroupIDErr}
                                    error={DashboardGroupIDErr != ''}
                                    select
                                    fullWidth
                                    required
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true, }}
                                >
                                    <MenuItem value="" sx={{ color: "black" }}>
                                        {TdropDownDefaultSelect}
                                    </MenuItem>
                                    {DashboardGroupFillList.map((data) => (
                                        <MenuItem key={data.code} value={data.code}>
                                            {data.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            <Box sx={{ gridTemplateColumns: "4f 3f", display: "flex", gap: 3, mb: 3, }}  >
                                {currentLang.toLowerCase() === "en-us" ? (
                                    <TextField
                                        sx={{ paddingTop: "10px", width: "49%" }}
                                        label={TclientName}
                                        onChange={(e) => {
                                            setClientNameEnglish(e.target.value)
                                            setClientNameEnglishErr('')
                                        }}
                                        error={ClientNameEnglishErr != ''}
                                        helperText={ClientNameEnglishErr}
                                        value={clientNameEnglish}
                                        fullWidth
                                        placeholder={TclientNamePH}
                                        required
                                        variant="outlined"
                                        inputProps={{ maxLength: 100 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                ) : <>
                                    <TextField
                                        sx={{ paddingTop: "10px", width: "49%" }}
                                        label={TclientNameEnglish}
                                        onChange={(e) => {
                                            setClientNameEnglish(e.target.value)
                                            setClientNameEnglishErr('')
                                        }}
                                        error={ClientNameEnglishErr != ''}
                                        helperText={ClientNameEnglishErr}
                                        value={clientNameEnglish}
                                        fullWidth
                                        placeholder={TclientNameEnglishPH}
                                        required
                                        variant="outlined"
                                        inputProps={{ maxLength: 100 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        sx={{ paddingTop: "10px", width: "49%" }}
                                        label={TlocalClientName}
                                        onChange={(e) => {
                                            setClientNameLocal(e.target.value)
                                            setClientNameLocalErr('')
                                        }}
                                        error={ClientNameLocalErr != ''}
                                        helperText={ClientNameLocalErr}
                                        value={clientNameLocal}
                                        fullWidth
                                        placeholder={TlocalClientNamePH}
                                        required
                                        variant="outlined"
                                        inputProps={{ maxLength: 100 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </>}
                            </Box>

                            <Box sx={{ gridTemplateColumns: "4f 3f", display: "flex", gap: 3, mb: 3, }}  >
                                <TextField
                                    sx={{ paddingTop: "10px", width: "50%" }}

                                    label={TworkspaceName}
                                    onChange={(e) => setWorkspaceName(e.target.value)}
                                    value={workspaceName}

                                    placeholder={TworkspaceNamePH}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    sx={{ paddingTop: "10px", width: "50%" }}
                                    label={TworkspaceID}
                                    onChange={(e) => {
                                        setWorkspaceID(e.target.value)
                                        setWorkspaceIDErr('')
                                    }}
                                    required
                                    error={WorkspaceIDErr != ''}
                                    value={workspaceID}
                                    helperText={WorkspaceIDErr}
                                    placeholder={TworkspaceIDPH}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>

                            <Box sx={{ gridTemplateColumns: "4f 3f", display: "flex", gap: 3, mb: 3, }}  >
                                <TextField
                                    sx={{ paddingTop: "10px", width: "50%" }}
                                    label={TreportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                    value={reportName}
                                    placeholder={TreportNamePH}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    sx={{ paddingTop: "10px", width: "50%" }}
                                    label={TreportID}
                                    error={ReportIDErr != ''}
                                    fullWidth
                                    required
                                    onChange={(e) => {
                                        setReportID(e.target.value)
                                        setReportIDErr('')
                                    }}
                                    value={reportID}
                                    placeholder={TreportIDPH}
                                    variant="outlined"
                                    helperText={ReportIDErr}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>

                            <Box sx={{ display: "flex", gap: 3, mb: 3 }}>

                                {/* <TextField
                                    sx={{ paddingTop: "10px" }}
                                    label={Tfield1}
                                    fullWidth
                                    onChange={(e) => setField1(e.target.value)}
                                    value={field1}
                                    placeholder={Tfield1PH}
                                    variant="outlined"
                                />
                                <TextField
                                    sx={{ paddingTop: "10px" }}
                                    label={Tfield2}
                                    onChange={(e) => setField2(e.target.value)}
                                    value={field2}
                                    fullWidth
                                    placeholder={Tfield2PH}
                                    variant="outlined"
                                /> */}
                                {imagePath && dashboardID && !isAlteredImage ? (
                                    <>
                                        <TextField
                                            label="Attach Image"
                                            variant="outlined"
                                            sx={{ width: '32%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    imagePath ? (
                                                        <>
                                                            <Link
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    display: 'inline-block',
                                                                    paddingTop: '15px'
                                                                }}
                                                                href={imagePath}  // Ensure imagePath is a valid URL
                                                                target="_blank"
                                                                title="View Image"
                                                                className="margin-right-base"
                                                            >
                                                                {imageFileName}  {/* Display image filename */}
                                                            </Link>

                                                            <Tooltip title="Remove">
                                                                <DeleteIcon
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        marginLeft: '10px',
                                                                        fontSize: '20px',
                                                                    }}
                                                                    onClick={() => {
                                                                        setImagePath(null);  // Clear image path
                                                                        setImageFileName(null);  // Clear image file name
                                                                        setIsAlteredImage(true);  // Mark as altered image
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </>
                                                    ) : null
                                                ),
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <TextField
                                            sx={{ paddingTop: "10px", width: "49%", }}
                                            label={TattachImage}
                                            type="file"
                                            accept="image/*"
                                            variant="outlined"
                                            helperText={imageFileName && <p>Selected File: {imageFileName}</p>}
                                            InputLabelProps={{ shrink: true }} // Keeps label visible
                                            inputProps={{
                                                onChange: handleFileChange, // File change handler
                                            }}
                                        />
                                    </>
                                )}
                                <FormControlLabel sx={{ width: "32%", margin: '0px', marginTop: '30px' }}
                                    control={<Checkbox checked={isRLS} onChange={(e) => {
                                        if (dashboardID) { setIsUDTUserDataChanged(true); }
                                        setIsRLS(e.target.checked)
                                    }} />}
                                    label={TRLS}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", gap: 3 }}>
                        {/* Groups & Users Section */}
                        {/* Left Table */}
                        <Box
                            sx={{
                                border: "1px solid #dbe5ff",
                                borderRadius: 1,
                                padding: 2,
                                flex: 1,
                            }}
                        >
                            <Typography variant="h6" className="box-name-tit text-align-left">
                                {TgroupNusers}
                            </Typography>
                            <TableContainer className="display-rec-4">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>{TnameUdt}</TableCell> */}
                                            <TableCell className="padding-left-none">{TemailUdt}</TableCell>
                                            <TableCell >{TvalidUntilUdt} <Typography variant="p" sx={{ fontWeight: "bold", color: '#ed1b34' }}>*</Typography></TableCell>
                                            <TableCell sx={{ width: '150px' }}>{TroleUdt} <Typography variant="p" sx={{ fontWeight: "bold", color: '#ed1b34' }}>*</Typography></TableCell>
                                            {isRLS ? <TableCell>{TRLS} <Typography variant="p" sx={{ fontWeight: "bold", color: '#ed1b34' }}>*</Typography></TableCell> : <></>}
                                            <TableCell sx={{ textAlign: 'center' }}>{TAction}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {GroupnUserList.length > 0 ? GroupnUserList.map((data, index) => (
                                            data.isDeleted === true ? null : (
                                                <TableRow key={data.emailAddress} >
                                                    {/* <TableCell>{data.displayName !== '' ? data.displayName : '-'}</TableCell> */}
                                                    <TableCell
                                                        className="padding-left-xs"
                                                        sx={{
                                                            width: "20rem",
                                                            wordWrap: "break-word",
                                                            whiteSpace: "normal",
                                                            wordBreak: "break-word",
                                                        }}
                                                    >
                                                        {data.emailAddress}
                                                    </TableCell>
                                                    <TableCell className="padding-top-none margin-top-none">
                                                        {/* <TextField style={{margin:'0px', padding:'0px'}}
                                                                                        fullWidth
                                                                                        type="date"
                                                                                        value={(data.emailAddress==JSON.parse(localStorage.getItem("userInfo")).emailAddress)?getNextYearDate():moment(data.validUntil, 'DD-MM-YYYY').format('YYYY-MM-DD')}
                                                                                        onChange={(e) => {
                                                                                            const updatedList = [...GroupnUserList];
                                                                                            updatedList[index] = {
                                                                                                ...updatedList[index],
                                                                                                validUntil: e.target.value
                                                                                            };
                                                                                            setGroupnUserList(updatedList);

                                                                                            const today = moment().format('YYYY-MM-DD');
                                                                                            const selectedDate = moment(e.target.value, 'YYYY-MM-DD');

                                                                                            if (selectedDate.isBefore(today, 'day')) {
                                                                                                setErrors(prev => ({
                                                                                                    ...prev,
                                                                                                    [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
                                                                                                }));
                                                                                            } else {
                                                                                                setErrors(prev => ({
                                                                                                    ...prev,
                                                                                                    [`${data.userID}-validUntil`]: false
                                                                                                }));
                                                                                            }
                                                                                        }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                    />  */}
                                                        <TextField style={{ margin: '0px', padding: '0px' }}
                                                            fullWidth
                                                            type="date"
                                                            value={data.validUntil}
                                                            onChange={(e) => handleValidDateChangeEvent(e, index, data)}
                                                            required
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                    <TableCell className="padding-top-none margin-top-none">
                                                        <TextField className="select-rights" style={{ margin: '0px', padding: '0px' }}
                                                            select
                                                            fullWidth
                                                            required
                                                            disabled={(data.dashboardRoleID == 3) ||(data.dashboardRoleID == 2)}
                                                            value={data.dashboardRoleID}
                                                            onChange={(e) => {
                                                                setIsUDTUserDataChanged(true)
                                                                const updatedList = [...GroupnUserList];
                                                                updatedList[index] = {
                                                                    ...updatedList[index],
                                                                    dashboardRoleID: e.target.value 
                                                                };
                                                                setGroupnUserList(updatedList);
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            {DashboardRoleFillList.map((role) => (
                                                                <MenuItem key={role.code} value={role.code}>
                                                                    {role.description}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </TableCell>
                                                    {isRLS && (
                                                        <TableCell className="padding-top-none margin-top-none">
                                                            <TextField
                                                                sx={{ width: '100px' }}
                                                                fullWidth
                                                                value={data.rlsName}
                                                                onChange={(e) => {
                                                                    setIsUDTUserDataChanged(true)
                                                                    const updatedList = [...GroupnUserList];
                                                                    updatedList[index] = {
                                                                        ...updatedList[index],
                                                                        rlsName: e.target.value // Update RLSName
                                                                    };
                                                                    setGroupnUserList(updatedList);
                                                                }}
                                                                required
                                                                variant="outlined"
                                                            />
                                                        </TableCell>
                                                    )}
                                                    <TableCell sx={{ textAlign: 'center' }} className="padding-top-none margin-top-none">
                                                        {(dashboardID ? (Number(data.dashboardRoleID) === 3 && data.isCreatedBy === true)
                                                            : (Number(data.dashboardRoleID) === 3) || data.isDeleted === true ? null : (
                                                                <Tooltip title="Remove"><Icon className='fa-light fa-trash-can padding-top-m font-size-base text-primary' onClick={() => toggleRemoveUser(data.emailAddress)} />
                                                                </ Tooltip>
                                                            ))}
                                                        {(dashboardID ? ((Number(data.dashboardRoleID) === 2 && data.isMailView === true) ? (
                                                            <Tooltip title="Mail">
                                                                <Icon className='fa-light fa-envelope padding-top-m font-size-base text-danger' onClick={() => sendMail(dashboardID, data.userID)} />
                                                            </Tooltip>
                                                        ) : null) : null)}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )) : (
                                            <TableRow>
                                                <TableCell colSpan={6} sx={{ textAlign: 'center' }}>{TnoUserFound}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography defaultValue="Error" variant="p">
                                {GroupnUserListErr}
                            </Typography>

                        </Box>

                        {/* Add Groups & Users */}
                        <Box
                            sx={{
                                border: "1px solid #dbe5ff",
                                borderRadius: 1,
                                padding: 2,
                                paddingTop: '8px',
                                paddingLeft: '8px',
                                flex: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingLeft: 1

                                }}
                            >
                                <Typography className="box-name-tit" style={{ textTransform: 'uppercase' }} variant="h6" >
                                    {isInvite ? TinviteUsers : `${Tadd} ${TgroupNusers}`}
                                </Typography>
                                {isInvite ? <></> : <>
                                    <Button className="padding-right-s padding-left-s"
                                        variant="outlined"
                                        disabled={!canShareClient}
                                        startIcon={<AddIcon />}
                                        sx={{ borderColor: "#25408f", color: "#25408f" }}
                                        onClick={() => setIsInvite(true)}
                                    >
                                        {TinviteUsers}
                                    </Button>
                                </>}
                            </Box>
                            {isInvite ? <>
                                <Box style={{ padding: '0px 6px' }} sx={{ display: "flex", gap: 3, mb: 3 }}>
                                    <TextField
                                        sx={isRLS ? { paddingTop: '10px', width: "50%", marginLeft: '6px' } : { paddingTop: '10px', width: "70%", marginLeft: '6px' }}
                                        label={TguestEmailUdt}
                                        onChange={(e) => setInviteEmail(e.target.value)}
                                        value={inviteEmail}
                                        fullWidth
                                        placeholder={TguestEmailNoteUdt}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                    <TextField
                                        sx={{ paddingTop: '10px', width: "20%", marginLeft: '6px' }}
                                        label={TvalidUntilUdt}
                                        onChange={(e) => handleDate(e.target.value)}
                                        value={inviteValidUntil}
                                        fullWidth
                                        type="date"
                                        required
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {isRLS ?
                                        <TextField sx={{ paddingTop: '10px', width: "15%", marginLeft: '6px' }}

                                            fullWidth
                                            onChange={(e) => setInviteRls(e.target.value)}
                                            value={inviteRls}
                                            required
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="RLS"
                                        />
                                        : <></>}
                                </Box>
                                <Box style={{ padding: '0px 6px' }}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                    }}
                                >
                                    <Button className="margin-right-base "
                                        variant="contained"
                                        color="primary"
                                        onClick={() => inviteSubmit()}
                                    >
                                        {TInvite}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            setIsInvite(false)
                                            setInviteEmail('')
                                            setInviteRls('')
                                        }}
                                    >
                                        {Tcancel}
                                    </Button>
                                </Box>
                            </> : <>
                                <TextField style={{ padding: '0px 6px' }}
                                    sx={{ mt: '-5px' }}
                                    fullWidth
                                    placeholder="Search for a person or group (Minimum 3 characters)"
                                    variant="outlined"
                                    value={inputValue}
                                    onChange={handleChangeSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{ fontSize: '90px' }}>
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    isUserLoading ? (
                                        <Box
                                            sx={{
                                                top: 0, height: '100%', display: 'flex',
                                                zIndex: 9999, justifyContent: 'center', alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <TableContainer component={Paper} className="display-rec-4" style={{ padding: '0px 6px', marginTop: '10px' }} >
                                            <Table>
                                                <TableBody>
                                                    {DashboardUserData.length > 0 ? (
                                                        DashboardUserData.map((data, i) => (
                                                            <TableRow key={i} sx={{ borderBottom: "2px solid", borderColor: "primary.main" }}>
                                                                <TableCell sx={{ fontWeight: "bold" }}>
                                                                    <TextField
                                                                        sx={{ paddingTop: '10px', background: 'none' }}
                                                                        label={data.displayName ? data.displayName : data.firstName}
                                                                        value={data.userPrincipalName ? data.userPrincipalName : data.email}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </TableCell>
                                                                <TableCell sx={{ color: "#757575" }}>
                                                                    {/*<TextField*/}
                                                                    {/*    label={TvalidUntilUdt}*/}
                                                                    {/*    required*/}
                                                                    {/*    fullWidth*/}
                                                                    {/*    type="date"*/}
                                                                    {/*    value={getNextYearDate()}*/}
                                                                    {/*/>*/}
                                                                    <TextField sx={{ color: "#757575", paddingTop: '10px', width: "80%", marginLeft: '6px' }}
                                                                        label={TvalidUntilUdt}
                                                                        fullWidth
                                                                        type="date"
                                                                        value={data.validUntil}
                                                                        onChange={(e) => handleValidUntil(e, i, data)}
                                                                        required
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={canShareColliers ? {
                                                                            border: '1px solid #000759',
                                                                            padding: '6px 13px 6px 8px',
                                                                            color: '#000759',
                                                                        } : {
                                                                            border: '1px solid rgb(170, 170, 170)',
                                                                            padding: '6px 13px 6px 8px',
                                                                            color: 'rgb(170, 170, 170)',
                                                                        }}
                                                                        variant="outlined"
                                                                        startIcon={canShareColliers ? <AddIcon /> : <></>}
                                                                        disabled={!canShareColliers}
                                                                        sx={{
                                                                            fontWeight: "bold",
                                                                            textTransform: "none",
                                                                        }}
                                                                        onClick={(event) => {
                                                                            AddtoGroupUsers(data); // Pass user data to AddtoGroupUsers
                                                                        }}
                                                                    >
                                                                        {TAddAsUdt}
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center" sx={{ color: "#757575" }}>
                                                                {TnoRecordFound}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                }
                            </>}
                        </Box>
                    </Box>
                </Box>
                <Box className="row border rounded-1 mt-2 py-4 pt-3 ml-4" sx={{ paddingY: 2 }}>
                    <Box className="action-buttons mt-1" sx={{ display: 'flex', justifyContent: "center", gap: 2 }}>
                        <Button className="padding-right-s padding-left-s"
                            variant="outlined"
                            sx={{
                                borderColor: '#25408f',
                                color: '#25408f',
                                paddingY: 1,
                                paddingX: 4,
                                textTransform: 'capitalize',
                                borderRadius: 1,
                            }}
                            onClick={() => {
                                if (dashboardID != null && dashboardID != undefined && dashboardID != '' && dashboardID > 0) {
                                    updateDashboard()
                                } else {
                                    console.log('submit enter')
                                    SubmitDashboard()
                                }
                            }}
                        >
                            {Tsave}
                        </Button>
                        <Button className="padding-right-s padding-left-s"
                            variant="outlined"
                            sx={{
                                borderColor: '#25408f',
                                color: '#25408f',
                                paddingY: 1,
                                paddingX: 3,
                                textTransform: 'capitalize',
                                borderRadius: 1,
                            }}
                            onClick={() => navigate('/dashboard')}
                        >
                            {Tcancel}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Footer />
            {/* <Dialog
                open={submitModal}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                        padding: "16px",
                        width: "400px",
                        textAlign: "center",
                    },
                }}
            >
                <DialogTitle>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        style={{ color: "#000959", marginBottom: "8px" }}
                    >
                        {dashboardID ? "Update Dashboard" : "Submit Dashboard"}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                        Are you sure you want to {dashboardID ? "Add" : "Update"} this Dashboard?
                    </Typography>
                </DialogContent>

                <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                    <Button
                        variant="outlined"
                        startIcon={<Cancel />}
                        onClick={() => toggleSubmitModal()}
                        style={{
                            color: "#000959",
                            borderColor: "#000959",
                            textTransform: "none",
                            borderRadius: "6px",
                        }}
                    >
                        {Tcancel}
                    </Button>
                    {console.log('dashboardID', dashboardID)}
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (dashboardID) {
                                updateDashboard()
                            } else {
                                SubmitDashboard()
                            }
                        }}
                        startIcon={<Delete />}
                        style={{
                            backgroundColor: "#000959",
                            color: "#FFFFFF",
                            textTransform: "none",
                            borderRadius: "6px",
                        }}
                    >
                        {Tsubmit}
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog
                open={isRemoveUserModal}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                        padding: "16px",
                        width: "400px",
                        textAlign: "center",
                    },
                }}
            >
                <DialogTitle>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        style={{ color: "#000959", marginBottom: "8px" }}
                    >
                        {"Remove User"}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                        Are you sure you want to Remove User from this Group?
                    </Typography>
                </DialogContent>

                <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                    <Button
                        variant="outlined"
                        startIcon={<Cancel />}
                        onClick={() => toggleRemoveUser()}
                        style={{
                            color: "#000959",
                            borderColor: "#000959",
                            textTransform: "none",
                            borderRadius: "6px",
                        }}
                    >
                        {Tcancel}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleRemovefromGroupList(removeUserIndex)}
                        startIcon={<Delete />}
                        style={{
                            backgroundColor: "#000959",
                            color: "#FFFFFF",
                            textTransform: "none",
                            borderRadius: "6px", cursor: 'pointer'
                        }}
                    >
                        {Tdelete}
                    </Button>
                </DialogActions>
            </Dialog>
            {ToastComponent}
        </>
    );
};

export default DashboardAdd;
