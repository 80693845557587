import React, { useState, useEffect } from "react";
import {
    Container, Box, Typography, Button, TextField, Select, MenuItem, InputLabel,
    FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Icon, Pagination, InputAdornment, Dialog, DialogTitle, DialogContent,
    DialogActions, Tooltip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withTranslation } from "react-i18next";
import { Search, List, GridView, StarOutline, Star, Share, Delete, Edit, Close, Save, Cancel } from "@mui/icons-material";
import axios from "axios";
/*import { useHistory } from "react-router-dom";*/
import { GetDashboardGroupListSearchUrl, DeleteActionUrl, getFillListUrl } from "../../utils/constants";
import CommonPagination from "../../components/CommonPagination";
import { axiosDelete, axiosGet, axiosPost } from "../../utils/Axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../components/toast';

//shayd shifa maam merge nhi liye
const DashboardGroupList = (props) => {
    //constructor(props) {
    //    super(props);
    //    this.state = {
    //        isLoading: false,
    //    };
    //}
    const { ToastComponent, showToast } = useToast();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    //const[dropdownList, setDropdownList] = useState([
    //    { id: 1, label: "Option 1" },
    //    { id: 2, label: "Option 2" },
    //    { id: 3, label: "Option 3" },
    //]);
    const [dropdownList, setDropdownList] = useState([]);
    /*const [countryID, setcountryID] = useState(localStorage.getItem("userInfo")!=null?JSON.parse(localStorage.getItem("userInfo")).countryID:0);*/
    const [countryID, setcountryID] = useState("0");
    const [groupName, setGroupName] = useState("");
    const [dashboardGroup, setdashboardGroup] = useState("");
    const [status, setStatus] = useState("Active");

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    // Handle change event
    const handleChange = (event) => {
        setcountryID(event.target.value);
        console.log("Selected Value:", event.target.value);
    };
    //render() {
    //const { isLoading } = this.state;
    const navigate = useNavigate();
    const [userData, setUserInfo] = useState([]);
    const [reset, setReset] = useState(false);
    const [dashboardGroupList, setdashboardGroupList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredDashboardGroups, setFilteredDashboardGroups] = useState(dashboardGroupList)
    const listItemsPerPage = 10;
    const totalItems = filteredDashboardGroups?.length;
    const startIndex = (currentPage - 1) * listItemsPerPage;
    const paginatedDashboardGroups = filteredDashboardGroups?.slice(startIndex, startIndex + listItemsPerPage);

    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo == null) {
            //history.push('/login/root-data');
            navigate("/login/KeyStone_Login");
            return;
        } else {
            // Simulate API call
            fetchData();
            getDashboardGroupList();
        }

    }, [reset]);


    const fetchData = async () => {
        setIsLoading(true);
        try {
            const data = await axiosGet(getFillListUrl, {
                /*params: {*/
                actionID: 2,
                valueID: null,
                keyValue: null
                /*},*/
            });
            if (data != null && data.length > 0) {
                setDropdownList(data);
            }
            else {
                console.error("Failed to fetch country list: No data found.");
            }
        }
        catch (error) {
            console.error("Error fetching country list:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getDashboardGroupList = async () => {
        setIsLoading(true);
        try {
            const dashboardGroupSearchParam = {
                dashboardGroupcountryID: countryID > 0 ? countryID.toString() : "0",
                dashboardGroupName: (groupName == "" || groupName == null || groupName == undefined) ? "" : groupName,
                status: (status == "Active") ? true : ((status == "" || status == "0") ? null : false)
            };
            const response = await axiosPost(GetDashboardGroupListSearchUrl, dashboardGroupSearchParam);
            if (response) {
                setdashboardGroupList(response);
                setFilteredDashboardGroups(response);
            } else {
                showToast('error', 'Failed to fetch dashboard groups: No data found.');
                console.error("Failed to fetch dashboard groups: No data found.");
            }
        } catch (error) {
            console.error("Error fetching dashboard groups:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const [isDeleteModal, setDeleteModal] = useState(false);
    const [dashboardGroupID, setDashboardGroupID] = useState('');

    const toggleDeleteModal = (dashboardGroupID, dashboardGroup) => {
        if (isDeleteModal == true) {
            setDeleteModal(false);
            setDashboardGroupID('');
            setdashboardGroup('');
        } else {
            console.log(dashboardGroupID, dashboardGroup)
            setDeleteModal(true);
            setDashboardGroupID(dashboardGroupID);
            setdashboardGroup(dashboardGroup);
        }
    };
    const handleDelete = async (dashboardGroupID) => {
        setIsLoading(true);
        try {
            //const response = await axiosDelete(DeleteActionUrl, { moduleID: 5, valueID: dashboardGroupID.toString()});
            const response = await axiosDelete(DeleteActionUrl, { moduleID: 5, valueID: dashboardGroupID.toString() });
            if (response) {
                showToast('success', 'Successfully Deleted!');
                getDashboardGroupList();
            } else {
                console.error("Failed to fetch dashboards groups: No data found.");
                showToast('error', 'Failed to fetch dashboard groups: No data found.');
            }
        } catch (error) {
            console.error("Error fetching dashboard groups:", error);
            showToast('error', `Error fetching dashboard groups:, ${error}`)
        } finally {
            setIsLoading(false);
            setDeleteModal(false); // Close the modal after the action
        }
    }
    const searchBtnCall = async () => {
        getDashboardGroupList();
        setReset(false)
    }
    const resetSearch = async () => {
        setcountryID("0");
        setGroupName("");
        setStatus("Active");
        setReset(true)
    }
    const editGroup = async (groupID) => {
        navigate("/dashboard-group-add", { state: { groupID } });
    }

    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />
            <div
                style={{
                    position: 'relative',
                    justifyContent: "space-between",
                    alignItems: "center", background: "#EDF2FF", width: '100%', marginTop: 50,
                    padding: '2rem'
                }}
            >
                <div></div>
                <Typography variant="h5" className="padding-top-m text-center page-name">
                    {t('dashboardGroup.dashboardGroup')}
                </Typography>
                <Button className="add-dashboard-btn"
                    variant="outlined"
                    startIcon={<i className="fa fa-plus" style={{ fontSize: 12 }} />}
                    sx={{
                        fontSize: 13,
                        letterSpacing: "0.5px",
                        textTransform: "uppercase",
                        mx: 3,
                        py: 0.5,
                        px: 3,
                        borderRadius: 1,
                    }}
                    onClick={() => navigate("/dashboard-group-add")}
                >
                    {t('common.Add')}
                </Button>
            </div>
            <main
                id="main"
                style={{
                    justifyContent: "center",
                    minHeight: "100vh",
                    width: "100%",
                    padding: '24px',
                    paddingTop: '0px'
                }}
            >
                <Box
                    className="pagetitle main-area-top"
                    textAlign="center"
                    sx={{ margin: "2rem" }}
                >


                    <section className="section page-body">
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 2,
                                mb: 3,

                            }}
                        >
                            <FormControl fullWidth sx={{ maxWidth: "300px" }}>
                                {/*<InputLabel>{t('dashboard_group.country')}</InputLabel>*/}
                                {/*<Select id="dropdown"*/}
                                {/*    value={countryID}*/}
                                {/*    onChange={handleChange}>*/}
                                <TextField className="space-lab"
                                    label={t('dashboardGroup.country')}
                                    onChange={handleChange}
                                    value={countryID}
                                    select
                                    fullWidth
                                    variant="outlined"
                                >
                                    <MenuItem key="0" value="0" >
                                        {t('dashboardGroup.selectCountry')}
                                    </MenuItem>
                                    {dropdownList.map((item) => (
                                        //<option key={item.code} value={item.code}>
                                        //    {item.description}
                                        //</option>
                                        <MenuItem key={item.code} value={item.code}>
                                            {item.description}
                                        </MenuItem>
                                    ))}
                                    {/*<MenuItem value="">*/}
                                    {/*    <em>Add Country</em>*/}
                                    {/*</MenuItem>*/}
                                    {/*<MenuItem value="India">India</MenuItem>*/}
                                    {/*<MenuItem value="Singapore">Singapore</MenuItem>*/}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth sx={{ maxWidth: "300px" }}>
                                <TextField
                                    className="space-lab"
                                    label={t('dashboardGroup.groupName')}
                                    placeholder={t('dashboardGroup.groupName')}
                                    variant="outlined"
                                    fullWidth
                                    value={groupName}
                                    onChange={handleGroupNameChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            </InputAdornment>
                                        ),
                                    }} />
                            </FormControl>
                            <FormControl fullWidth sx={{ maxWidth: "300px" }}>
                                <TextField
                                    className="space-lab"
                                    label={t('dashboardGroup.status')}
                                    onChange={handleStatusChange}
                                    value={status}
                                    select
                                    fullWidth
                                    variant="outlined"
                                >
                                    {/*<Select value={status}*/}
                                    {/*    onChange={handleStatusChange}>*/}
                                    <MenuItem value="0">
                                        {t('dashboardGroup.selectStatus')}
                                    </MenuItem>
                                    <MenuItem value="Active">{t("common.active")}</MenuItem>
                                    <MenuItem value="Inactive">{t("common.inactive")}</MenuItem>
                                </TextField>
                            </FormControl>
                            <Button className="margin-left-base margin-right-s" variant="contained" sx={{ alignSelf: "center" }} onClick={() => searchBtnCall()}>
                                {t('common.search')}
                            </Button>
                            <Button variant="contained" sx={{ alignSelf: "center" }} onClick={() => resetSearch()}>
                                {t('common.reset')}
                            </Button>
                        </Box>

                        <TableContainer component={Paper} className="list-height">
                            <Table>
                                <TableHead>
                                    <TableRow sx={{
                                        borderBottom: "2px solid",
                                        borderColor: "primary.main",
                                    }}>
                                        <TableCell>{t('dashboardGroup.groupName')}</TableCell>
                                        <TableCell>{t('dashboardGroup.country')}</TableCell>
                                        <TableCell>{t('dashboardGroup.status')}</TableCell>
                                        <TableCell align="center">{t('common.Action')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedDashboardGroups?.length > 0 ? (
                                        paginatedDashboardGroups?.map((dashboardGroup, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ width: '20rem', wordWrap: "break-word", whiteSpace: "normal", wordBreak: "break-word" }}>{dashboardGroup.dashboardGroup}</TableCell>
                                                <TableCell>{dashboardGroup.countryName}</TableCell>
                                                <TableCell>{dashboardGroup.status}</TableCell>
                                                <TableCell align="center">
                                                    <Box display="flex" justifyContent="center">
                                                        {/* <IconButton onClick={() => toggleDeleteModal(dashboardGroup.dashboardGroupID, dashboardGroup.dashboardGroup)}>
                                                                                <Delete color="primary" />
                                                                            </IconButton>
                                                                            <IconButton onClick={() => editGroup(dashboardGroup.dashboardGroupID)}>
                                                                                <Edit color="primary" />
                                                                            </IconButton> */}


                                                        <Button className="padding-right-none">
                                                            <Tooltip title="Remove">
                                                                <Icon className='fa-light fa-trash-can font-size-base text-primary' onClick={() => toggleDeleteModal(dashboardGroup.dashboardGroupID, dashboardGroup.dashboardGroup)} />
                                                                </Tooltip>
                                                        </Button>
                                                        <Button className="padding-right-none">
                                                            <Tooltip title="Edit">
                                                                <Icon className='fa-light fa-pen-to-square font-size-base text-primary' onClick={() => editGroup(dashboardGroup.dashboardGroupID)} />
                                                                </Tooltip>
                                                        </Button>


                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))) : (
                                        <TableCell colSpan={4}>{t('common.noRecordsFound')}</TableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <CommonPagination
                            totalItems={totalItems}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                        />

                        <Dialog
                            open={isDeleteModal}
                            PaperProps={{
                                style: {
                                    borderRadius: "12px",
                                    padding: "16px",
                                    width: "400px",
                                    textAlign: "center",
                                },
                            }}
                        >
                            <DialogTitle>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    style={{ color: "#000959", marginBottom: "8px" }}
                                >
                                    {t('dashboardGroup.deleteDashboardGroup')}
                                    {/*- <strong>{dashboardGroup}</strong>*/}
                                </Typography>
                            </DialogTitle>

                            <DialogContent>
                                <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                                    {t('common.deleteMessage')}
                                </Typography>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<Cancel />}
                                    onClick={() => toggleDeleteModal('', '')}
                                    style={{
                                        color: "#000959",
                                        borderColor: "#000959",
                                        textTransform: "none",
                                        borderRadius: "6px",
                                    }}
                                >
                                    {t('common.Cancel')}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleDelete(dashboardGroupID)}
                                    startIcon={<Delete />}
                                    style={{
                                        backgroundColor: "#000959",
                                        color: "#FFFFFF",
                                        textTransform: "none",
                                        borderRadius: "6px",
                                    }}
                                >
                                    {t('common.Delete')}
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </section>

                </Box>
            </main>
            <Footer />{ }
            {ToastComponent}
        </>
        //    </>
        //)}
        /*</>*/
    );
    //}
}

export default withTranslation()(DashboardGroupList);
