import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  Typography, IconButton, MenuItem, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
    Icon,
    Tooltip
} from "@mui/material";
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation, withTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonPagination from "../../components/CommonPagination";
import { useNavigate } from "react-router-dom";
import { countryActionID, DeleteActionUrl, GetClientListSearchUrl } from "../../utils/constants";
import { axiosDelete, axiosGet, axiosPost } from "../../utils/Axios";
import { useFillList } from "../../components/fillList";
import { useToast } from '../../components/toast';
import { Search, List, GridView, StarOutline, Star, Share, Delete, Edit, Close, Save, Cancel } from "@mui/icons-material";

const ClientUserList = () => {
  const { ToastComponent, showToast } = useToast();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [Status, setStatus] = useState('Active');
  const [reset, setReset] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listItemsPerPage = 10;
  const totalItems = userList?.length;
  const startIndex = (currentPage - 1) * listItemsPerPage;
  const paginatedUserList = userList?.slice(startIndex, startIndex + listItemsPerPage);

  //translation
  //common
  const TAction = t("common.Action");
  const TnoRecordFound = t("common.noRecordFound");
  const Tsearch = t("common.search");
  const Treset = t("common.reset");
  const Tcancel = t("common.Cancel");
  const Tdelete = t("common.Delete");
  const Tstatus = t("common.status");
  const Tselect = t("common.select");
  const Tactive = t("common.active");
  const Tinactive = t("common.inactive");
  //list
  const TpageTitle = t("clientPage.pageTitle")
  const Tname = t("clientPage.Name")
  const Temail = t("clientPage.Email")
  const Taddress = t("clientPage.Address")
  const TactiveDashboard = t("clientPage.activeDashboard")
  const TenterName = t("clientPage.enterName")
  const TenterEmail = t("clientPage.addEmail")
  const TselectStatus = t("clientPage.selectStatus")

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      navigate("/login/root-data");
      return;
    } else {
      getClientSearchList();
    }
    setIsLoading(true);
  }, [reset]);

  const getClientSearchList = async () => {
    const payload = {
      Name: userName != '' ? userName : '',
      EmailAddress: emailAddress != '' ? emailAddress : '',
      Status: (Status == "Active") ? true : ((Status == "" || Status == "0") ? null : false)
      //  Status: (Status == '' || Status == 'Active') ? true : false,
    }
    try {
      setIsLoading(true);
      const response = await axiosPost(GetClientListSearchUrl, payload);
      console.log(response);
      if (response) {
        console.log(response)
        setUserList(response);
        setCurrentPage(1)
      } else {
        console.error("Failed to fetch dashboards: No data found.");
      }
    } catch (error) {
      console.error("Error fetching dashboards:", error);
    } finally {
      setIsLoading(false);
    }
  }
  const handleFilterChange = (e, field) => {
    const value = e.target.value
    if (field == 'user') {
      setUserName(value)
    } else if (field == 'email') {
      setEmailAddress(value)
    } else if (field == 'status') {
      setStatus(value)
    }
  };
  const resetSearch = async () => {
    setUserName("");
    setEmailAddress("");
    setStatus('Active');
    setReset(true)
    setCurrentPage(1)
};
  const toggleDeleteModal = (userID) => {
    if (isDeleteModal == true) {
      setDeleteModal(false);
      setUserID('');
    } else {
      setDeleteModal(true);
      setUserID(userID);
    }
  };
  const handleDelete = async (userID) => {
    try {
      setIsLoading(true);
      const response = await axiosDelete(DeleteActionUrl, { moduleID: 2, valueID: userID.toString() });
      if (response) {
        showToast('success', 'Successfully Deleted!');
        getClientSearchList();
      } else {
        showToast('error', 'Failed to fetch user list: No data found.');
      }
    } catch (error) {
      console.log(error)
      showToast('error', `Error fetching user list:, ${error}`)
    } finally {
      setIsLoading(false);
      setDeleteModal(false); // Close the modal after the action
    }
  }
  const navigateEdit = (userID) => {
    console.log(userID, 'list')
    navigate('/client-user-management-manage', { state: { userID } })
  }
  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Header />
      <Box style={{
        position: 'relative',
        justifyContent: "space-between",
        alignItems: "center", background: "#EDF2FF", width: '100%', marginTop: 45,
        padding: '2rem'
      }}>
        <Typography variant="h5" className="padding-top-m text-center page-name" gutterBottom>
          {TpageTitle}
        </Typography>
      </Box>
      <main
        id="main"
        style={{
          justifyContent: "center",
          minHeight: "100vh",
          width: "100%",
          padding: '24px',
          paddingTop: '0px'
        }}
      >

        <Box className="pagetitle main-area-top "
          textAlign="center"

        >
          <section className="section page-body" style={{ marginTop: '20px' }}>
            <Box className="" >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  mb: 3,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    borderColor: "primary.main",
                    borderRadius: 1,
                    paddingY: 2,
                    paddingX: 2,
                    marginX: "auto", // Centers horizontally
                  }}
                >
                  {/* Filters */}
                  <Grid container mb={1} spacing={2}>
                    <Grid item sm={3} >
                      <TextField className="cli-dash space-lab"
                        label={Tname}
                        variant="outlined"
                        placeholder={TenterName}
                        value={userName}
                        onChange={(e) => handleFilterChange(e, "user")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <TextField className="cli-dash space-lab"
                        label={Temail}
                        variant="outlined"
                        placeholder={TenterEmail}
                        value={emailAddress}
                        onChange={(e) => handleFilterChange(e, "email")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <TextField className="cli-dash space-lab"
                        label={Tstatus}
                        variant="outlined"
                        select
                        fullWidth
                        placeholder={TselectStatus}
                        value={Status}
                        onChange={(e) => handleFilterChange(e, "status")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value="">{Tselect}</MenuItem>
                        <MenuItem value="Active">{Tactive}</MenuItem>
                        <MenuItem value="In-Active">{Tinactive}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" className="margin-right-s" onClick={getClientSearchList}>
                        {Tsearch}
                      </Button>
                      <Button variant="contained" onClick={resetSearch}>
                        {Treset}
                      </Button>
                    </Grid>
                  </Grid>
                  <TableContainer className="border list-height">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{Tname}</TableCell>
                          <TableCell>{Temail}</TableCell>
                          <TableCell align="center">{TactiveDashboard}</TableCell>
                          <TableCell>{Tstatus}</TableCell>
                          <TableCell align="center">{TAction}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedUserList?.length > 0 ? (
                          paginatedUserList.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.emailAddress}</TableCell>
                              <TableCell align="center">{item.activeDashboard}</TableCell>
                              <TableCell>{item.status}</TableCell>
                              <TableCell align="center">
                                {/* <IconButton
                                      color="error"
                                      onClick={() => toggleDeleteModal(item.userID)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>

                                    <IconButton
                                      color="primary"
                                      onClick={() => navigateEdit(item.userID)}
                                    >
                                      <Edit />
                                    </IconButton> */}



                                      <Button className="padding-right-none">
                                          <Tooltip title="Remove">
                                              <Icon className='fa-light fa-trash-can font-size-base text-primary' onClick={() => toggleDeleteModal(item.userID)} />
                                              </Tooltip>
                                </Button>

                                      <Button className="padding-right-none">
                                          <Tooltip title="Edit">
                                              <Icon className='fa-light fa-pen-to-square font-size-base text-primary' onClick={() => navigateEdit(item.userID)} />
                                              </Tooltip>
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5}>{TnoRecordFound}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <CommonPagination
                    totalItems={totalItems}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                  />
                </Grid>
                <Dialog
                  open={isDeleteModal}
                  PaperProps={{
                    style: {
                      borderRadius: "12px",
                      padding: "16px",
                      width: "400px",
                      textAlign: "center",
                    },
                  }}
                >
                  <DialogTitle>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      style={{ color: "#000959", marginBottom: "8px" }}
                    >
                      {t("common.deleteMessage")}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#555555",
                        marginBottom: "16px",
                      }}
                    >
                      {t("user_mgmt.deleteUser")}
                    </Typography>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                    <Button
                      variant="outlined"
                      startIcon={<Cancel />}
                      onClick={() => toggleDeleteModal("")}
                      style={{
                        color: "#000959",
                        borderColor: "#000959",
                        textTransform: "none",
                        borderRadius: "6px",
                      }}
                    >
                      {Tcancel}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleDelete(userID)}
                      startIcon={<Delete />}
                      style={{
                        backgroundColor: "#000959",
                        color: "#FFFFFF",
                        textTransform: "none",
                        borderRadius: "6px",
                      }}
                    >
                      {Tdelete}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </section>
        </Box>


      </main>

      <Footer />
      {ToastComponent}
    </>
    //  )}
    //</>
  );

};

export default withTranslation()(ClientUserList);
