// src/Footer.js
import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-container py-3">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
          <Box>
            <Typography variant="body2" color="primary">
              Copyright © 2025 Colliers International
            </Typography>
          </Box>
          <Box>
            <Link href=" https://www.colliers.com/en-in/cookie-policy" target='_blank' className='footer-link' color="primary" sx={{ mx: 1 }}>
              Cookie Policy
            </Link>
            <Link href="https://www.colliers.com/en-in/privacy-policy" className='footer-link' target='_blank' color="primary" sx={{ mx: 1 }}>
              Privacy Policy
            </Link>
            <Link href="https://www.colliers.com/en-in/terms-of-use" className='footer-link' target='_blank' color="primary" sx={{ mx: 1 }}>
              Terms of Use
            </Link>
          </Box>
          <Box>
            <IconButton href="#" color="primary">
              <LinkedInIcon />
            </IconButton>
            <IconButton href="#" color="primary">
              <TwitterIcon />
            </IconButton>
            <IconButton href="#" color="primary">
              <FacebookIcon />
            </IconButton>
            <IconButton href="#" color="primary">
              <InstagramIcon />
            </IconButton>
          </Box>
        </Box>
      </footer>
    );
  }
}

export default Footer;