import { useState, useEffect } from "react";
import { axiosGet } from "../utils/Axios";
import { getFillListUrl } from "../utils/constants";

// Custom hook to fetch fill list
export const useFillList = (actionID, valueID, keyValue) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        actionID: actionID ?? null,
        valueID: valueID ?? null,
        keyValue: keyValue ?? null,
      };
      const endpoint =`?actionID=${params.actionID}&valueID=${params.valueID}&keyValue=${params.keyValue}`
      try {
        const response = await axiosGet(`${getFillListUrl}${endpoint}`);
        setData(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [actionID, valueID, keyValue]);

  return { data, loading, error };
};