import React, { Component } from "react";
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withTranslation } from "react-i18next";
import {
    Box,
    TextField,
    Select,
    MenuItem,
    Button,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper, InputLabel, InputAdornment, FormControl
} from "@mui/material";
import { useState, useEffect, useCallback, useRef } from "react";
import { GetDashboardLogListSearchUrl, GetDashboardLogListExcelUrl, GetThirdPartyAPI, SearchUserAndADGroupUrl, logoutUrl, dashboardGroupActionID } from "../../utils/constants";
import { useToast } from '../../components/toast';
import { Search, List, GridView, StarOutline, Star, Share, Delete, Edit, Close, Save, Cancel } from "@mui/icons-material";
import { axiosDelete, axiosGet, axiosPost } from "../../utils/Axios";
import CommonPagination from "../../components/CommonPagination";
import { useTranslation } from 'react-i18next';
import moment from "moment";
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import dayjs from 'dayjs';
import _, { set } from 'lodash';
import { useNavigate } from "react-router-dom";
import { useFillList } from "../../components/fillList";

const DashboardLog = (props) => {
    const navigate = useNavigate()
    const { ToastComponent, showToast } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const [status, setStatus] = useState("Active");
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    const [userName, setUserName] = useState("");
    const [dashboard, setDashboard] = useState("");
    const [DashboardGroupID, setDashboardGroupID] = useState("");
    const [DashboardGroupFillList, setDashboardGroupFillList] = useState([]);
    const groupFillList = useFillList(dashboardGroupActionID, "", ""); // Action ID 3

    const [dashboardLogList, setdashboardLogList] = useState([]);
    const [reset, setReset] = useState(false);

    //const [startDate, setStartDate] = useState(new Date());
    //const [endDate, setEndDate] = useState(new Date());

    //const [value, setValue] = useState([null, null]);
    const [currentPage, setCurrentPage] = useState(1);
    const listItemsPerPage = 10;
    const [filteredDashboardLog, setFilteredDashboardLog] = useState(dashboardLogList);
    const startIndex = (currentPage - 1) * listItemsPerPage;
    const paginatedDashboardLog = filteredDashboardLog?.slice(startIndex, startIndex + listItemsPerPage);
    const totalItems = filteredDashboardLog?.length;

    const { t } = useTranslation();

    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };
    const handleDashboardChange = (event) => {
        setDashboard(event.target.value);
    };
    const searchBtnCall = async () => {
        getDashboardLogList();
        setReset(false)
    }
    const resetSearch = async () => {
        setFromDate("");
        setToDate("");
        setDateRange("");
        setShowDatePickers(false);
        setUserName("");
        setDashboard("");
        setDashboardGroupID("");
        setReset(true)
    };
    const callGetUserAPI = async () => {
        //const response = await axiosGet(SearchUserAndADGroupUrl, { keyword:"smith"});
        const response = await axiosPost(logoutUrl);
    }
    const exportBtnCall = async () => {
        setIsLoading(true);
        try {
            const dashboardLogSearchParam = {
                name: (userName == null || userName == "") ? "" : userName,
                dateRange: (dateRange == "" || dateRange == null || dateRange == undefined) ? "" : dateRange,
                dashboard: (dashboard == null || dashboard == "") ? "" : dashboard,
                dashboardGroupID: (DashboardGroupID == ""||DashboardGroupID=='select'||DashboardGroupID==null) ? 0 : Number(DashboardGroupID)  
            };
            const response = await axiosPost(GetDashboardLogListExcelUrl, dashboardLogSearchParam);
            if (response) {
                window.open(response.responseMsg);
            } else {
                showToast('error', 'Failed to generate excel');
            }
        } catch (error) {
            showToast('error', 'Failed to generate excel');
        } finally {
            setIsLoading(false);
        }
    }
    const getDashboardLogList = async () => {
        setIsLoading(true);
        try {
            const dashboardLogSearchParam = {
                dateRange: (dateRange == "" || dateRange == null || dateRange == undefined) ? "" : dateRange.toString().indexOf("Between") > -1 ? (fromDateRef.current + "|" + toDateRef.current) : dateRange,
                dashboard: (dashboard == null || dashboard == "") ? "" : dashboard,
                name: (userName == null || userName == "") ? "" : userName,
                dashboardGroupID: (DashboardGroupID == ""||DashboardGroupID=='select'||DashboardGroupID==null) ? null : DashboardGroupID  
            };
            const response = await axiosPost(GetDashboardLogListSearchUrl, dashboardLogSearchParam);
            if (response) {
                setdashboardLogList(response);
                setFilteredDashboardLog(response);
            } else {
                showToast('error', 'Failed to fetch dashboard log: No data found.');
                console.error("Failed to fetch dashboard log: No data found.");
            }
        } catch (error) {
            console.error("Error fetching dashboard log:", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo == null) {
            navigate("/login/KeyStone_Login");
            return;
        } else {
            getDashboardLogList();
            if (groupFillList.data) setDashboardGroupFillList(groupFillList.data);
        }
    }, [reset, groupFillList.data]);

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [dateRange, setDateRange] = useState("");

    const fromDateRef = useRef("");
    const toDateRef = useRef("");

    const handleFromDateChange = useCallback(
        _.debounce((event) => {
            if (event != "" && event != null && event != undefined) {
                //const formattedfromDate = dayjs(event).format('DD-MMM-YYYY');
                const formattedfromDate = moment(event, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
                setFromDate(event);
                fromDateRef.current = formattedfromDate;
                console.log("fromDate:", fromDate);
                handleDateChange();
            }
        }, 300), []);

    const handleToDateChange = useCallback(
        _.debounce((event) => {
            if (event != "" && event != null && event != undefined) {
                //const formattedfromDate = dayjs(event).format('DD-MMM-YYYY');
                const formattedtoDate = moment(event, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
                setToDate(event);
                toDateRef.current = formattedtoDate;
                handleDateChange();
            }
        }, 300), []);
    const handleDateChange = (event) => {
        if ((fromDateRef.current != null && fromDateRef.current != undefined && fromDateRef.current != "") &&
            (toDateRef.current == "" || toDateRef.current == undefined || toDateRef.current == null)) {
            setDateRange("greater than " + fromDateRef.current);
        }
        else if ((toDateRef.current != null && toDateRef.current != undefined && toDateRef.current != "") &&
            (fromDateRef.current == "" || fromDateRef.current == undefined || fromDateRef.current == null)) {
            setDateRange("less than " + toDateRef.current);
        }
        else if (fromDateRef.current != null && fromDateRef.current != undefined && fromDateRef.current != "" &&
            toDateRef.current != null && toDateRef.current != undefined && toDateRef.current != "") {
            //setDateRange(fromDateRef.current + "|" + toDateRef.current);
            setDateRange("Between " + fromDateRef.current + " and " + toDateRef.current);
        }
        else if (fromDateRef.current == null || fromDateRef.current == undefined || fromDateRef.current == "" ||
            toDateRef.current == null || toDateRef.current == undefined || toDateRef.current == "") {
            setDateRange("");
        }
    }
    const handleFromKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            setFromDate('');
            fromDateRef.current = "";
            handleDateChange();
        }
    };
    const handleToKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            setToDate('');
            toDateRef.current = "";
            handleDateChange();
        }
    }
    const [showDatePickers, setShowDatePickers] = useState(false);
    const openDatePickers = () => {
        setShowDatePickers(true);
    };
    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />

            <Box style={{
                position: 'relative',
                justifyContent: "space-between",
                alignItems: "center", background: "#EDF2FF", width: '100%', marginTop: 70,
                padding: '2rem'
            }}>
                <Typography variant="h6" align="center" className="pt-2 text-uppercase">
                    {t('dashboard_log.dashboardLog')}
                </Typography>
            </Box>
            <main
                id="main"
                style={{
                    justifyContent: "center",
                    minHeight: "100vh",
                    width: "100%",
                    padding: '25px',
                    paddingTop: '20px'
                }}
            >
                <section className="section page-body">
                    <Box >
                        <Grid container spacing={2}>
                            {/* Date Range */}
                            <Grid item xs={12} md={2.5}>
                                <Typography className="cus-label-date" variant="subtitle2" gutterBottom>
                                    Date Range
                                </Typography>
                                <TextField className=""
                                    fullWidth
                                    style={{ marginTop: '-15px' }}
                                    value={dateRange}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    onClick={openDatePickers}
                                    placeholder="Select Date"
                                />
                                {showDatePickers && (
                                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => handleFromDateChange(e.target.value)}
                                            required
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => handleToDateChange(e.target.value)}
                                            required
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                )}
                            </Grid>

                            {/* Status */}
                            <Grid item xs={12} md={2.5}>
                                <FormControl fullWidth>
                                    <TextField className="space-lab"
                                        label={t('dashboard_log.dashboardGroup')}
                                        onChange={(e)=>{
                                            setDashboardGroupID(e.target.value)
                                        }}
                                        value={DashboardGroupID}
                                        select
                                        required
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="0">{t('dashboard_log.selectDashboardGroup')}</MenuItem>
                                        {DashboardGroupFillList.map((data) => (
                                            <MenuItem key={data.code} value={data.code}>
                                                {data.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>

                            {/* Name */}
                            {/* <Grid item xs={12} md={2.5}>
                                <TextField className="space-lab"
                                    label={t('dashboard_log.name')}
                                    placeholder={t('dashboard_log.enterAName')}
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    value={userName}
                                    onChange={handleUserNameChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid> */}

                            {/* Dashboard */}
                            <Grid item xs={12} md={2.5}>
                                <TextField className="space-lab"
                                    label={t('dashboard_log.dashboard')}
                                    placeholder={t('dashboard_log.dashboard')}
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    value={dashboard}
                                    onChange={handleDashboardChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            {/* Buttons */}
                            <Grid style={{ padding: '0px', marginTop: '20px' }}  item xs={12} md={2} container justifyContent="flex-end" alignItems="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginRight: 2 }}
                                    style={{ border: "1px solid #25408f" }}
                                    onClick={searchBtnCall}
                                >
                                    {t('dashboard_log.view')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ border: "1px solid #25408f" }}
                                    onClick={resetSearch}
                                >
                                    {t('common.reset')}
                                </Button>
                            </Grid>
                        </Grid>


                        <Box className="row margin-top-m m-1">
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="body2" style={{ fontSize: 14, paddingTop: '20px' }}>
                                        {t('dashboard_log.total')} {totalItems} {t('dashboard_log.recordsFound')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ border: "1px solid #25408f" }}
                                        onClick={() => exportBtnCall()}
                                    >
                                        {t('dashboard_log.exportToExcel')}
                                    </Button>
                                    {/*<Button*/}
                                    {/*    variant="outlined"*/}
                                    {/*    color="primary"*/}
                                    {/*    style={{ border: "1px solid #25408f" }}*/}
                                    {/*    onClick={() => callGetUserAPI()}*/}
                                    {/*>*/}
                                    {/*    LogOut*/}
                                    {/*</Button>*/}
                                </Grid>
                            </Grid>

                            <TableContainer component={Paper} sx={{ mt: 2 }} className="list-height-log">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('dashboard_log.viewDate')}</TableCell>
                                            <TableCell>{t('dashboard_log.user')}</TableCell>
                                            <TableCell>{t('dashboard_log.userType')}</TableCell>
                                            <TableCell>{t('dashboard_log.dashboard')}</TableCell>
                                            <TableCell>{t('dashboard_log.group')}</TableCell>
                                            <TableCell>{t('dashboard_log.lang')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedDashboardLog?.length > 0 ? (
                                            paginatedDashboardLog?.map((dashboardLog, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{moment(dashboardLog.viewDate, "DD-MM-YYYY HH:mm:ss").isValid()
                                                        ? moment(dashboardLog.viewDate, "DD-MM-YYYY HH:mm:ss").format("DD MMM YYYY HH:mm:ss")
                                                        : "Invalid Date"}</TableCell>
                                                    <TableCell>{dashboardLog.user}</TableCell>
                                                    <TableCell>{dashboardLog.userType}</TableCell>
                                                    <TableCell sx={{ width: '20rem', wordWrap: "break-word", whiteSpace: "normal", wordBreak: "break-word" }}>{dashboardLog.dashboard}</TableCell>
                                                    <TableCell sx={{ width: '20rem', wordWrap: "break-word", whiteSpace: "normal", wordBreak: "break-word" }}>{dashboardLog.dashboardGroup}</TableCell>
                                                    <TableCell>{dashboardLog.lang}</TableCell>
                                                </TableRow>
                                            ))) : (
                                            <TableCell colSpan={6}>{t('common.noRecordsFound')}</TableCell>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <CommonPagination
                                totalItems={totalItems}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            />
                        </Box>
                    </Box>
                    <Box style={{ height: 150 }} />
                </section>
            </main>
            <Footer />
            {ToastComponent}
        </>
        //        </>

        //    )}
        //</>
    );
}

export default withTranslation()(DashboardLog);
