import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const Toast = ({ open, handleClose, severity, message }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioning
        >
            <Alert onClose={handleClose} severity={severity} className={severity=="success"?"succ-m":"err-m"} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export const useToast = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const showToast = (severity, message) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleClose = () => {
        setSnackbarOpen(false);
    };

    return {
        ToastComponent: (
            <Toast
                variant="filled"
                open={snackbarOpen}
                handleClose={handleClose}
                severity={snackbarSeverity}
                message={snackbarMessage}
            />
        ),
        showToast,
    };
};