import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Icon,
    Tooltip
} from "@mui/material";
import { Search, List, GridView, StarOutline, Star, Share, Delete, Edit, Close, Save, Cancel } from "@mui/icons-material";
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation, withTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonPagination from "../../components/CommonPagination";
import { useFillList } from "../../components/fillList";
import { countryActionID, DeleteActionUrl, GetClientDetailsUrl, DeleteDashboardUserUrl, getFillListUrl, UpdateUserDetailsUrl, InsertUserDetailsUrl } from "../../utils/constants";
import { axiosDelete, axiosGet, axiosPost } from "../../utils/Axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useToast } from "../../components/toast";

const ClientUserManage = () => {
    const { t } = useTranslation();
    const { ToastComponent, showToast } = useToast();
    //navigate
    const navigate = useNavigate();
    const location = useLocation();
    //state
    const [EditClientID, setEditClientID] = useState(location.state?.userID || null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [dashboardGrpList, setDashboardGrpList] = useState([]);
    const [userName, setUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [basecountryID, setBasecountryID] = useState('');
    const [activeDashboard, setActiveDashboard] = useState('');
    const [viewedDashboard, setViewedDashboard] = useState('');

    //filters
    const [filters, setFilters] = useState({ name: "", email: "", status: "" });
    //fillist
    const [clientCountryFillList, setCountryFillList] = useState([]);
    const countryFillList = useFillList(countryActionID, "", "")
    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const listItemsPerPage = 10;
    const totalItems = dashboardGrpList?.length;
    const startIndex = (currentPage - 1) * listItemsPerPage;
    const paginatedDashboardGrpList = dashboardGrpList?.slice(startIndex, startIndex + listItemsPerPage);
    //translation
    //common
    const TAction = t("common.Action");
    const TnoRecordFound = t("common.noRecordFound");
    const Tdisable = t("common.disable");
    const Tcancel = t("common.Cancel");
    const Tsave = t("common.Save");
    const Tdashboard = t("common.Dashboard");
    //list
    const TpageTitle = t("clientPage.pageTitle")
    const Tname = t("clientPage.Name")
    const Temail = t("clientPage.Email")
    const Taddress = t("clientPage.Address")
    const TenterName = t("clientPage.enterName")
    const TenterEmail = t("clientPage.addEmail")
    const TclientDetails = t("clientPage.clientDetails")
    const TActiveDashboards = t("clientPage.ActiveDashboards")
    const TViewedDashboards = t("clientPage.ViewedDashboards")
    const TListofAccessibleDashboards = t("clientPage.ListofAccessibleDashboards")
    const TGroup = t("clientPage.Group")
    const TAccessRight = t("clientPage.AccessRight")
    const TvalidTill = t("clientPage.validTill")
    const Tcountry = t("clientPage.Country")
    const TaddCountry = t("clientPage.addCountry")
    const TdeleteClient = t("clientPage.deleteClient")
    const TdeleteClientConfirm = t("clientPage.deleteClientConfirm")


    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (!userInfo) {
            navigate("/login/root-data");
            return;
        } else {
            fetchData();
        }
        //if (countryFillList.data) setCountryFillList(countryFillList.data);
        //setIsLoading(true);
    }, []);


    const fetchData = async () => {
        setIsLoading(true);
        try {
            let updatedAccessCountries = [];
            const data = await axiosGet(getFillListUrl, {
                actionID: 2,
                valueID: null,
                keyValue: null
            });
            if (data != null && data.length > 0) {
                setCountryFillList(data);
                updatedAccessCountries = data.map(item => ({
                    ...item,
                    defaultChecked: false
                }));
                setAccessCountries(updatedAccessCountries);
            }
            else {
                console.error("Failed to fetch country list: No data found.");
            }
            getClientDetails(location.state?.userID, updatedAccessCountries);
        }
        catch (error) {
            console.error("Error fetching country list :", error);
        } finally {
            setIsLoading(false);
        }
    };




    const getClientDetails = async (EditClientID, updatedAccessCountries) => {
        try {
            setIsLoading(true);
            const response = await axiosGet(`${GetClientDetailsUrl}?EditClientID=${EditClientID}`);
            if (response) {
                setUserName(response.userName)
                setEmailAddress(response.emailAddress)
                setBasecountryID(response.basecountryID)
                setActiveDashboard(response.activeDashboard)
                setViewedDashboard(response.viewedDashboard)
                setDashboardGrpList(response.clientDashboardGroup)
                if (response.userCountries != null && response.userCountries != undefined && response.userCountries.length > 0) {
                    const countryIdArray = response.userCountries.map(country => parseInt(country.countryID, 10));
                    const newAccessCountries = updatedAccessCountries.map(item => ({
                        ...item,
                        defaultChecked: false
                    }));
                    newAccessCountries.forEach(x => {
                        if (countryIdArray.includes(Number(x.code))) {
                            x.defaultChecked = true
                        }
                    })
                    setAccessCountries(newAccessCountries);

                    const countryIdString = countryIdArray.join(',');
                    setOriginalcountryIds(countryIdString);
                }
            } else {
                console.error("Failed to fetch getClientDetails: Invalid response format.");
                setUserName('')
                setEmailAddress('')
                setBasecountryID('')
                setActiveDashboard('')
                setViewedDashboard('')
                setDashboardGrpList([]);
            }
        } catch (error) {
            console.error("Error fetching getClientDetails:", error);
            setUserName('')
            setEmailAddress('')
            setBasecountryID('')
            setActiveDashboard('')
            setViewedDashboard('')
            setDashboardGrpList([]);
        } finally {
            setIsLoading(false);
        }
    };
    const toggleDeleteModal = (userID) => {
        if (isDeleteModal == true) {
            setDeleteModal(false);
            setEditClientID('')
        } else {
            setDeleteModal(true);
            setEditClientID(userID);
        }
    };
    const handleDisable = async (userID) => {
        setIsLoading(true);
        try {
            const response = await axiosDelete(DeleteActionUrl, { moduleID: 2, valueID: userID.toString() });
            if (response) {
                showToast('success', 'Successfully Deleted!');
                getClientDetails();
            } else {
                showToast('error', 'Failed to fetch user list: No data found.');
            }
        } catch (error) {
            showToast('error', `Error fetching user list:, ${error}`)
        } finally {
            setIsLoading(false);
            setDeleteModal(false); // Close the modal after the action
        }
    }
    const handleFieldChange = (e, field) => {
        const value = e.target.value
        if (field == 'user') {
            setUserName(value)
        } else if (field == 'email') {
            setEmailAddress(value)
        } else if (field == 'country') {
            const selectedCountryID = e.target.value;
            setBasecountryID(selectedCountryID);
            if (selectedCountryID > 0) {
                const updatedCountries = accessCountries.map((country) =>
                    country.code === selectedCountryID
                        ? { ...country, defaultChecked: true } // Update the matched country
                        : country
                );
                setAccessCountries(updatedCountries);
            }
        }

    };
    const [originalcountryIds, setOriginalcountryIds] = useState("");

    const handleDelete = async (dashboardGroupID, dashboardID) => {
        setIsLoading(true);
        try {
            const response = await axiosDelete(DeleteDashboardUserUrl, { deleteUserID: EditClientID, deleteADGroupID: dashboardGroupID, dashboardID: dashboardID });
            if (response) {
                showToast('success', 'Successfully Deleted!');
                // const updatedDashboards = dashboards.filter(x => Number(x.dashboardID) != Number(dashboardID));
                // setDashboards(updatedDashboards);
                // setFilteredDashboardLog(updatedDashboards);
                setActiveDashboard(response);
                setViewedDashboard(response);
            } else {
                showToast('error', 'Failed to Delete');
            }
        } catch (error) {
            showToast('error', `Error in Delete Dashboard:, ${error}`)
        } finally {
            setIsLoading(false);
            setDeleteModal(false); // Close the modal after the action
        }
    }

    const navigateCancel = () => {
        navigate('/client-user-management')
    }
    const [validate, setValidate] = useState(false);
    const validateEmail = () => {
        let check = true;
        let errmsg = "";

        if (emailAddress == "" || emailAddress == null || !is_email(emailAddress)) {
            check = false;
            errmsg = "Invalid Email";
        }
        if (!check) {
            return errmsg;
        }

        return errmsg;
    }
    const is_email = (str) => {

        var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (str.match(validRegex)) {
            return true;
        }
        else {
            return false;
        }

    }
    const compareStringIds = (string1, string2) => {
        // Split the strings into arrays
        const array1 = string1.split(",").map(Number); // Convert to numbers if needed
        const array2 = string2.split(",").map(Number);

        // Check if lengths are different
        if (array1.length !== array2.length) return true;

        // Sort both arrays
        const sortedArray1 = array1.sort((a, b) => a - b);
        const sortedArray2 = array2.sort((a, b) => a - b);

        // Compare sorted arrays
        for (let i = 0; i < sortedArray1.length; i++) {
            if (sortedArray1[i] !== sortedArray2[i]) {
                return true; // Return false if any element is different
            }
        }
        return false; // Arrays contain the same items
    };
    const handleSave = async () => {
        setValidate(true);
        let countryAccessTemp = "";
        if (accessCountries != null && accessCountries != undefined && accessCountries.length > 0) {
            accessCountries.forEach(x => {
                if (x.defaultChecked == true) {
                    countryAccessTemp = (countryAccessTemp == "") ? x.code : countryAccessTemp + "," + x.code;
                }
            })
        }
        if (userName != null && userName != "" && validateEmail() == "" && basecountryID > 0 && countryAccessTemp != "") {
            if (EditClientID > 0) {

                //country list changed?
                const countryChanged = compareStringIds(
                    countryAccessTemp,
                    originalcountryIds
                );
                try {
                    setIsLoading(true);
                    const updateUser = {
                        name: (userName == "" || userName == null || userName == undefined) ? "" : userName,
                        emailAddress: (emailAddress == null || emailAddress == undefined || emailAddress == "") ? "" : emailAddress,
                        roleID: 2,
                        basecountryID: (basecountryID != null && basecountryID != undefined && basecountryID != "0") ? Number(basecountryID) : 0,
                        updateuserID: EditClientID,
                        accessRight: null,
                        countryAccess: countryAccessTemp,
                        isAccessChanged: false,
                        isCountryChanged: countryChanged
                    };
                    const response = await axiosPost(UpdateUserDetailsUrl, updateUser);
                    if (response.code == 200) {
                        setValidate(false);
                        showToast('success', 'Successfully Updated!');
                        setTimeout(() => {
                            navigate('/client-user-management');
                        }, 1000);
                    } else {
                        showToast('error', response.responseMsg);
                        setIsLoading(false);
                    }
                }
                catch (error) {
                    console.error("Error updating user:", error);
                    setIsLoading(false);
                } finally {

                }
            }
            else {
                try {
                    setIsLoading(true);
                    const saveUser = {
                        name: (userName == "" || userName == null || userName == undefined) ? "" : userName,
                        emailAddress: (emailAddress == null || emailAddress == undefined || emailAddress == "") ? "" : emailAddress,
                        roleID: 0,
                        basecountryID: 0,
                        updateuserID: 0,
                        accessRight: null,
                        countryAccess: countryAccessTemp
                    };
                    const response = await axiosPost(InsertUserDetailsUrl, saveUser);
                    if (response.code == 200) {
                        setValidate(false);
                        showToast('success', 'Successfully Saved!');
                        setTimeout(() => {
                            navigate('/client-user-management');
                        }, 1000);
                    } else {
                        showToast('error', response.responseMsg);
                        setIsLoading(false);
                    }
                }
                catch (error) {
                    console.error("Error saving user:", error);
                    setIsLoading(false);
                } finally {

                }
            }
        }
        else {
            console.error("Mandatory fields are required");
        }
    }

    const [accessCountries, setAccessCountries] = useState([]);

    const handleAccessCountryChange = (code, checked) => {
        if (code == basecountryID && checked == false) {
            setBasecountryID("0");
        }
        if (checked == false) {
            const updatedCountries = accessCountries.map((country) =>
                country.code === code
                    ? { ...country, defaultChecked: false } // Update the matched country
                    : country
            );
            setAccessCountries(updatedCountries);
        }
        else if (checked == true) {
            const updatedCountries = accessCountries.map((country) =>
                country.code === code
                    ? { ...country, defaultChecked: true } // Update the matched country
                    : country
            );
            setAccessCountries(updatedCountries);
        }
    }
    const validateCountry = () => {
        if (accessCountries != null && accessCountries != undefined && accessCountries.length > 0) {
            if (accessCountries.filter(x => x.defaultChecked == true).length > 0) {
                return "";
            }
            else {
                return "country selection is mandatory";
            }
        }
        else {
            return "country selection is mandatory";
        }
        return "";
    }
    const formatValidUntilDate = (date, t) => {
        if (!date || date === "") return ""; // Handle empty values

        const parsedDate = moment(date, "DD-MM-YYYY HH:mm:ss", true); // 'true' ensures strict parsing
        return parsedDate.isValid() ? parsedDate.format("DD MMM YYYY") : t("common.invalidDate");
    };

    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Header />
            <Box
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#EDF2FF",
                    width: "100%",
                    marginTop: 55,
                    padding: "2rem",
                }}
            >
                <Typography className="padding-top-m text-center page-name" variant="h5" align="center" gutterBottom>
                    {TpageTitle}
                </Typography>
            </Box>

            <main
                id="main"
                style={{
                    minHeight: "100vh",
                    width: "100%",
                    padding: '40px',
                    paddingTop: '15px'
                }}
            >
                <Box>
                    <Box style={{ border: '0px' }} mb={3}>
                        <Card className="padding-top-s padding-left-base margin-bottom-m">
                            <CardContent>
                                <Typography className="box-name-tit " variant="h6" gutterBottom>
                                    {TclientDetails}
                                </Typography>

                                <Box style={{ height: '15px' }}></Box>
                                <Grid container spacing={2} mb={3}>
                                    <Grid item xs={6} sm={3}>
                                        <Box
                                            sx={{
                                                textAlign: "center",
                                                border: "1px solid #ccc",
                                                borderRadius: 1,
                                                padding: 2,
                                            }}
                                        >
                                            <Typography variant="h5" fontWeight="bold">
                                                {activeDashboard != null ? activeDashboard : 0}
                                            </Typography>
                                            <Typography>{TActiveDashboards}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Box
                                            sx={{
                                                textAlign: "center",
                                                border: "1px solid #ccc",
                                                borderRadius: 1,
                                                padding: 2,
                                            }}
                                        >
                                            <Typography variant="h5" fontWeight="bold">
                                                {viewedDashboard != null ? viewedDashboard : 0}
                                            </Typography>
                                            <Typography>{TViewedDashboards}</Typography>
                                        </Box>
                                    </Grid>

                                </Grid>
                                {/* Filters */}
                                <Grid container spacing={2} mb={3}>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            label={Tname}
                                            variant="outlined"
                                            sx={{ paddingTop: "10px", width: "93%" }}
                                            placeholder={TenterName}
                                            value={userName}
                                            onChange={(e) => handleFieldChange(e, 'user')}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validate && (userName == "" || userName == null)}
                                            helperText={(validate && (userName == "" || userName == null)) ? t('user_mgmt.pleaseEnterName') : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            label={Temail}
                                            variant="outlined"
                                            sx={{ paddingTop: "10px", width: "93%" }}
                                            placeholder={TenterEmail}
                                            value={emailAddress}
                                            onChange={(e) => handleFieldChange(e, 'email')}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validate && validateEmail() != ""}
                                            helperText={(validate && validateEmail() != "") ? t('user_mgmt.pleaseEnterEmail') : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            label={Tcountry}
                                            variant="outlined"
                                            select
                                            fullWidth
                                            sx={{ paddingTop: "10px", width: "93%" }}
                                            placeholder={TaddCountry}
                                            value={basecountryID}
                                            onChange={(e) => handleFieldChange(e, 'country')}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={validate && (basecountryID == "" || basecountryID == null || basecountryID == "0")}
                                            helperText={(validate && (basecountryID == "" || basecountryID == null || basecountryID == "0")) ? t('user_mgmt.pleaseSelectCountry') : ""}
                                        >
                                            {clientCountryFillList.map((role) => (
                                                <MenuItem key={role.code} value={role.code}>
                                                    {role.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6" className="margin-bottom-l box-name-tit margin-top-base" textTransform="uppercase">
                                            {t('user_mgmt.accessibleCountry')} <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </Typography>
                                        {accessCountries.map((country, index) => (
                                            <FormControl className="margin-top-s" style={{ marginLeft: '5px' }} key={index} error={validate && validateCountry() !== ""}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={country.defaultChecked}
                                                        onChange={(e) => handleAccessCountryChange(country.code, e.target.checked)}
                                                    />}
                                                    label={country.description}
                                                />
                                                {index === accessCountries.length - 1 && validate && validateCountry() !== "" && (
                                                    <FormHelperText>
                                                        {t('user_mgmt.pleaseSelectOneCountry')}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        ))}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box className="site-border" style={{ padding: '23px' }}>
                        <Typography className="box-name-tit" variant="h6" gutterBottom>
                            {TListofAccessibleDashboards}
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="padding-left-none">{Tdashboard}</TableCell>
                                        <TableCell>{TGroup}</TableCell>
                                        <TableCell>{TAccessRight}</TableCell>
                                        <TableCell>{TvalidTill}</TableCell>
                                        <TableCell align="center">{TAction}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedDashboardGrpList?.length > 0 ? paginatedDashboardGrpList.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="padding-left-none">{item.dashboardName}</TableCell>
                                            <TableCell>{item.dashboardGroup}</TableCell>
                                            <TableCell>{item.accessRight}</TableCell>
                                            <TableCell>{formatValidUntilDate(item.validUntil, t)}</TableCell>
                                            <TableCell align="center">
                                                {/* <IconButton color="error" >
                              <DeleteIcon />
                            </IconButton> */}

                                                <Button className="" sx={{ margin: '0px !important' }}>
                                                    <Tooltip title="Remove">
                                                        <Icon className='fa-light fa-trash-can font-size-base text-primary btn-list' onClick={() => handleDelete(item.dashboardGroupID, item.dashboardID)} />
                                                    </Tooltip>
                                                </Button>


                                            </TableCell>
                                        </TableRow>
                                    )) : (
                                        <TableRow>
                                            <TableCell colSpan={5}>{TnoRecordFound}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CommonPagination
                            totalItems={totalItems}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                        />
                    </Box>
                </Box>
                {/* Buttons */}
                <Box display="flex" justifyContent="center" mt={2} gap={2}>
                    <Button variant="contained" color="primary" onClick={() => handleSave()}>
                        {Tsave}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => navigateCancel()}>
                        {Tcancel}
                    </Button>
                    <Button variant="contained" color="error" onClick={() => toggleDeleteModal(EditClientID)}>
                        {Tdisable}
                    </Button>
                </Box>

            </main>

            <Footer />
            <Dialog
                open={isDeleteModal}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                        padding: "16px",
                        width: "400px",
                        textAlign: "center",
                    },
                }}
            >
                <DialogTitle>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        style={{ color: "#000959", marginBottom: "8px" }}
                    >
                        {TdeleteClient}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                        {TdeleteClientConfirm}
                    </Typography>
                </DialogContent>

                <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                    <Button
                        variant="outlined"
                        startIcon={<Cancel />}
                        onClick={() => toggleDeleteModal('')}
                        style={{
                            color: "#000959",
                            borderColor: "#000959",
                            textTransform: "none",
                            borderRadius: "6px",
                        }}
                    >
                        {Tcancel}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleDisable(EditClientID)}
                        startIcon={<Delete />}
                        style={{
                            backgroundColor: "#000959",
                            color: "#FFFFFF",
                            textTransform: "none",
                            borderRadius: "6px",
                        }}
                    >
                        {Tdisable}
                    </Button>
                </DialogActions>
            </Dialog>
            {ToastComponent}
            <div style={{ height: 50 }} />
        </>
        //  )}
        //</>
    );
};

export default withTranslation()(ClientUserManage);
