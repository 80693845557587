import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../components/Loader";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
    Icon,
    Box, Button, TextField, IconButton, InputAdornment, Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Grid, Tabs, Tab, Card, CardMedia, CardContent, CardActions, useTheme, Dialog,
    DialogTitle, DialogContent, DialogActions, MenuItem, Tooltip,
    CircularProgress,
    Link
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Search, List, GridView, StarOutline, Star, Share, Delete, Edit, Close, Save, Cancel, Margin } from "@mui/icons-material";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withTranslation, useTranslation } from "react-i18next";
import { GetDashboardListSearchUrl, InsertUpdateDashboardFavUrl, DeleteActionUrl, GetDashboardUsersUrl, GetShareDashboardUrl, dashboardRoleActionID, InsertShareDashboardurl, SearchUserAndADGroupUrl, SendMailNotificationUrl, GetUserShareAccessUrl } from "../../utils/constants";
import CommonPagination from "../../components/CommonPagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { axiosDelete, axiosGet, axiosPost } from "../../utils/Axios";
import { useToast } from '../../components/toast';
import { useFillList } from "../../components/fillList";
import _, { set } from 'lodash';

const DashboardList = (props) => {
    const getNextYearDate = () => {
        const today = new Date();
        const nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
        return nextYear.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };
    const theme = useTheme();
    const navigate = useNavigate();
    //toast
    const { ToastComponent, showToast } = useToast();
    //loader
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isloadRender, setIsloadRender] = useState(false);

    //pop
    const [isFavModal, setFavModal] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    //state
    const [dashboardID, setDashboardID] = useState('');
    const [dashboardGroupID, setDashboardGroupID] = useState('');
    const [deleteUserID, setDeleteUserID] = useState('');
    const [isFav, setIsFav] = useState(false);
    const [dashboardList, setDashboardList] = useState([]);
    const [canShareClient, setCanShareClient] = useState(false);
    const [canShareColliers, setCanShareColliers] = useState(false);
    const [dashboardGroupList, setdashboardGroupList] = useState([]);
    const [dashboardName, setDashboardName] = useState("");
    const [clientName, setClientName] = useState("");
    const [viewType, setViewType] = useState("list");
    const [tabValue, setTabValue] = useState(0);
    const [userID, setUserID] = useState(JSON.parse(localStorage.getItem("userInfo"))?.userID);
    const [roleID, setRoleID] = useState(JSON.parse(localStorage.getItem("userInfo"))?.roleID);
    // Calculate Paginated Records
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(1);
    const [filteredDashboards, setFilteredDashboards] = useState(dashboardList)
    //share modal
    const [isRLS, setIsRLS] = useState(false)
    const [isRemoveUserModal, setRemoveUserModal] = useState(false);
    const [removeUserIndex, setRemoveUserIndex] = useState('');
    const [GroupnUserListErr, setGroupnUserListErr] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [isUserLoading, setIsUserLoading] = useState(false);

    const [errors, setErrors] = useState(null)
    //invite
    const [isInvite, setIsInvite] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteValidUntil, setInviteValidUntil] = useState(getNextYearDate());
    const [inviteRls, setInviteRls] = useState('');
    //fillList
    const [DashboardUserData, setDashboardUserData] = useState([]);
    const [DashboardRoleFillList, setDashboardRoleFillList] = useState([]);
    const roleFillList = useFillList(dashboardRoleActionID, "", ""); // Action ID 4
    const [GroupnUserList, setGroupnUserList] = useState([]);
    //pagination
    const [shareCurrentPage, setCurrentPageShare] = useState(1);
    //cards
    const cardItemsPerSlide = 4;
    const startIndexCards = (currentSlide - 1) * cardItemsPerSlide;
    const carousalDashboards = filteredDashboards?.slice(startIndexCards, startIndexCards + cardItemsPerSlide);
    //list
    const listItemsPerPage = 10;
    const totalItems = filteredDashboards?.length;
    const startIndex = (currentPage - 1) * listItemsPerPage;
    const paginatedDashboards = filteredDashboards?.slice(startIndex, startIndex + listItemsPerPage);
    //translations
    const { t } = useTranslation();
    //common
    const TAction = t("common.Action");
    const TnoRecordFound = t("common.noRecordFound");
    const TnoUserFound = t("common.noUserFound");
    const Tcancel = t("common.Cancel");
    const Tadd = t("common.Add");
    const Tsubmit = t("common.Submit");
    const TDashboard = t("common.Dashboard");
    const TInvite = t("common.Invite");
    const TnotAvailable = t("common.notAvailable");
    const Tshare = t("common.Share");
    const Tdelete = t("common.Delete");
    //list
    const TmyDashboards = t("dashboardList.myDashboards");
    const TaddDashboardButton = t("dashboardList.addDashboardButton");
    const TsearchForDashboard = t("dashboardList.searchForDashboard");
    const TnoDashboardGroups = t("dashboardList.noDashboardGroups");
    const Tclient = t("dashboardList.dashboardTable.client");
    const TSharedBy = t("dashboardList.dashboardTable.SharedBy");
    const TValidUntil = t("dashboardList.dashboardTable.ValidUntil");
    //dashboardShare
    const TgroupNusers = t("dashboardAddUpdate.dashboardUdt.groupNusers");
    const TinviteUsers = t("dashboardAddUpdate.dashboardUdt.inviteUsers");
    const TnameUdt = t("dashboardAddUpdate.dashboardUdt.nameUdt");
    const TemailUdt = t("dashboardAddUpdate.dashboardUdt.emailUdt");
    const TroleUdt = t("dashboardAddUpdate.dashboardUdt.roleUdt");
    const TguestEmailUdt = t("dashboardAddUpdate.dashboardUdt.guestEmailUdt");
    const TguestEmailNoteUdt = t("dashboardAddUpdate.dashboardUdt.guestEmailNoteUdt");
    const TAddAsUdt = t("dashboardAddUpdate.dashboardUdt.AddAsUdt");
    const TvalidUntilUdt = t("dashboardList.dashboardTable.ValidUntil");
    const TRLS = t("dashboardAddUpdate.dashboardFields.RLS");

    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (debouncedValue) {
            SearchUserAndADGroup(debouncedValue);
        } else {
            if (!userInfo) {
                navigate("/login/root-data");
                return;
            } else {
                getDashboardList();
                GetUserShareAccess();
            }
            if (roleFillList.data) {
                setDashboardRoleFillList(roleFillList.data);
            }
        }
    }, [roleFillList.data, debouncedValue]);

    const getDashboardList = async (searchTerm = "") => {
        const endpoint = `?dashboardName=${searchTerm}`
        isloadRender ? setIsLoading(false) : setIsLoading(true)
        try {
            const response = await axiosGet(`${GetDashboardListSearchUrl}${endpoint}`);
            console.log(response);
            if (response) {
                console.log(response)
                setdashboardGroupList(response.dashboardGroupList);
                setDashboardList(response.dashboardList);
                setFilteredDashboards(response.dashboardList);
                setCurrentPage(1)
                setTimeout(() => {
                    setIsLoading(false)
                    setIsloadRender(false)
                }, 500);
            } else {
                setIsLoading(false)
                setIsloadRender(false)
                console.error("Failed to fetch dashboards: No data found.");
            }
        } catch (error) {
            setIsloadRender(false)
            setIsLoading(false)
            console.error("Error fetching dashboards:", error);
        }
    };

    const handleSearch = (query) => {
        if (query.length >= 3) {
            setIsloadRender(true)
            let filtered = dashboardList.filter((dashboard) =>
                dashboard.title.toLowerCase().includes(query.toLowerCase())
            );

            if (filtered.length === 0) {
                filtered = dashboardList.filter((dashboard) =>
                    dashboard.clientName.toLowerCase().includes(query.toLowerCase())
                );
            }

            setFilteredDashboards(filtered);
            console.log("Filtered Dashboards:", filtered);
            //setIsloadRender(true)

            setIsloadRender(false)
            setIsLoading(false)
        }
        else {
            getDashboardList();
        }
    };
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    const debouncedSearch = debounce(handleSearch, 200);
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setDashboardName(inputValue);
        debouncedSearch(inputValue);
    };


    const toggleFavourite = (dashboardID, isFav, dashboardName) => {
        if (isFavModal == true) {
            setFavModal(false);
            setDashboardID('');
            setIsFav(false);
            setDashboardName('');
        } else {
            setFavModal(true);
            setDashboardID(dashboardID);
            setIsFav(isFav);
            setDashboardName(dashboardName);
        }
    };

    const submitFavourite = async (dashboardID, isFav) => {
        const endpoint = `?dashboardID=${dashboardID}&isFav=${isFav}`;
        try {
            const response = await axiosPost(`${InsertUpdateDashboardFavUrl}${endpoint}`);
            if (response) {
                showToast('success', 'Successfully updated favourite status!');
                setIsloadRender(true)
                getDashboardList();
                setDeleteModal(false);
                setDashboardID('');
                setDashboardName('');
                setDashboardGroupID('');
                setDeleteUserID('');
            } else {
                showToast('error', 'Failed to update favourite status: No data found.');
                console.error("Failed to fetch dashboards: No data found.");
            }
        } catch (error) {
            showToast('error', 'Error updating favourite status.');
            console.error("Error fetching dashboards:", error);
        } finally {
            setFavModal(false); // Close the modal after the action
        }
    };

    const toggleDeleteModal = (dashboardID, dashboardName, dashboardGroupID, deleteUserID) => {
        if (isDeleteModal == true) {
            setDeleteModal(false);
            setDashboardID('');
            setDashboardName('');
            setDashboardGroupID('');
            setDeleteUserID('')
        } else {
            setDeleteModal(true);
            setDashboardID(dashboardID);
            setDashboardName(dashboardName);
            setDashboardGroupID(dashboardGroupID);
            setDeleteUserID(1)
        }
    };
    const toggleShareModal = (dashboardID, isRLS) => {
        if (shareModal == true) {
            setShareModal(false);
            setDashboardID('');
            setIsRLS(0)
            setGroupnUserList([])
        } else {
            setShareModal(true);
            setDashboardID(dashboardID);
            setIsRLS(isRLS)
            // GetDashboardUsers()
            GetShareDashboard(dashboardID)
        }
    };
    const handleShare = async () => {
        const invalidUsers = GroupnUserList.filter(user => {
            const validUntilDate = user.validUntil;
            return !validUntilDate || isNaN(Date.parse(validUntilDate));
        });

        if (invalidUsers.length > 0) {
            showToast('error', 'Some users have invalid or missing validUntil dates.');
            return;
        }
        const endpoint = `?dashboardID=${dashboardID}`;
        const updatedGroupnUserList = GroupnUserList.map(user => ({
            adGroup: user.adGroup,
            adGroupID: user.adGroupID !== "" ? user.adGroupID : 0,
            azureGroupID: user.azureGroupID !== "" ? user.azureGroupID : 0,
            azureGroup: user.azureGroup,
            dashboardRoleID: user.dashboardRoleID,
            displayName: user.displayName,
            emailAddress: user.emailAddress,
            userID: user.userID,
            dashboardID: user.dashboardID,
            dashboardRLS: user.rlsName != null ? user.rlsName : '',
            dashboardRole: user.dashboardRole,
            validUntil: user.validUntil,
            isCreatedBy: user.isCreatedBy,
        }));

        setIsSearchLoading(true);
        try {
            const response = await axiosPost(`${InsertShareDashboardurl}${endpoint}`, updatedGroupnUserList);
            if (response) {
                showToast('success', 'Successfully Shared!');
                getDashboardList();
                setIsSearchLoading(false);
            } else {
                setIsSearchLoading(false);
                console.error("Failed to Share dashboards.");
                showToast('error', 'Failed to Share dashboards: No data found.');
            }
        } catch (error) {
            setIsSearchLoading(false);
            console.error("Error Sharing Dashboard:", error);
            showToast('error', `Error Sharing Dashboard: ${error}`);
        } finally {
            setIsSearchLoading(false);
            setShareModal(false);
        }
    };

    const handleDelete = async (dashboardID) => {
        debugger;
        try {
            const response = await axiosDelete(DeleteActionUrl, { moduleID: 4, valueID: dashboardID.toString() });
            if (response) {
                showToast('success', 'Successfully Deleted!');
                setIsloadRender(true)
                getDashboardList()
                setDeleteModal(false);
                setDashboardID('');
                setDashboardName('');
                setDashboardGroupID('');
                setDeleteUserID('')
            } else {
                showToast('error', 'Failed to fetch user list: No data found.');
            }
        } catch (error) {
            showToast('error', 'Failed to fetch dashboards: No data found.');
        } finally {
            setDeleteModal(false); // Close the modal after the action
        }
    }
    //const handleDelete = async (deleteUserID, dashboardGroupID, dashboardID) => {
    //    console.log(deleteUserID)
    //    const endpoint = `?deleteUserID=${deleteUserID}&deleteADGroupID=${dashboardGroupID}&dashboardID=${dashboardID}`
    //    console.log('endpoint', endpoint)
    //    try {
    //        const response = await axiosDelete(`${DeleteDashboardUserUrl}${endpoint}`);
    //        if (response) {
    //            showToast('success', 'Successfully Deleted!');
    //            getDashboardList()
    //            setDeleteModal(false);
    //            setDashboardID('');
    //            setDashboardName('');
    //            setDashboardGroupID('');
    //            setDeleteUserID('')
    //        } else {
    //            console.error("Failed to fetch dashboards: No data found.");
    //            showToast('error', 'Failed to fetch dashboards: No data found.');
    //        }
    //    } catch (error) {
    //        console.error("Error fetching dashboards:", error);
    //        showToast('error', `Error fetching dashboards:, ${error}`)
    //    } finally {
    //        setDeleteModal(false);
    //    }
    //}

    const handleTabClick = (group, index) => {
        if (group.dashboardGroup === "All") {
            setFilteredDashboards(dashboardList);
        } else {
            setFilteredDashboards(dashboardList.filter((item) => item.dashboardGroupID == group.dashboardGroupID));
        }
        setTabValue(index)
        setCurrentPage(1);
    };
    const handleAddClick = () => {
        navigate("/dashboardAdd");
    };
    const navigateUpdate = (dashboardID) => {
        navigate("/dashboardAdd", { state: { dashboardID } });
    }
    const navigateLoadDashboard = (dashboardID) => {
        navigate("/loadDashboard", { state: { dashboardID } })
    }
    //share modal
    const SearchUserAndADGroup = async (searchUserKeyword) => {
        setIsUserLoading(true)
        try {
            setTimeout(() => {
                setDashboardUserData([]);
            }, 200);
            const response = await axiosGet(`${SearchUserAndADGroupUrl}?keyword=${searchUserKeyword}`);
            console.log(response);
            if (response) {
                const updatedData = response.map(row => ({
                    ...row,
                    isAdded: 0
                }));
                updatedData.forEach(x => x.validUntil = getNextYearDate());
                setDashboardUserData(updatedData);
                setIsUserLoading(false)
            } else {
                console.error("Failed to search Users: No data found.");
                setIsUserLoading(false)
            }
        } catch (error) {
            setIsUserLoading(false)
            console.error("Error Search Users:", error);
        }
    };

    const GetDashboardUsers = async () => {
        setIsUserLoading(true)
        try {
            const response = await axiosGet(GetDashboardUsersUrl);
            console.log(response);
            if (response?.value) {
                // Map through the response data and add isAdded:0 to each row
                const updatedData = response.value.map(row => ({
                    ...row,
                    isAdded: 0
                }));
                setDashboardUserData(updatedData);
                setIsUserLoading(false)
            } else {
                setIsUserLoading(false)
                console.error("Failed to fetch GetDashboardUsers: No data found.");
            }
        } catch (error) {
            console.error("Error fetching GetDashboardUsers:", error);
            setIsUserLoading(false)
        }
    };

    const GetUserShareAccess = async () => {
        try {
            const response = await axiosGet(GetUserShareAccessUrl);
            console.log(response);
            if (response) {
                setCanShareClient(response.canShareClient);
                setCanShareColliers(response.canShareColliers);
            } else {
                console.error("Failed to fetch GetDashboardUsers: No data found.");
            }
        } catch (error) {
            console.error("Error fetching GetDashboardUsers:", error);
        }
    };
    const GetShareDashboard = async (dashboardID) => {
        const endpoint = `?dashboardID=${dashboardID}`
        try {
            const response = await axiosGet(`${GetShareDashboardUrl}${endpoint}`);
            console.log(response);
            if (response) {
                const existingUsers = response.map(user => ({
                    ...user,
                    isDeleted: user.isDeleted !== undefined ? user.isDeleted : false,
                    rlsName: user.dashboardRLS != '' ? user.dashboardRLS : '',
                    isMailView: true,
                }));

                if (existingUsers != null && existingUsers.length > 0) {
                    existingUsers.forEach(x => {
                        if (x.validUntil != null && x.validUntil != undefined) {
                            x.validUntil = moment(x.validUntil, 'DD-MM-YYYY').format('YYYY-MM-DD');
                        }
                    })
                }
                setGroupnUserList(existingUsers);
            } else {
                console.error("Failed to fetch GetDashboardUsers: No data found.");
            }
        } catch (error) {
            console.error("Error fetching GetDashboardUsers:", error);
        }
    };

    //add to group user
    const AddtoGroupUsers = (user) => {
        console.log('userExist', user)
        if (user.validUntil == null || user.validUntil == '') {
            showToast('error', 'Please select valid until date');
        } else {
            const dashboardRoleID = user.email && user.email.includes('@colliers') ? 4 : 2;
            const newUser = {
                displayName: user.displayName ? user.displayName : user.firstName,
                emailAddress: user.userPrincipalName ? user.userPrincipalName : user.email,
                userID: user.userID || '', // Ensure UserID is defined
                validUntil: user.validUntil,
                rlsName: '',
                azureGroupID: '',
                azureGroupName: '',
                dashboardRoleID: dashboardRoleID, // Set dashboardRoleID based on userPrincipalName
                adGroupID: "",
                isDeleted: false
            };
            const existingUser = GroupnUserList.find(existingUser => existingUser.emailAddress.toLowerCase() === newUser.emailAddress.toLowerCase());
            if (existingUser) {
                if (existingUser.isDeleted) {
                    const updatedUserList = GroupnUserList.map(existingUserItem =>
                        existingUserItem.emailAddress.toLowerCase() === newUser.emailAddress.toLowerCase()
                            ? { ...existingUserItem, isDeleted: false }
                            : existingUserItem
                    );
                    setGroupnUserList(updatedUserList);

                    showToast("success", "User Added Successfully");
                } else {
                    showToast("success", "User Already Exists");
                }
            } else {
                setGroupnUserList(prevList => [...prevList, newUser]);
                showToast("success", "User Added Successfully");

            }
            setGroupnUserListErr('');
        }

    };

    const inviteSubmit = () => {
        console.log(inviteValidUntil, inviteRls, inviteEmail)
        if (inviteEmail == '') {
            showToast("error", "Kindly Enter Email Address");
        } else if (inviteValidUntil == '') {
            showToast("error", "Kindly Enter Valid Until Date");
        } else if (isRLS == 1 && inviteRls == '') {
            showToast("error", "Kindly Enter RLS Name");
        } else {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const emails = inviteEmail.split(',').map(email => email.trim()).filter(email => email);
            const duplicateEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
            if (duplicateEmails.length > 0) {
                showToast("error", `Duplicate emails found: ${[...new Set(duplicateEmails)].join(', ')}`);
            } else {
                const invalidEmails = emails.filter(email => !emailRegex.test(email));

                if (invalidEmails.length > 0) {
                    showToast("error", `The following emails are invalid: ${invalidEmails}`);
                } else {
                    const newUsers = emails.map(email => {
                        const dashboardRoleID = email.includes('@colliers') ? 4 : 2;

                        return {
                            userID: "",
                            azureGroupID: "",
                            azureGroupName: "",
                            emailAddress: email,
                            validUntil: inviteValidUntil,
                            dashboardRoleID: dashboardRoleID, // Set dashboardRoleID based on email
                            rlsName: inviteRls,
                            adGroupID: "",
                            isDeleted: false
                        };
                    });
                    const existingEmails = GroupnUserList.map(user => user.emailAddress);
                    const duplicateNewUsers = newUsers.filter(user => existingEmails.includes(user.emailAddress));

                    if (duplicateNewUsers.length > 0) {
                        duplicateNewUsers.forEach(user => {
                            const existingUser = GroupnUserList.find(existing => existing.emailAddress === user.emailAddress);

                            if (existingUser) {
                                if (existingUser.isDeleted) {
                                    existingUser.isDeleted = false;
                                    showToast('success', 'User Added Successfully');
                                } else {
                                    showToast('info', `User ${user.emailAddress} already added.`);
                                }
                            } else {
                                showToast("success", "User Already Exists");
                            }
                        });
                    } else {
                        setGroupnUserList(prevList => [...prevList, ...newUsers]);
                        showToast("success", "User Added Successfully");

                    }
                    setGroupnUserListErr('');
                    setInviteEmail('');
                    setInviteRls('');
                    setIsInvite(false);
                }
            }
        }
    };
    const handleRemovefromGroupList = (email) => {
        const updatedList = GroupnUserList.map((item, i) =>
            item.emailAddress === email ? { ...item, isDeleted: true } : item
        );
        setGroupnUserList(updatedList);
        setRemoveUserModal(false);
        setRemoveUserIndex('');
        showToast("success", "User Removed Successfully")
    };
    const toggleRemoveUser = (index) => {
        if (isRemoveUserModal == true) {
            setRemoveUserModal(false);
            setRemoveUserIndex('')
        } else {
            setRemoveUserIndex(index)
            setRemoveUserModal(true);
        }
    };
    const sendMail = async (dashboardID, userID) => {
        const endpoint = `?dashboardID=${dashboardID}&toUserID=${userID}`;
        setIsSearchLoading(true)
        try {
            const response = await axiosPost(`${SendMailNotificationUrl}${endpoint}`);
            if (response) {
                setIsSearchLoading(false)
                showToast('success', 'Mail Sent Successfully!');
            } else {
                showToast('error', 'Failed to Send Mail.');
                setIsSearchLoading(false)
                console.error("Failed to Send Mail.");
            }
        } catch (error) {
            showToast('error', 'Error Sending Mail.');
            setIsSearchLoading(false)
            console.error("Error Sending Mail:", error);
        }
    }
    const handleChangeSearch = (e) => {
        setInputValue(e.target.value);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(
            setTimeout(() => {
                if (e.target.value.length >= 3) {
                    setDebouncedValue(e.target.value)
                }
                else if (e.target.value <= 3) {
                    // GetDashboardUsers()
                    setDashboardUserData([])
                }
            }, 100)
        );
    };
    const handleDate = useCallback(
        _.debounce((event) => {
            debugger;
            const today = moment().format('YYYY-MM-DD');
            const selectedDate = moment(event, 'YYYY-MM-DD');

            if (selectedDate.isBefore(today, 'day')) {
                //setErrors(prev => ({
                //    ...prev,
                //    [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
                //}));
                showToast('error', "You can not select past date");
            } else {
                //setErrors(prev => ({
                //    ...prev,
                //    [`${data.userID}-validUntil`]: false
                //}));
                setInviteValidUntil(event);
            }
        }, 300), []);
    const handleValidUntil = async (e, i, data) => {
        debugger;
        const updatedList = [...DashboardUserData];
        updatedList[i] = {
            ...updatedList[i],
            validUntil: e.target.value
        };


        const today = moment().format('YYYY-MM-DD');
        const selectedDate = moment(e.target.value, 'YYYY-MM-DD');

        if (selectedDate.isBefore(today, 'day')) {
            //setErrors(prev => ({
            //    ...prev,
            //    [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
            //}));
            showToast('error', "You can not select past date");
        } else {
            //setErrors(prev => ({
            //    ...prev,
            //    [`${data.userID}-validUntil`]: false
            //}));
            setDashboardUserData(updatedList);
        }
    }
    const handleValidDateChangeEvent = async (e, index, data) => {
        const updatedList = [...GroupnUserList];
        updatedList[index] = {
            ...updatedList[index],
            validUntil: e.target.value
        };
        const today = moment().format('YYYY-MM-DD');
        const selectedDate = moment(e.target.value, 'YYYY-MM-DD');

        if (selectedDate.isBefore(today, 'day')) {
            setErrors(prev => ({
                ...prev,
                [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
            }));
            showToast('error', "You can not select past date");
        } else {
            setErrors(prev => ({
                ...prev,
                [`${data.userID}-validUntil`]: false
            }));
            setGroupnUserList(updatedList);
        }
    }
    const userAccessRights = JSON.parse(localStorage.getItem("userInfo"))?.userAccessRight;
    const hasAccessRightID1 = userAccessRights && userAccessRights.some(access => access.accessRightID === 1);


    //render
    return (
        <>
            <Header />
            {isLoading ? <Loader /> : <></>}
            <Box className="margin-top-xxl padding-top-s padding-bottom-s space-x-base position-relative full-width text-align-center">
                <Box></Box>
                <Typography variant="h5" className=" padding-top-xxl padding-bottom-l text-center page-name padding-bottom-m background-primary-pale">
                    {TmyDashboards}
                </Typography>
                {hasAccessRightID1 ? <>
                    <Button
                        className="add-dashboard-btn padding-right-s padding-left-s font-size-xs text-uppercase margin-right-m padding-right-base padding-left-base padding-top-s padding-bottom-s border-radius-soft"
                        variant="outlined"
                        startIcon={<i className="fa fa-plus margin-right-xs" />}
                        onClick={handleAddClick}
                    >
                        {TaddDashboardButton}
                    </Button>
                </> : <></>}
            </Box>

            <main id="main" className="padding-left-m padding-right-m">
                <Box>
                    <section className="section page-body">
                        <Box className="container">
                            <Grid container className="" >
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" className="margin-bottom-base margin-top-s" justifyContent="space-between">
                                        {/* Left buttons */}
                                        <Box style={{ display: 'flex' }}>
                                            <Button
                                                className={`abc1 bor-radi ${viewType === "list" ? "list-grid" : ""}`}
                                                variant={viewType === "list" ? "contained" : "outlined"}
                                                startIcon={<List />}
                                                onClick={() => setViewType("list")}
                                            >
                                            </Button>
                                            <Button
                                                className={`abc1 bor-radi ${viewType === "card" ? "list-grid" : ""}`}
                                                variant={viewType === "list" ? "outlined" : "contained"}
                                                startIcon={<GridView />}
                                                onClick={() => setViewType("card")}
                                            >
                                            </Button>
                                        </Box>

                                        {/* Tabs section */}
                                        <Box>
                                            <Tabs
                                                className="margin-left-base out-border tabs-width"
                                                value={tabValue}
                                                onChange={(_, index) => handleTabClick(dashboardGroupList[index], index)}
                                                aria-label="navigation tabs"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                sx={{
                                                    "& .MuiTabs-indicator": {
                                                        display: "none",
                                                    },
                                                    "& .MuiTabs-scrollButtons": {
                                                        display: "flex",
                                                    },
                                                    
                                                }}
                                            >
                                                {dashboardGroupList?.length > 0 ? (
                                                    dashboardGroupList.map((data, index) => ( // Limit to showing 4 tabs
                                                        <Tab className="list-grid-btn"
                                                            key={index}
                                                            label={`${data.dashboardGroup} (${data.counts})`}
                                                            value={index}
                                                            onClick={() => handleTabClick(data, index)}
                                                            sx={{
                                                               
                                                                '&.MuiButton-contained': {
                                                                    backgroundColor: '#1976d2',
                                                                    color: '#fff',
                                                                    '&:hover': {
                                                                        backgroundColor: '#115293',
                                                                    },
                                                                },
                                                                '&.MuiButton-outlined': {
                                                                    color: '#1976d2',
                                                                    borderColor: '#1976d2',
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    ))
                                                ) : (
                                                    <Box>{TnoDashboardGroups}</Box>
                                                )}
                                            </Tabs>
                                        </Box>

                                        <Box ml="auto">
                                            <TextField
                                                className="margin-left-m"
                                                variant="outlined"
                                                size="large"
                                                placeholder={TsearchForDashboard}
                                                value={dashboardName}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search className="dashboard-search-icon" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>

                                {viewType === "list" ? (
                                    <>
                                        {isloadRender ? <Loader /> : <>
                                            <TableContainer component={Paper} className="list-height">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {console.log(roleID)}
                                                            {roleID === 2 ? <></> : (
                                                                <TableCell>{Tclient}</TableCell>
                                                            )}
                                                            <TableCell>{TDashboard}</TableCell>
                                                            <TableCell>{TSharedBy}</TableCell>
                                                            <TableCell>{TValidUntil}</TableCell>
                                                            <TableCell align="center">{TAction}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {paginatedDashboards?.length > 0 ? (
                                                            paginatedDashboards?.map((dashboard, index) => (
                                                                <TableRow key={index}>
                                                                    {roleID === 2 ? <></> : (
                                                                        <TableCell>
                                                                            <Link className="list-grid-sty" onClick={() => navigateLoadDashboard(dashboard.dashboardID)}>{dashboard.clientName}</Link>
                                                                        </TableCell>)}
                                                                    {roleID === 2 ? (
                                                                        <TableCell> <Link className="list-grid-sty" onClick={() => navigateLoadDashboard(dashboard.dashboardID)}>{dashboard.title}</Link></TableCell>
                                                                    ) : (<TableCell>{dashboard.title}</TableCell>)}
                                                                    <TableCell>{dashboard.sharedBy}</TableCell>
                                                                    <TableCell>{moment(dashboard.validUntil, "DD-MM-YYYY").isValid()
                                                                        ? moment(dashboard.validUntil, "DD-MM-YYYY").format("DD MMM YYYY")
                                                                        : ""}</TableCell>
                                                                    <TableCell align="center">
                                                                        <Box display="flex" justifyContent="center">
                                                                            <Tooltip title={dashboard.isFav ? "Remove from Favourites" : "Add to Favourites"}>
                                                                                <IconButton className="space-btn" onClick={() => {
                                                                                    if (dashboard.isFav == true) {
                                                                                        toggleFavourite(dashboard.dashboardID, !dashboard.isFav, dashboard.title)
                                                                                    } else {
                                                                                        submitFavourite(dashboard.dashboardID, !dashboard.isFav)
                                                                                    }
                                                                                }
                                                                                }>
                                                                                    {dashboard.isFav ? <Star /> : <StarOutline />}
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {dashboard.canShare == true ? (
                                                                                <Button className="padding-left-s margin-left-s padding-right-xs margin-x-none margin-y-none" sx={{ margin: '0px !important' }}>
                                                                                    <Tooltip title="Share">
                                                                                        <Icon className='fa fa-share text-primary font-size-base  text-primary ' onClick={() => toggleShareModal(dashboard.dashboardID, dashboard.isRLS)} />
                                                                                    </Tooltip>
                                                                                </Button>) : <></>}
                                                                            {dashboard.canDelete == true ? (
                                                                                <Button className="padding-left-s margin-left-s padding-right-xs margin-x-none margin-y-none" sx={{ margin: '0px !important' }}>
                                                                                    <Tooltip title="Remove">
                                                                                        <Icon className='fa-light fa-trash-can font-size-base  text-primary ' onClick={() => toggleDeleteModal(dashboard.dashboardID, dashboard.title, dashboard.dashboardGroupID, dashboard.userID)} />
                                                                                    </Tooltip>
                                                                                </Button>) : <></>}
                                                                            {dashboard.canEdit == true ? (
                                                                                <Button className="padding-left-s margin-left-s padding-right-xs margin-x-none margin-y-none" sx={{ margin: '0px !important' }}>
                                                                                    <Tooltip title="Edit">
                                                                                        <Icon className='fa-light fa-pen-to-square font-size-base  text-primary ' onClick={() => navigateUpdate(dashboard.dashboardID)} />
                                                                                    </Tooltip>
                                                                                </Button>
                                                                            ) : <></>}
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))) : (
                                                            <TableCell colSpan={5}>{TnoRecordFound}</TableCell>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <CommonPagination
                                                totalItems={totalItems}
                                                currentPage={currentPage}
                                                onPageChange={setCurrentPage}
                                            />
                                        </>}
                                    </>

                                ) : (
                                    <> {isloadRender ? <Loader /> : <>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={20}
                                            slidesPerView={4}
                                            navigation
                                            pagination={{ clickable: true }}
                                            breakpoints={{
                                                1024: { slidesPerView: 4 },
                                                768: { slidesPerView: 3 },
                                                480: { slidesPerView: 2 },
                                                0: { slidesPerView: 1 },
                                            }}
                                            style={{ padding: '3rem' }}
                                            sx={{
                                                "--swiper-navigation-color": theme.palette.primary.main,
                                                "--swiper-pagination-color": theme.palette.primary.main,
                                            }}
                                        >
                                            {filteredDashboards?.length > 0 ? (
                                                filteredDashboards?.map((dashboard, index) => (
                                                    <SwiperSlide sx={{ width: "100%" }} key={index}>
                                                        <Card className="cart-view padding-y-xs padding-x-xs border-radius-soft"
                                                            sx={{
                                                                boxShadow: theme.shadows[3],
                                                                "&:hover": { boxShadow: theme.shadows[6] },
                                                            }}
                                                        >
                                                            <a style={{ textDecoration: "none" }}>
                                                                {dashboard.imagePath != "" ? <>
                                                                    <CardMedia className="single-cart-view border-radius-soft"
                                                                        component="img"
                                                                        image={dashboard.imagePath != "" ? dashboard.imagePath : ""}
                                                                        alt={dashboard.imagePath != "" ? `${dashboard.clientName} - ${dashboard.dashboardName}` : ''}
                                                                        
                                                                    />
                                                                </> : <>
                                                                    <Box className="border-radius-soft single-cart-view"
                                                                        
                                                                    ></Box>
                                                                </>}
                                                                <CardContent>
                                                                    <Box className="cart-content-outsite"
                                                                       
                                                                    >
                                                                        <Typography
                                                                            variant="h6"
                                                                            component="p"
                                                                            sx={{
                                                                                fontWeight: "bold",
                                                                                fontSize: "1rem",
                                                                                mb: 1,
                                                                                textAlign: "left",
                                                                                flex: 1,
                                                                                wordWrap: "break-word",
                                                                                cursor: "pointer"
                                                                            }}
                                                                            onClick={() => navigateLoadDashboard(dashboard.dashboardID)}
                                                                        >
                                                                            {roleID === 2
                                                                                ? dashboard.title
                                                                                : `${dashboard.clientName} - ${dashboard.title}`}
                                                                        </Typography>
                                                                        <Tooltip title={dashboard.isFav ? "Remove from Favourites" : "Add to Favourites"}>
                                                                            {dashboard.isFav == true ? (
                                                                                <Star onClick={() => toggleFavourite(dashboard.dashboardID, !dashboard.isFav, dashboard.title)} color="primary" sx={{ verticalAlign: "middle" }} />
                                                                            ) : (
                                                                                <StarOutline onClick={() => submitFavourite(dashboard.dashboardID, !dashboard.isFav)} color="primary" sx={{ verticalAlign: "middle" }} />
                                                                            )}
                                                                        </Tooltip>
                                                                    </Box>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                        sx={{ fontSize: "0.875rem", lineHeight: 1.5, textAlign: "left" }}
                                                                    >
                                                                        {TSharedBy} {dashboard.sharedBy}
                                                                        <br />
                                                                        {TValidUntil} {moment(dashboard.validUntil, "DD-MM-YYYY").isValid() ? moment(dashboard.validUntil, "DD-MM-YYYY").format("DD MMM YYYY") : ""}
                                                                    </Typography>
                                                                </CardContent>
                                                            </a>
                                                            <CardActions sx={{ mt: 2, justifyContent: "end" }}>
                                                                {/* <Share color="primary" onClick={() => toggleShareModal()} /> */}
                                                                {dashboard.canEdit == true ? (
                                                                    <Button sx={{ border: '0px !important', padding: "0px", width: '0px', minWidth: '0px !important', boxShadow: 'none !important', margin: '0px !important' }}>
                                                                        <Tooltip title="Edit">
                                                                            <Icon className='fa-light fa-pen-to-square font-size-base  text-primary ' onClick={() => navigateUpdate(dashboard.dashboardID)} />
                                                                        </Tooltip>
                                                                    </Button>
                                                                ) : <></>}
                                                                {dashboard.canShare == true ? (
                                                                    <Button sx={{ border: '0px !important', padding: "0px", width: '0px', minWidth: '0px !important', boxShadow: 'none !important', margin: '0px !important' }}>
                                                                        <Tooltip title="Share">
                                                                            <Icon className='fa fa-share text-primary font-size-base  text-primary btn-list' onClick={() => toggleShareModal(dashboard.dashboardID, dashboard.isRLS)} />
                                                                        </Tooltip>
                                                                    </Button>
                                                                ) : <></>}
                                                                {/* <Delete color="primary" className="fa-light fa-trash-can" onClick={() => toggleDeleteModal(dashboard.dashboardID, dashboard.title, dashboard.dashboardGroupID, dashboard.userID)} /> */}
                                                                {dashboard.canDelete == true ? (
                                                                    <Button className="padding-right-s" sx={{ border: '0px !important', width: '0px', minWidth: '0px !important', boxShadow: 'none !important', margin: '0px !important' }}>
                                                                        <Tooltip title="Delete">
                                                                            <Icon className='fa-light fa-trash-can font-size-base  text-primary btn-list' onClick={() => toggleDeleteModal(dashboard.dashboardID, dashboard.title, dashboard.dashboardGroupID, dashboard.userID)} />
                                                                        </Tooltip>
                                                                    </Button>) : <></>}
                                                            </CardActions>
                                                        </Card>
                                                    </SwiperSlide>
                                                ))
                                            ) : (
                                                <SwiperSlide>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            height: "200px",
                                                            borderRadius: "12px",
                                                            boxShadow: theme.shadows[3],
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}
                                                        >
                                                            {TnoRecordFound}
                                                        </Typography>
                                                    </Box>
                                                </SwiperSlide>
                                            )}
                                        </Swiper>
                                    </>}
                                    </>
                                )}
                                <Dialog
                                    open={isFavModal}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "12px",
                                            padding: "16px",
                                            width: "400px",
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            style={{ color: "#000959", marginBottom: "8px" }}
                                        >
                                            {"Remove Favourite"}
                                        </Typography>
                                    </DialogTitle>

                                    <DialogContent>
                                        <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                                            Are you sure you want to Remove <strong>{dashboardName}</strong> from Favourite?
                                        </Typography>
                                    </DialogContent>

                                    <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Cancel />}
                                            onClick={() => toggleFavourite('', '')}
                                            style={{
                                                color: "#000959",
                                                borderColor: "#000959",
                                                textTransform: "none",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => submitFavourite(dashboardID, isFav)}
                                            startIcon={<Delete />}
                                            style={{
                                                backgroundColor: "#000959",
                                                color: "#FFFFFF",
                                                textTransform: "none",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={isDeleteModal}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "12px",
                                            padding: "16px",
                                            width: "400px",
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            style={{ color: "#000959", marginBottom: "8px" }}
                                        >
                                            Delete Dashboard - <Typography variant="strong" sx={{ width: '10rem', wordWrap: "break-word", whiteSpace: "normal", wordBreak: "break-word" }}>{dashboardName}</Typography>
                                        </Typography>
                                    </DialogTitle>

                                    <DialogContent>
                                        <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                                            Are you sure you want to Delete this Dashboard?
                                        </Typography>
                                    </DialogContent>

                                    <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Cancel />}
                                            onClick={() => toggleDeleteModal('', '', '')}
                                            style={{
                                                color: "#000959",
                                                borderColor: "#000959",
                                                textTransform: "none",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleDelete(dashboardID)}
                                            startIcon={<Delete />}
                                            style={{
                                                backgroundColor: "#000959",
                                                color: "#FFFFFF",
                                                textTransform: "none",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={shareModal}
                                    className="border-radius-soft padding-base"
                                    fullWidth={true}
                                    maxWidth={'xl'}
                                    PaperProps={{
                                        style: {
                                            height: "auto",
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        <Typography
                                            variant="h6"
                                            className="text-align-center margin-bottom-xs"
                                            fontWeight="bold"
                                            style={{ color: "#000959" }}
                                        >
                                            {/* Share Dashboard */}
                                            {Tshare} {TDashboard}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        {isSearchLoading ? <Loader /> : <>
                                            <Box sx={{ display: "flex", gap: 3 }}>
                                                {/* Groups & Users Section */}
                                                {/* Left Table */}
                                                <Box
                                                    sx={{
                                                        border: "1px solid #dbe5ff",
                                                        borderRadius: 1,
                                                        padding: 2,
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Typography variant="h6" className="box-name-tit text-align-left">
                                                        {TgroupNusers}
                                                    </Typography>
                                                    <TableContainer className="display-rec-4">
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    {/* <TableCell>{TnameUdt}</TableCell> */}
                                                                    <TableCell className="padding-left-none">{TemailUdt}</TableCell>
                                                                    <TableCell >{TvalidUntilUdt} <Typography variant="p" sx={{ fontWeight: "bold", color: '#ed1b34' }}>*</Typography></TableCell>
                                                                    <TableCell sx={{ width: '125px' }} >{TroleUdt} <Typography variant="p" sx={{ fontWeight: "bold", color: '#ed1b34', width: '8rem' }}>*</Typography></TableCell>
                                                                    {isRLS ? <TableCell>{TRLS} <Typography variant="p" sx={{ fontWeight: "bold", color: '#ed1b34' }}>*</Typography></TableCell> : <></>}
                                                                    <TableCell>{TAction}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {GroupnUserList.length > 0 ? GroupnUserList.map((data, index) => (
                                                                    data.isDeleted === true ? null : (
                                                                        <TableRow key={data.emailAddress} >
                                                                            {/* <TableCell>{data.displayName !== '' ? data.displayName : '-'}</TableCell> */}
                                                                            <TableCell
                                                                                className="padding-left-xs"
                                                                                sx={{
                                                                                    width: "20rem",
                                                                                    wordWrap: "break-word",
                                                                                    whiteSpace: "normal",
                                                                                    wordBreak: "break-word",
                                                                                }}>
                                                                                {data.emailAddress}
                                                                            </TableCell>

                                                                            <TableCell className="padding-top-none margin-top-none">
                                                                                {/* <TextField style={{margin:'0px', padding:'0px'}}
                                                                                        fullWidth
                                                                                        type="date"
                                                                                        value={(data.emailAddress==JSON.parse(localStorage.getItem("userInfo")).emailAddress)?getNextYearDate():moment(data.validUntil, 'DD-MM-YYYY').format('YYYY-MM-DD')}
                                                                                        onChange={(e) => {
                                                                                            const updatedList = [...GroupnUserList];
                                                                                            updatedList[index] = {
                                                                                                ...updatedList[index],
                                                                                                validUntil: e.target.value
                                                                                            };
                                                                                            setGroupnUserList(updatedList);

                                                                                            const today = moment().format('YYYY-MM-DD');
                                                                                            const selectedDate = moment(e.target.value, 'YYYY-MM-DD');

                                                                                            if (selectedDate.isBefore(today, 'day')) {
                                                                                                setErrors(prev => ({
                                                                                                    ...prev,
                                                                                                    [`${data.userID}-validUntil`]: 'Date cannot be in the past.'
                                                                                                }));
                                                                                            } else {
                                                                                                setErrors(prev => ({
                                                                                                    ...prev,
                                                                                                    [`${data.userID}-validUntil`]: false
                                                                                                }));
                                                                                            }
                                                                                        }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                    />  */}
                                                                                <TextField style={{ margin: '0px', padding: '0px' }}
                                                                                    fullWidth
                                                                                    type="date"
                                                                                    value={data.validUntil}
                                                                                    onChange={(e) => handleValidDateChangeEvent(e, index, data)}
                                                                                    required
                                                                                    variant="outlined"
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell className="padding-top-none margin-top-none">
                                                                                <TextField style={{ margin: '0px', padding: '0px' }}
                                                                                    select
                                                                                    fullWidth
                                                                                    required
                                                                                    disabled={(data.dashboardRoleID == 3) || (data.dashboardRoleID == 2)}
                                                                                    value={data.dashboardRoleID}
                                                                                    onChange={(e) => {
                                                                                        const updatedList = [...GroupnUserList];
                                                                                        updatedList[index] = {
                                                                                            ...updatedList[index],
                                                                                            dashboardRoleID: e.target.value // Update DashboardRoleID
                                                                                        };
                                                                                        setGroupnUserList(updatedList);
                                                                                    }}
                                                                                    variant="outlined"
                                                                                >
                                                                                    {DashboardRoleFillList.map((role) => (
                                                                                        <MenuItem key={role.code} value={role.code}>
                                                                                            {role.description}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </TableCell>
                                                                            {isRLS && (
                                                                                <TableCell className="padding-top-none margin-top-none">
                                                                                    <TextField
                                                                                        sx={{ width: '100px' }}
                                                                                        fullWidth
                                                                                        value={data.dashboardRLS}
                                                                                        onChange={(e) => {
                                                                                            const updatedList = [...GroupnUserList];
                                                                                            updatedList[index] = {
                                                                                                ...updatedList[index],
                                                                                                rlsName: e.target.value // Update RLSName
                                                                                            };
                                                                                            setGroupnUserList(updatedList);
                                                                                        }}
                                                                                        required
                                                                                        variant="outlined"
                                                                                    />
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell className="padding-top-none margin-top-none">
                                                                                {(!(Number(data.dashboardRoleID) === 3 && data.isCreatedBy === true) && !data.isDeleted) || (!(Number(data.dashboardRoleID) === 3)) ? (
                                                                                    <Icon className='fa-light fa-trash-can font-size-base  text-primary btn-list' onClick={() => toggleRemoveUser(data.emailAddress)} />
                                                                                ) : null}
                                                                                {(Number(data.dashboardRoleID) === 2 && data.isMailView === true) ? (
                                                                                    <Icon className='fa-light fa-envelope padding-top-m font-size-base text-danger' onClick={() => sendMail(dashboardID, data.userID)} />
                                                                                ) : null}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )) : (
                                                                    <TableRow>
                                                                        <TableCell colSpan={6} sx={{ textAlign: 'center' }}>{TnoUserFound}</TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <Typography defaultValue="Error" variant="p">
                                                        {GroupnUserListErr}
                                                    </Typography>
                                                </Box>

                                                {/* Add Groups & Users */}
                                                <Box
                                                    sx={{
                                                        border: "1px solid #dbe5ff",
                                                        borderRadius: 1,
                                                        padding: 2,
                                                        paddingTop: '8px',
                                                        paddingLeft: '8px',
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            padding: 1

                                                        }}
                                                    >
                                                        <Typography className="box-name-tit" style={{ textTransform: 'uppercase' }} variant="h6" >
                                                            {isInvite ? TinviteUsers : `${Tadd} ${TgroupNusers}`}
                                                        </Typography>
                                                        {isInvite ? <></> : <>
                                                            <Button className="padding-right-s padding-left-s"
                                                                variant="outlined"
                                                                disabled={!canShareClient}
                                                                startIcon={<AddIcon />}
                                                                sx={{ borderColor: "#25408f", color: "#25408f" }}
                                                                onClick={() => setIsInvite(true)}
                                                            >
                                                                {TinviteUsers}
                                                            </Button>
                                                        </>}
                                                    </Box>
                                                    {isInvite ? <>
                                                        <Box sx={{ display: "flex", gap: 3, mb: 3 }}>
                                                            <TextField
                                                                sx={isRLS ? { paddingTop: '10px', width: "50%", marginLeft: '6px' } : { paddingTop: '10px', width: "70%", marginLeft: '6px' }}
                                                                label={TguestEmailUdt}
                                                                onChange={(e) => setInviteEmail(e.target.value)}
                                                                value={inviteEmail}
                                                                fullWidth
                                                                placeholder={TguestEmailNoteUdt}
                                                                required
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant="outlined"
                                                            />
                                                            <TextField
                                                                sx={{ paddingTop: '10px', width: "20%", marginLeft: '6px' }}
                                                                label={TvalidUntilUdt}
                                                                onChange={(e) => handleDate(e.target.value)}
                                                                value={inviteValidUntil}
                                                                fullWidth
                                                                type="date"
                                                                required
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                            {isRLS ?
                                                                <TextField sx={{ paddingTop: '10px', width: "15%", marginLeft: '6px' }}

                                                                    fullWidth
                                                                    onChange={(e) => setInviteRls(e.target.value)}
                                                                    value={inviteRls}
                                                                    required
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    label="RLS"
                                                                />
                                                                : <></>}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                mt: 3,
                                                            }}
                                                        >
                                                            <Button className="margin-right-base "
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => inviteSubmit()}
                                                            >
                                                                {TInvite}
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    setIsInvite(false)
                                                                    setInviteEmail('')
                                                                    setInviteRls('')
                                                                }}
                                                            >
                                                                {Tcancel}
                                                            </Button>
                                                        </Box>
                                                        {console.log("GroupnUserList", GroupnUserList)}
                                                    </> : <>
                                                        <TextField
                                                            sx={{ mt: '-9px' }}
                                                            fullWidth
                                                            placeholder="Search for a person or group (Minimum 3 characters)"
                                                            variant="outlined"
                                                            value={inputValue}
                                                            onChange={handleChangeSearch}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        {
                                                            isUserLoading ? (
                                                                <Box
                                                                    sx={{
                                                                        top: 0, height: '100%', display: 'flex',
                                                                        zIndex: 9999, justifyContent: 'center', alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <CircularProgress />
                                                                </Box>
                                                            ) : (
                                                                <TableContainer component={Paper} sx={{ mt: 3 }} className="display-rec-4">
                                                                    <Table>
                                                                        <TableBody>
                                                                            {DashboardUserData.length > 0 ? (
                                                                                DashboardUserData.map((data, i) => (
                                                                                    <TableRow key={i} sx={{ borderBottom: "2px solid", borderColor: "primary.main" }}>
                                                                                        <TableCell sx={{ fontWeight: "bold" }}>
                                                                                            <TextField
                                                                                                sx={{ paddingTop: '10px', background: 'none' }}
                                                                                                label={data.displayName ? data.displayName : data.firstName}
                                                                                                value={data.userPrincipalName ? data.userPrincipalName : data.email}
                                                                                                fullWidth
                                                                                                disabled
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ color: "#757575" }}>
                                                                                            {/*<TextField*/}
                                                                                            {/*    label={TvalidUntilUdt}*/}
                                                                                            {/*    required*/}
                                                                                            {/*    fullWidth*/}
                                                                                            {/*    type="date"*/}
                                                                                            {/*    value={getNextYearDate()}*/}
                                                                                            {/*/>*/}
                                                                                            <TextField sx={{ color: "#757575", paddingTop: '10px', width: "80%", marginLeft: '6px' }}
                                                                                                label={TvalidUntilUdt}
                                                                                                fullWidth
                                                                                                type="date"
                                                                                                value={data.validUntil}
                                                                                                onChange={(e) => handleValidUntil(e, i, data)}
                                                                                                required
                                                                                                variant="outlined"
                                                                                                InputLabelProps={{
                                                                                                    shrink: true,
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Button
                                                                                                style={canShareColliers ? {
                                                                                                    border: '1px solid #000759',
                                                                                                    padding: '6px 13px 6px 8px',
                                                                                                    color: '#000759',
                                                                                                } : {
                                                                                                    border: '1px solid rgb(170, 170, 170)',
                                                                                                    padding: '6px 13px 6px 8px',
                                                                                                    color: 'rgb(170, 170, 170)',
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                startIcon={canShareColliers ? <AddIcon /> : <></>}
                                                                                                sx={{
                                                                                                    fontWeight: "bold",
                                                                                                    textTransform: "none",
                                                                                                }}
                                                                                                disabled={!canShareColliers}
                                                                                                onClick={(event) => {
                                                                                                    event.persist(); // Call persist to keep the event around
                                                                                                    AddtoGroupUsers(data); // Pass user data to AddtoGroupUsers
                                                                                                }}
                                                                                            >
                                                                                                {TAddAsUdt}
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))
                                                                            ) : (
                                                                                <TableRow>
                                                                                    <TableCell colSpan={3} align="center" sx={{ color: "#757575" }}>
                                                                                        {TnoRecordFound}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            )
                                                        }
                                                    </>}
                                                </Box>
                                            </Box>
                                        </>}
                                    </DialogContent>

                                    <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                        {isSearchLoading ? <Loader /> : <>
                                            <Button
                                                variant="outlined"
                                                startIcon={<Cancel />}
                                                onClick={() => toggleShareModal()}
                                                style={{
                                                    color: "#000959",
                                                    borderColor: "#000959",
                                                    textTransform: "none",
                                                    borderRadius: "6px",
                                                    padding: '10px'
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button className="space-btn" variant="contained" onClick={() => handleShare()} >
                                                <Icon className='fa fa-share text-primary font-size-base  text-primary btn-list' /> Share
                                            </Button>
                                        </>}
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={isRemoveUserModal}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "12px",
                                            padding: "16px",
                                            width: "400px",
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            style={{ color: "#000959", marginBottom: "8px" }}
                                        >
                                            {"Remove User"}
                                        </Typography>
                                    </DialogTitle>

                                    <DialogContent>
                                        <Typography style={{ fontSize: "14px", color: "#555555", marginBottom: "16px" }}>
                                            Are you sure you want to Remove User from this Group?
                                        </Typography>
                                    </DialogContent>

                                    <DialogActions style={{ justifyContent: "center", gap: "8px" }}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Cancel />}
                                            onClick={() => toggleRemoveUser()}
                                            style={{
                                                color: "#000959",
                                                borderColor: "#000959",
                                                textTransform: "none",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            {Tcancel}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleRemovefromGroupList(removeUserIndex)}
                                            startIcon={<Delete />}
                                            style={{
                                                backgroundColor: "#000959",
                                                color: "#FFFFFF",
                                                textTransform: "none",
                                                borderRadius: "6px", cursor: 'pointer'
                                            }}
                                        >
                                            {Tdelete}
                                        </Button>
                                    </DialogActions>
                                    <Box style={{ height: '50px' }}></Box>

                                </Dialog>
                            </Grid>
                        </Box>

                    </section>
                </Box>
            </main>

            {ToastComponent}
            <Footer />
        </>
    );
};

export default withTranslation()(DashboardList);
