import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { UpdateUserLangUrl } from "../utils/constants";
import { axiosPost } from "../utils/Axios";

export const LanguageSelector = () => {
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const { i18n } = useTranslation();

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo?.userLangInfo) {
            const userLanguages = userInfo.userLangInfo.map((lang) => ({
                lang_code: lang.lang_code,
                lang: lang.lang,
            }));
            setLanguages(userLanguages);
            setSelectedLanguage(userInfo.lang || i18n.language);
        }
    }, [i18n.language]);

    const changeLanguage = async (lng) => {
        try {
            setSelectedLanguage(lng);
            i18n.changeLanguage(lng);
            document.body.dir = i18n.dir(lng);

            const newLang = `?newLang=${lng}`;
            const response = await axiosPost(`${UpdateUserLangUrl}${newLang}`);

            if (response) {
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                if (userInfo) {
                    userInfo.lang = lng;
                    localStorage.setItem("userInfo", JSON.stringify(userInfo));
                }
            } else {
                console.error("Unexpected response:", response);
                setFallbackLanguage();
            }
        } catch (error) {
            console.error("Error updating language:", error);
            setFallbackLanguage();
        }
    };

    const setFallbackLanguage = () => {
        const fallbackLang = "en-US";
        setSelectedLanguage(fallbackLang);
        i18n.changeLanguage(fallbackLang);
        document.body.dir = i18n.dir(fallbackLang);
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo) {
            userInfo.lang = fallbackLang;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
    };

    return (
        <Select style={{paddingLeft:'1px'}}
            value={selectedLanguage}
            onChange={(event) => changeLanguage(event.target.value)} // Handle language change
            // sx={{ "& fieldset": { border: "1px solid #e0e0e0" } }}
        >
            {languages.map((lng) => (
                <MenuItem key={lng.lang_code} value={lng.lang_code}>
                    {lng.lang}
                </MenuItem>
            ))}
        </Select>
    );
};
