import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from './screens/Layout';
import axios from "axios";
import { GetUserPrivUrl } from '../src/utils/constants';
import './custom.css';
import DashboardList from './screens/dashboard/dashboardList';
import LoadDashboard from './screens/dashboard/loadDashboard';
import DashboardAdd from './screens/dashboard/dashboardAdd';
import DashboardGroupAdd from './screens/dashboard_group/dashboardGroupAdd';
import DashboardGroupList from './screens/dashboard_group/dashboardGroupList';
import DashboardLog from './screens/dashboard_log/dashboardLog';
import ColliersUserManagementList from './screens/colliers_user_management/colliers_user_management_list';
import ColliersUserManagementManage from './screens/colliers_user_management/colliers_user_management_manage';
import ClientUserManagementList from './screens/client_user_management/client_user_management_list';
import ClientUserManagementManage from './screens/client_user_management/client_user_management_manage';
import AuditTrial from './screens/audit_trail/audit_trail';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: []
        };
    }

    async componentDidMount() {
        try {
            const response = await axios.get(GetUserPrivUrl);
            if (response?.data) {
                if (response.status === 440 || response.status === 400) {
                    window.location.href = "/Login/KeyStone_Login";
                } else if (response.status === 302) {
                    window.location.href = "/Login/KeyStone_Login";
                } else if (response.data?.userID != null) {
                    this.setState({ userData: response.data });
                    localStorage.setItem("userInfo", JSON.stringify(response.data));
                    console.log("userData", response);
                } else {
                    window.location.href = "/Login/KeyStone_Login";
                }
            } else {
                console.error("Failed to fetch user privileges: No data found.");
            }
        } catch (error) {
            console.error("Error fetching user privileges:", error);
        }
    }

    render() {
        return (
            <Layout>
                <Routes>
                    {/* Dashboard */}
                    <Route path='/' element={<DashboardList />} />
                    <Route path='/dashboard' element={<DashboardList />} />
                    <Route path='/dashboardAdd' element={<DashboardAdd />} />
                    <Route path='/loadDashboard' element={<LoadDashboard />} />
                    <Route path='/loadDashboard/:code' element={<LoadDashboard />} />
                    {/* Dashboard Group */}
                    <Route path='/dashboard-group-add' element={<DashboardGroupAdd />} />
                    <Route path='/dashboard-group' element={<DashboardGroupList />} />
                    {/* Dashboard Log */}
                    <Route path='/dashboard-log' element={<DashboardLog />} />
                    {/* Colliers User */}
                    <Route path='/colliers-user-management' element={<ColliersUserManagementList />} />
                    <Route path='/colliers-user-management-manage' element={<ColliersUserManagementManage />} />
                    {/* Client User */}
                    <Route path='/client-user-management' element={<ClientUserManagementList />} />
                    <Route path='/client-user-management-manage' element={<ClientUserManagementManage />} />
                    {/* Audit Trial */}
                    <Route path='/audit-trail' element={<AuditTrial />} />
                </Routes>
            </Layout>
        );
    }
}