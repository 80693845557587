import axios from "axios";
import { headers } from './constants'
export async function axiosPost(url, mapData, headers) {
    console.log("registrationOneAdd_action url", url)

    var result;
    const ax = axios.create();
    await ax
        .post(url, mapData)
        .then((res) => {
            result = res.data;
            console.log("registrationOneAdd_action result", result)
        })
        .catch((err) => {
            if (err.response.status === 400 || err.response.status === 403) {
                result = err.response.data
            }
            else if (/*err.response.status === 400 || */err.response.status === 440 /*|| err.response.status === 401*/) {
                localStorage.clear();
            } else if (err.response.data != null) {
                result = null;
            } else {
                result = null;
            }
        });
    return result;
}

// Similarly, modify axiosGet to accept 'history'
export async function axiosGet(url, params, headers) {
    var result;
    const ax = axios.create();
    await ax
        .get(url, params != null ? { params } : null)
        .then((res) => {
            result = res.data;
        })
        .catch((err) => {
            console.log('err:', err)
            if (err.response.status === 400 || err.response.status === 403) {
                result = err.response.data
            }
            else if (/*err.response.status === 400 || */err.response.status === 440 /*|| err.response.status === 401*/) {
                
                localStorage.clear();
            } else if (err.response.data != null) {
                result = null;
            } else {
                result = null;
            }
        });
    return result;
}

// Same modification for axiosDelete
export async function axiosDelete(url, params, headers) {
    var result;
    const ax = axios.create();
    await ax
        .delete(url, params != null ? { params } : null)
        .then((res) => {
            result = res.data;
        })
        .catch((err) => {
            console.log('err:', err)
            if (err.response.status === 403) {
                result = err.response.data
            } else if (err.response.status === 400 || err.response.status === 440 || err.response.status === 401) {
                localStorage.clear();
            } else if (err.response.data != null) {
                result = null;
            } else {
                result = null;
            }
        });
    return result;
}

// axiosGetFile doesn't seem to need any changes regarding history
export async function axiosGetFile(url) {
    var result;
    await axios({
        url: url,
        method: 'GET',
        responseType: 'blob' //important
    }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        result = res.data;
    })
        .catch((err) => {
            result = null;
        });
    return result;
}
