import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar  from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import { LanguageSelector } from './languageSelector';
import { GetModuleDetailsUrl, logoutUrl } from '../utils/constants';
import axios from 'axios';
import { axiosGet, axiosPost } from '../utils/Axios';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Use useLocation to get the current path
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const [language, setLanguage] = useState('en-US');
    const [moduleData, setModuleData] = useState([]);

    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo");
        if (!userInfo) {
           // navigate("/login/root-data");
            window.location.href = "/Login/KeyStone_Login";
            return;
        }

        const getModuleData = async () => {
            try {
                const response = await axiosGet(GetModuleDetailsUrl);
                if (response) {
                    setModuleData(response || []);
                } else {
                    console.error("Failed to fetch dashboards: No data found.");
                }
            } catch (error) {
                console.error("Error fetching data:", error?.response || error);
            }
        };

        getModuleData();
    }, [navigate]);

    const handleProfileMenuOpen = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null);
    };

    const onLogout = async () => {
        localStorage.clear();
        const response = await axiosPost(logoutUrl);
        if (response) {
            //navigate("/Login/KeyStone_Login");
            window.location.href = "/Login/KeyStone_Login";
            //window.open("/Login/KeyStone_Login", _Self);
        }
    };

    const isOnDashboard = location.pathname;

    return (
        <AppBar position="fixed" color="default" elevation={1} className='background-neutral-0'>
            <Toolbar className='background-neutral-0'>
                <Box className='text-center background-neutral-0'>
                    <Link to="/dashboard">
                        <img src="img/header-logo.png" alt="Logo" className='header-logo margin-top-s' />
                    </Link>
                </Box>

                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor:'pointer' }}>
                    {isOnDashboard === "/dashboard" || isOnDashboard === "/" ? <></> : (
                        <Link className='cack-to-dash'
                            to={getReturnPath(isOnDashboard)}
                            style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                        >
                            <ArrowBackIcon sx={{ mr: 1 }} />
                            <Typography variant="subtitle1">
                                {getReturnText(isOnDashboard)}
                            </Typography>
                        </Link>
                    )}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PersonIcon sx={{ fontSize: 22 }} />
                    <Typography className='user-name'
                        onClick={handleProfileMenuOpen}
                        variant="subtitle1"
                        sx={{ ml: 1, display: { xs: 'none', md: 'block' } }}
                    >
                        {JSON.parse(localStorage.getItem("userInfo"))?.userName} {" "}
                    </Typography>

                    <Menu
                        anchorEl={profileAnchorEl}
                        open={Boolean(profileAnchorEl)}
                        onClose={handleProfileMenuClose}
                        PaperProps={{ sx: { width: '250px', mt: 1.5 } }}
                    >
                        <Box sx={{ px: 2, py: 1.5 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                {JSON.parse(localStorage.getItem("userInfo"))?.userName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {JSON.parse(localStorage.getItem("userInfo"))?.roleName}
                            </Typography>

                            <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                                <LanguageSelector />
                            </FormControl>
                        </Box>

                        <Divider />

                        {moduleData.length > 0 ? moduleData.filter(module => module.name !== 'Dashboard').map((module) => {
                            const formattedName = module.name.toLowerCase().replace(/\s+/g, '-');
                            return (
                                <MenuItem component={Link} to={`/${formattedName}`} key={module.name}>
                                    {module.name}
                                </MenuItem>
                            );
                        }) : <></>}

                        <Divider />

                        <MenuItem component={Link} sx={{ color: 'error.main' }} onClick={onLogout}>
                            <LogoutIcon sx={{ mr: 1, fontSize: 18 }} />
                            Sign Out
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

// Helper functions for conditional rendering
const getReturnPath = (path) => {
    switch (path) {
        case "/colliers-user-management-manage":
            return "/colliers-user-management";
        case "/client-user-management-manage":
            return "/client-user-management";
        case "/dashboard-group-add":
            return "/dashboard-group";
        default:
            return "/dashboard";
    }
};

const getReturnText = (path) => {
    switch (path) {
        case "/colliers-user-management-manage":
            return "Return to Colliers User Management";
        case "/client-user-management-manage":
            return "Return to Client User Management";
        case "/dashboard-group-add":
            return "Return to Dashboard Group";
        default:
            return "Return to My Dashboard";
    }
};

export default Header;
